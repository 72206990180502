<template>
  <svg
    width="35"
    height="37"
    viewBox="0 0 35 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.4338 5.39258H2.23595C1.18061 5.39258 0.325195 6.248 0.325195 7.30277V32.8763H33.3913C33.975 32.8763 34.4484 32.403 34.4484 31.8198V6.40656C34.4484 5.84643 33.9939 5.39258 33.4338 5.39258Z"
      fill="#085642"
    />
    <path
      d="M33.4338 5.39258H2.23595C1.18061 5.39258 0.325195 6.248 0.325195 7.30277V32.8763H0.437208V7.7819C0.437208 6.72655 1.29264 5.87113 2.34798 5.87113H33.5458C33.9417 5.87113 34.2812 6.10035 34.4484 6.43126V6.40656C34.4484 5.84643 33.9939 5.39258 33.4338 5.39258Z"
      fill="#084642"
    />
    <path
      d="M34.6716 15.8076H31.3154V25.1914H34.6716V15.8076Z"
      fill="#084542"
    />
    <path
      d="M26.2988 1.6926C26.0765 1.10087 25.4164 0.800984 24.8246 1.02389L1.53123 9.77515C0.939505 9.99805 0.640193 10.6576 0.862522 11.2499L5.70492 24.1381C5.92725 24.7298 5.66242 25.9546 6.25415 25.7323L30.4725 16.0555C31.0642 15.8332 31.3635 15.1731 31.1412 14.5814L26.2988 1.6926Z"
      fill="#FFA400"
    />
    <path
      d="M33.3937 32.8766H32.5566H33.3937C33.6803 32.8766 33.9406 32.7623 34.1308 32.5773C33.9406 32.7623 33.6803 32.8766 33.3937 32.8766ZM34.1319 32.5762C34.1348 32.5733 34.1371 32.571 34.1399 32.5681C34.1371 32.571 34.1348 32.5733 34.1319 32.5762ZM34.1411 32.567C34.1417 32.5664 34.1417 32.5664 34.1422 32.5658C34.1417 32.5664 34.1417 32.5664 34.1411 32.567ZM34.1497 32.5578C34.1503 32.5578 34.1509 32.5572 34.1514 32.5566C34.1509 32.5572 34.1503 32.5578 34.1497 32.5578Z"
      fill="#C7C0C7"
    />
    <path
      d="M33.3861 32.8759H32.549V27.1057H33.019C33.3401 27.1057 33.6486 26.9753 33.8761 26.7437L34.4431 26.1658V31.8194C34.4431 32.1055 34.3288 32.3657 34.1438 32.5559C34.1432 32.5565 34.1427 32.557 34.1421 32.557C34.1398 32.5599 34.1375 32.5622 34.1346 32.5651L34.1231 32.5766C33.933 32.7615 33.6727 32.8759 33.3861 32.8759ZM33.3792 15.8065H32.549V9.39231C32.549 8.83218 32.0952 8.37833 31.535 8.37833H28.805L28.2782 6.97656H32.3072C32.8989 6.97656 33.3792 7.45626 33.3792 8.04799V15.8065ZM5.24163 8.37833H2.23071C1.49938 8.37833 0.815743 7.96469 0.478516 7.36032C0.508389 7.22647 0.552619 7.09778 0.60892 6.97656H8.9632L8.64034 7.10123L5.24163 8.37833Z"
      fill="#084542"
    />
    <path
      d="M0.479109 7.36032C0.423383 7.26094 0.377416 7.15638 0.341797 7.04722C0.345244 7.02367 0.349259 7.00012 0.35328 6.97656H0.609513C0.553213 7.09778 0.508983 7.22647 0.479109 7.36032Z"
      fill="#2A6EB3"
    />
    <path
      d="M33.0341 17.7029H32.5566V15.8076H33.3868V17.6494C33.273 17.6845 33.1547 17.7029 33.0341 17.7029Z"
      fill="#084542"
    />
    <path
      d="M28.8095 8.37833H5.24609L8.64481 7.10123L7.91405 7.38445H28.4045L28.2609 6.97656H28.2827L28.8095 8.37833Z"
      fill="#CE7F00"
    />
    <path
      d="M31.5403 8.3793H2.23595C1.35813 8.3793 0.548673 7.78355 0.325195 6.97754V34.1396C0.325195 35.1949 1.18061 36.0504 2.23595 36.0504H31.2887C31.9878 36.0504 32.5543 35.4839 32.5543 34.7847V9.39328C32.5543 8.83315 32.1004 8.3793 31.5403 8.3793Z"
      fill="#087342"
    />
    <path
      d="M31.5397 8.3793H2.23595C1.35813 8.3793 0.549248 7.78355 0.325195 6.97754V7.40841C0.549248 8.21442 1.35813 8.81017 2.23595 8.81017H31.5397C32.1004 8.81017 32.5543 9.26402 32.5543 9.82415V9.39328C32.5543 8.83315 32.1004 8.3793 31.5397 8.3793Z"
      fill="#084642"
    />
    <path
      d="M33.3828 25.6975V25.1914H34.4468V25.4155L33.3828 25.6975Z"
      fill="#173D63"
    />
    <path
      d="M34.4468 25.1915H33.3828V17.6495C33.5672 17.5927 33.7378 17.4904 33.878 17.3502L34.4468 16.7832V25.1915Z"
      fill="#173D63"
    />
    <path
      d="M33.0266 27.1068H32.5566V25.1914H33.3868V25.6975L34.4508 25.4155V26.1669L33.8837 26.7448C33.6562 26.9764 33.3477 27.1068 33.0266 27.1068Z"
      fill="#132F4D"
    />
    <path
      d="M33.3868 25.1914H32.5566V17.7028H33.0341C33.1547 17.7028 33.273 17.6845 33.3868 17.6494V25.1914Z"
      fill="#132F4D"
    />
    <path
      d="M29.5843 18.8045H25.707C25.9311 18.6207 26.1712 18.4564 26.4251 18.3145H29.5843V18.8045Z"
      fill="#4D89C7"
    />
    <path
      d="M29.4253 21.1014H24.1865C24.2354 20.9342 24.2934 20.7705 24.3606 20.6113H29.4253V21.1014Z"
      fill="#4D89C7"
    />
    <path
      d="M33.2319 16.7274H28.8922C26.388 16.7274 24.2009 18.6175 24.0395 21.1165C23.862 23.8551 26.0307 26.1313 28.7308 26.1313H33.2245C33.5519 26.1313 33.8662 26.0009 34.0977 25.7693L34.6756 25.1914V15.8076L34.0965 16.3752C33.8656 16.601 33.5548 16.7274 33.2319 16.7274Z"
      fill="#085642"
    />
    <path
      d="M33.2309 16.7274H28.8912C26.387 16.7274 24.1999 18.6175 24.0384 21.1165C24.0264 21.3021 24.0269 21.4848 24.0361 21.6657C24.0373 21.602 24.0344 21.5393 24.0384 21.4756C24.1999 18.9765 26.387 17.0864 28.8912 17.0864H33.2309C33.5537 17.0864 33.8646 16.9601 34.0955 16.7343L34.6746 16.1667V15.8076L34.0955 16.3752C33.8646 16.601 33.5537 16.7274 33.2309 16.7274Z"
      fill="#084242"
    />
    <path
      d="M28.8793 19.1846C27.6396 19.1846 26.6348 20.1899 26.6348 21.4297C26.6348 22.6694 27.6396 23.6742 28.8793 23.6742C30.1191 23.6742 31.1244 22.6694 31.1244 21.4297C31.1244 20.1899 30.1191 19.1846 28.8793 19.1846Z"
      fill="#CE5900"
    />
    <path
      d="M28.8793 23.214C27.7183 23.214 26.7744 22.3298 26.6578 21.1992C26.6497 21.2762 26.6348 21.3509 26.6348 21.4296C26.6348 22.6693 27.6396 23.6741 28.8793 23.6741C30.1191 23.6741 31.1244 22.6693 31.1244 21.4296C31.1244 21.3509 31.1089 21.2762 31.1009 21.1992C30.9843 22.3298 30.0404 23.214 28.8793 23.214Z"
      fill="#AB3E00"
    />
    <path
      d="M28.8009 19.502C28.1856 19.502 27.6875 20.0006 27.6875 20.6153C27.6875 21.23 28.1856 21.7281 28.8009 21.7281C29.4156 21.7281 29.9137 21.23 29.9137 20.6153C29.9137 20.0006 29.4156 19.502 28.8009 19.502Z"
      fill="#D26817"
    />
  </svg>
</template>
