<template>
  <div>
    <div
      class="grid grid-cols-1 md:grid-cols-2 md:px-0 px-4 container mx-auto mt-6 md:mt-24"
      style="margin-bottom: 120px"
    >
      <div class="md:mb-0 mb-4">
        <p class="title">About us</p>
        <p class="content mt-4">
          As a non-profit enterprise that envisions the world where the threat
          of global warming no longer exists, we believe that carbon
          sequestration is one of the best ways to save our planet from the
          threat of global warming. At Bring Back The Forest, we believe that
          the key to efficient carbon sequestration lies in healthy forests.
          Hence, we are committed to our mission: to grow forests where trees
          would mature and thrive into a strong, sustainable forest.
        </p>
      </div>
      <img src="/images/about-us/aboutUs1.webp" alt="" />
    </div>
    <div class="flex flex-col">
      <div>
        <img
          src="/images/about-us/aboutUs2.webp"
          alt=""
          class="w-full h-56 md:h-auto -mb-7 md:-mb-20 object-cover"
        />
      </div>
      <div
        class="text-xl p-10 bg-white rounded-lg mx-4 lg:m-auto max-w-6xl"
        style="box-shadow: 0px 2px 8px rgba(3, 18, 1, 0.25); float: bottom"
      >
        In order to achieve our goal to raise a world’s sustainable forest, our
        team of forest experts take into consideration every detail of forest
        planting. This includes every factor that contributes to our planting
        process, from the plantation, the weather, the soil condition, the
        selection of sapling, etc. This is to make sure that our forest would
        foster a perfect condition for living organisms to survive and
        thrive–all for the sake of planting the world's sustainable forest.
      </div>
    </div>
    <div>
      <div
        class="grid grid-cols-1 md:grid-cols-2 md:px-0 px-4 container mx-auto mt-22 md:mt-64 mb-16 md:mb-32"
      >
        <img class="mb-6 md:mb-0" src="/images/about-us/aboutUs3.webp" alt="" />
        <div class="self-center">
          <p class="content md:px-6 px-0">
            At Bring Back The Forest, we are firmly committed to the four core
            values that inspire our work: transparency, security, immutability
            and accessibility. In order to report to our respected donors the
            impact their donations have made to our planet, Bring Back The
            Forest decided to apply Blockchain technology as a means to track
            how donations actually help nurture our forest. With Blockchain, we
            are able to monitor our forest data, including the amount of CO2
            sequestered by trees and the number of living organisms that thrive
            within our forest. This way, with the application of Blockchain
            technology as the means of data tracking, BBTF can show its donors
            that all of their donations have gone towards saving our planet.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title {
  @apply font-bold font-sarabun;
  font-size: 56px;
}

.content {
  @apply text-xl;

  &.card {
    @apply absolute right-4 left-4;
    max-width: 980px;
    bottom: -137px;
  }
}
</style>
