import { ClaimingPeriodResponse } from '@/types/Claiming';
import {
  Currency,
  DonateRequest,
  DonateRequestType,
  GuestDonateRequest,
  DonateResponse,
  DonationFees,
  DonationInfo,
  DonationPaypalInfo,
} from '@/types/Donate';

import { Payment } from '@/types/Payment';
import { Price } from '@/types/Price';
import Axios from 'axios';

interface ClaimTokensParams {
  claimBio: boolean;
  claimCab: boolean;
  sessionToken?: string;
}

interface CommandResponse {
  code: number;
  message: string;
}

const base = Axios.create({
  baseURL: '/api/carbon',
});

export const setHeaders = (target: string, value?: string): void => {
  if (!value) delete base.defaults.headers.common[target];
  else base.defaults.headers.common[target] = value;
};

export const claimTokens = (params: ClaimTokensParams) =>
  base.post<CommandResponse>('/claimTokens', params);

export const getClaimTokens = () => base.get<CommandResponse>('/claimTokens');

export const getClaimingPeriods = () =>
  base.get<ClaimingPeriodResponse>('/claimTokens/periods');

export const donate = (donation: DonateRequest) => {
  return base.post<DonateResponse>('/donate', donation);
};

export const guestDonate = (donation: GuestDonateRequest) => {
  return base.post<DonateResponse>('/guest/donate', donation);
};

export const getDonationFees = (currency: Currency) => {
  return base.get<DonationFees>(`/donate/Fee`, { params: { currency } });
};

export const donatePromptPay = async (donation: DonateRequest) => {
  await donate({ ...donation, type: 'promptpay' });
};

export const getPrices = (type: DonateRequestType) =>
  base.get<Price[]>('/priceList', { params: { type } });

export const getCryptoPrices = () =>
  base.get<Price[]>('/priceList/cryptocurrencies');

export const getPayment = (chargeId: string) => {
  return base.get<Payment>(`/payments/${chargeId}`);
};

export const donateInfo = (ref: string) => {
  return base.get<DonationInfo>(`payments/ref/${ref}`);
};

export const donatePaypalInfo = (token: string) => {
  return base.get<DonationPaypalInfo>(`paypal/orders/${token}`);
};

export const receiveSendByGuest = (param: any) => {
  return base.post<any>('/claims/nfts/request', param);
};

export const receiveSendByCenter = (param: {
  paymentId: string;
  recipient: string;
}) => {
  return base.post<CommandResponse>('/claims/nfts', param);
};
export const receiveConfirm = (param: any) => {
  return base.post<any>('/claims/nfts/confirm', param);
};

export const getInfoReceiveNfts = (param: any) => {
  return base.get<any>('/claims/nfts', param);
};
export default base;
