import useToastMessage from './useToastMessage';
import { useSmartContract } from './useSmartContract';
import { useUserStore } from '@/store/user';
import { useRouter } from 'vue-router';
import * as api from '@/apis';
import Web3 from 'web3';

export default function useReceive() {
  const smartContract = useSmartContract();
  const router = useRouter();
  const { error } = useToastMessage();
  const userStore = useUserStore();
  const web3 = new Web3((window as any).ethereum);

  const donateId = localStorage.getItem('receive_donate_id') as string;
  const paymentId = localStorage.getItem('receive_id') as string;
  const adressMe = userStore.me?.attributes.ethAddress as string;
  const receive = async (recipient: string) => {
    const channel = localStorage.getItem('receive_channel');
    const gasPrice = await web3.eth.getGasPrice();

    if (!userStore.isGeneratedUser && channel == '0') {
      try {
        await smartContract.aggregator?.methods
          .claimNFTs(donateId, recipient)
          .send({ from: adressMe, gasPrice })
          .on('transactionHash', () => {
            router.push(`/landing/confirm`);
          });
      } catch (err) {
        error(err);
      }
    } else {
      await api.receiveSendByCenter({
        paymentId: paymentId,
        recipient: recipient,
      });
      router.push(`/landing/confirm`);
    }
  };

  return {
    receive,
  };
}
