<template>
  <header class="bg-primary-dark">
    <div class="top-header pt-2 pb-4 px-4 lg:pt-6 lg:pb-5 lg:px-7">
      <div class="">
        <slot name="left"></slot>
      </div>
      <div class="">
        <slot name="right"></slot>
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
.top-header {
  @apply flex justify-between items-center;
}
</style>
