<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
  >
    <path
      d="M40.6289 20C40.6289 8.95312 31.6758 0 20.6289 0C9.58203 0 0.628906 8.95312 0.628906 20C0.628906 29.9844 7.94141 38.2578 17.5039 39.7578V25.7812H12.4258V20H17.5039V15.5937C17.5039 10.582 20.4883 7.8125 25.0586 7.8125C27.2461 7.8125 29.5352 8.20312 29.5352 8.20312V13.125H27.0117C24.5273 13.125 23.7539 14.668 23.7539 16.25V20H29.3008L28.4141 25.7812H23.7539V39.7578C33.3164 38.2578 40.6289 29.9844 40.6289 20Z"
      fill="#1877F2"
    />
    <path
      d="M28.4141 25.7812L29.3008 20H23.7539V16.25C23.7539 14.668 24.5273 13.125 27.0117 13.125H29.5352V8.20312C29.5352 8.20312 27.2461 7.8125 25.0586 7.8125C20.4883 7.8125 17.5039 10.582 17.5039 15.5938V20H12.4258V25.7812H17.5039V39.7578C18.5234 39.918 19.5664 40 20.6289 40C21.6914 40 22.7344 39.918 23.7539 39.7578V25.7812H28.4141Z"
      fill="white"
    />
  </svg>
</template>
