import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LandingBlank from '../views/LandingBlank.vue';
import AppHome from '../views/AppHome.vue';
import Main from '../views/MainView.vue';
import Inventory from '../views/Inventory.vue';
import InventoryNFT from '../views/InventoryNFT.vue';
import Donate from '../views/Donate.vue';
import DonateSuccess from '../views/DonateSuccess.vue';
import DonateActivity from '../views/DonateActivity.vue';
import ClaimTokens from '../views/ClaimTokens.vue';
import NotFound from '../views/NotFound.vue';
import LandingIndex from '../views/LandingIndex.vue';
import LandingHome from '../views/LandingHome.vue';
import LandingGlobalWarming from '../views/LandingGlobalWarming.vue';
import LandingGlobalWarmingExtremeWeather from '../views/LandingGlobalWarmingExtremeWeather.vue';
import LandingGlobalGlacialMelt from '../views/LandingGlobalWarmingGlacialMeal.vue';
import LandingGlobalWarmingRisingSeaLevel from '../views/LandingGlobalWarmingRisingSeaLevel.vue';
import LandingGlobalWarmingOceanAcidification from '../views/LandingGlobalWarmingOceanAcidification.vue';
import AuthPage from '../views/AuthPage.vue';
import VerifyAccount from '../views/VerifyAccount.vue';
import ReceiveNFT from '../views/ReceiveNFT.vue';
import ReceiveBySignUp from '@/views/ReceiveBySignUp.vue';
import AddressReceive from '@/views/AddressReceive.vue';
import ConfirmReceive from '../views/ConfirmReceive.vue';
import YearGoals from '../views/YearGoals.vue';
import PastAchievements from '../views/PastAchievements.vue';
import InformationNfts from '../views/InformationNfts.vue';
import FAQs from '../views/FAQs.vue';
import BlogIndex from '../views/BlogIndex.vue';
import Forgot from '../views/Forgot.vue';
import Reset from '../views/Reset.vue';
import AboutUsPage from '../views/AboutUs.vue';
import ForestAll from '../views/ForestAll.vue';
import ForestDetail from '../views/ForestDetail.vue';
import GalleriesAll from '../views/GalleriesAll.vue';
import AnnualReport from '../views/AnnualReport.vue';
import GraciousDonors from '../views/GraciousDonors.vue';
import NFTDetail from '../views/NFTDetail.vue';
import { useUserStore } from '@/store/user';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'Main',
    component: Main,
    redirect: '/landing',
    children: [
      {
        path: '/landing',
        name: 'Landing',
        component: LandingIndex,
        children: [
          {
            path: '',
            name: 'LandingHome',
            component: LandingHome,
          },
          {
            path: 'auth',
            name: 'AuthPage',
            component: AuthPage,
            children: [
              {
                path: 'login/receive/:id',
                name: 'LoginReceivePage',
                component: AuthPage,
              },
              {
                path: 'signup/receive/:id',
                name: 'SignupReceivePage',
                component: AuthPage,

                children: [
                  {
                    path: '/:id',
                    name: 'ReceiveBySignUp',
                    component: ReceiveBySignUp,
                  },
                ],
              },
            ],
          },
          {
            path: 'verify-account',
            name: 'VerifyAccount',
            component: VerifyAccount,
          },
          {
            path: 'receive-nft',
            name: 'ReceiveNFT',
            component: ReceiveNFT,
          },
          {
            path: 'receive/:id/login',
            name: 'ReceiveByLogin',
            component: ReceiveBySignUp,
          },
          {
            path: 'receive/:id/sign-up',
            name: 'ReceiveBySignUp',
            component: ReceiveBySignUp,
          },
          {
            path: 'send-nft/:id',
            name: 'AddressReceive',
            component: AddressReceive,
            children: [
              {
                path: 'confirm',
                name: 'AddressReceiveConfirm',
                component: AddressReceive,
              },
            ],
          },
          {
            path: 'confirm-nft/:id',
            name: 'ConfirmReceive',
            component: ConfirmReceive,
          },
          {
            path: 'confirm',
            name: 'SuccessReceive',
            component: ConfirmReceive,
          },
          {
            path: 'global-warming',
            name: 'GlobalWarming',
            component: LandingBlank,
            children: [
              {
                path: '',
                name: 'GlobalWarming',
                component: LandingGlobalWarming,
              },
              {
                path: 'extreme-weather',
                name: 'ExtremeWeather',
                component: LandingGlobalWarmingExtremeWeather,
              },
              {
                path: 'glacial-melt',
                name: 'GlacialMelt',
                component: LandingGlobalGlacialMelt,
              },
              {
                path: 'rising-sea-level',
                name: 'Rising Sea Level',
                component: LandingGlobalWarmingRisingSeaLevel,
              },
              {
                path: 'ocean-acidification',
                name: 'Ocean Acidification',
                component: LandingGlobalWarmingOceanAcidification,
              },
            ],
          },
          {
            path: 'years-goals',
            name: '10YearGoals',
            component: YearGoals,
          },
          {
            path: 'past-achievements',
            name: 'PastAchievements',
            component: PastAchievements,
          },
          {
            path: 'information-nfts',
            name: 'InformationNfts',
            component: InformationNfts,
          },
          {
            path: 'faqs',
            name: 'FAQsIndex',
            component: LandingBlank,

            children: [
              {
                path: '',
                name: 'FAQsPage',
                component: FAQs,
              },
              {
                path: 'blog/:id',
                name: 'BlogPage',
                component: BlogIndex,
              },
            ],
          },
          {
            path: 'about-us',
            name: 'AboutUsPage',
            component: AboutUsPage,
          },
          {
            path: 'forest',
            name: 'ForestIndex',
            component: LandingBlank,
            children: [
              {
                path: '',
                name: 'ForestAll',
                component: ForestAll,
              },
              {
                path: ':id',
                name: 'ForestDetail',
                component: ForestDetail,
              },
            ],
          },
          {
            path: 'galleries/:id',
            name: 'GalleriesAll',
            component: GalleriesAll,
          },
          {
            path: 'report',
            name: 'AnnualReport',
            component: AnnualReport,
          },
          {
            path: 'report/:id',
            name: 'AnnualReportForest',
            component: AnnualReport,
          },
          {
            path: 'gracious-donors',
            name: 'GraciousDonors',
            component: GraciousDonors,
          },
        ],
      },
      {
        path: '/app',
        name: 'AppHome',
        component: AppHome,
        redirect: '/app/inventory',
        beforeEnter: (to, from, next) => {
          const { me } = useUserStore();
          const authenticated = !!me;

          if (authenticated) {
            return next();
          } else {
            return next({
              path: from.fullPath,
              replace: true,
            });
          }
        },
        children: [
          {
            path: 'inventory',
            name: 'Inventory',
            component: Inventory,
            redirect: '/app/inventory/nfts/overview',
            children: [
              {
                path: 'nfts/:type',
                component: InventoryNFT,
              },
            ],
          },
          {
            path: 'activity',
            name: 'Activity',
            component: DonateActivity,
          },
          {
            path: 'receive-nft',
            name: 'Receive',
            component: ReceiveNFT,
          },
          {
            path: 'claim',
            name: 'ClaimTokens',
            component: ClaimTokens,
          },
          {
            path: 'account',
            name: 'Account',
            component: ClaimTokens,
          },
        ],
      },
      {
        path: '/donate',
        name: 'DonateIndex',
        component: LandingIndex,
        children: [
          {
            path: '',
            name: 'Donate',
            component: Donate,
          },
          {
            path: 'success',
            name: 'DonateSuccessByPaypal',
            component: DonateSuccess,
          },
          {
            path: 'success/:id',
            name: 'DonateSuccess',
            component: DonateSuccess,
          },
        ],
      },
      {
        path: '/forgot',
        name: 'Forgot',
        component: LandingIndex,
        children: [
          {
            path: '',
            name: 'Forgot',
            component: Forgot,
          },
        ],
      },
      {
        path: '/reset-password',
        name: 'Reset',
        component: LandingIndex,
        children: [
          {
            path: '',
            name: 'Reset',
            component: Reset,
          },
        ],
      },

      {
        path: '/nft-detail/:type/:address',
        name: 'NFTDetail',
        component: LandingIndex,
        children: [
          {
            path: '',
            name: 'NFTDetail',
            component: NFTDetail,
          },
        ],
      },

      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});

export default router;
