const splitNumber = (num: number) => {
  return num
    .toString()
    .split('.')
    .map((e) => parseInt(e || '0'));
};

export const countDecimals = (num: number) => {
  if (num % 1 === 0) return 0;
  if (num === Infinity) return 0;
  return splitNumber(num)[1].toString().length;
};

export const counts = (value = 0, unit = '', spaced = true) => {
  const valueString = `${value}${spaced ? ' ' : ''}${unit}`;
  const isPlural = value === 0 || Math.abs(value) > 1;
  return valueString + (isPlural ? 's' : '');
};

export const findRepeatFloat = (value: number) => {
  const NOT_FOUND = -1;
  const [integers, decimals] = splitNumber(value).map((e) => e.toString());
  if (decimals == null) return NOT_FOUND;
  const repeatPosition = decimals.search(/([0-9])\1{9,}/);
  return repeatPosition === NOT_FOUND
    ? NOT_FOUND
    : repeatPosition + integers.length;
};

export const roundNumber = (value: number) => {
  const repeatFloat = findRepeatFloat(value);
  const tenPowertoFull = 10 ** (repeatFloat - 1);
  if (repeatFloat < 0) return value;
  const slicedRepeatFloat = parseFloat(value.toFixed(2));
  return Math.round(slicedRepeatFloat * tenPowertoFull) / tenPowertoFull;
};

export const currencyNumber = (value: number | string) => {
  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
  });

  return formatter.format(Number(value));
};
