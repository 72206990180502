<template>
  <div class="relative">
    <!-- banner -->
    <div class="relative">
      <carousel
        :items-to-show="1"
        :items-to-scroll="banner_page"
        :wrap-around="true"
        ref="banner_slide"
      >
        <slide v-for="(slide, i) in news" :key="`news-${i}`">
          <div
            class="w-full flex items-center"
            style="
              min-height: 60vh;
              background: url(/images/gw-banner/img-1.webp);
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            "
          >
            <div class="container mx-auto my-8 px-4">
              <div class="text-white font-semibold text-4xl text-center">
                You may wait, but global <br />warming will not.
              </div>
            </div>
          </div>
        </slide>
      </carousel>

      <div
        class="absolute left-8 top-1/2 cursor-pointer"
        @click="
          banner_page = banner_page < 1 ? banners.length - 1 : banner_page - 1
        "
      >
        <img src="@/assets/left.svg" class="w-10" />
      </div>
      <div
        class="absolute right-8 top-1/2 cursor-pointer"
        @click="
          banner_page = banner_page > banners.length - 2 ? 0 : banner_page + 1
        "
      >
        <img src="@/assets/left.svg" class="w-10 transform rotate-180" />
      </div>
      <div class="absolute right-8 bottom-8 max-w-xs w-full">
        <div class="text-white">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </div>
        <div class="flex space-x-2 mt-4">
          <div
            v-for="(b, i) in banners"
            :key="`banner-${i}`"
            class="flex-1 bg-white h-1 cursor-pointer"
            :class="{ 'opacity-30': i != banner_page }"
            @click="banner_page = i"
          ></div>
        </div>
      </div>
    </div>

    <!-- our world -->
    <div class="container mx-auto my-16 px-4">
      <div class="my-4 font-semibold text-3xl max-w-lg mx-auto text-center">
        Our world is getting warmer. Soon it will be too late to stop it.
      </div>
      <div class="my-4 max-w-3xl mx-auto text-center text-gray-500">
        Since the pre-industrial period, human activities are estimated to have
        increased Earth’s global average temperature by about 1°C, a number that
        is currently increasing by 0.2°C per decade.
      </div>
      <div class="my-6 flex flex-wrap">
        <div
          v-for="(ow, i) in ourWorlds"
          :key="`ow-${i}`"
          class="flex space-x-4 p-3 w-full md:w-1/3"
        >
          <div
            class="bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center mt-1"
          >
            <img class="w-4" :src="ow.img" />
          </div>
          <div class="flex-1">
            <div class="font-semibold">{{ ow.title }}</div>
            <div class="text-sm text-gray-500">
              {{ ow.des }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-5/12">
          <landing-image-link
            to="/landing/global-warming/extreme-weather"
            text="Extreme Weather"
            bg="/images/gw-our-world/img-1.png"
          />

          <landing-image-link
            to="/landing/global-warming/rising-sea-level"
            text="Rising Sea Level"
            bg="/images/gw-our-world/img-2.png"
          />
        </div>
        <div class="w-7/12">
          <landing-image-link
            to="/landing/global-warming/glacial-melt"
            text="Glacial Melt"
            bg="/images/gw-our-world/img-3.png"
          />

          <landing-image-link
            to="/landing/global-warming/ocean-acidification"
            text="Ocean Acidification"
            bg="/images/gw-our-world/img-4.png"
          />
        </div>
      </div>
    </div>

    <!-- what doing -->
    <!-- <div class="container mx-auto my-16 px-4">
      <div class="my-4 font-semibold text-3xl max-w-sm mx-auto text-center">
        โปรเจคเราทำอะไรบ้าง และมีเป้าหมายยังไง
      </div>
      <div class="my-4 max-w-xl mx-auto text-center">
        NEAR is eliminating the barriers to Web 3 adoption. With high speeds,
        low fees, and progressive UX, NEAR’s climate-neutral blockchain is ready
        for explosive growth.
      </div>

      <div class="mt-8 mb-16 flex space-x-12">
        <div class="hidden md:block w-1/3">
          <img class="w-full" src="/images/gw-target/tree.png" />
        </div>
        <div class="w-2/3">
          <div class="text-primary font-lg mb-2">
            EVERY TREE PLANTED MATTERS
          </div>
          <div class="text-2xl font-semibold my-2">
            Our trees have been monitored twice and are alive and healthy.
          </div>
          <div v-for="i in 3" :key="`hand-${i}`" class="my-6 flex space-x-4">
            <img class="w-10 h-10" src="/images/gw-target/hand.png" />
            <div class="flex-1">
              <div class="text-2xl font-semibold text-primary-dark">
                1,935,180
              </div>
              <div class="text-sm">Trees planted</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- news -->
    <div class="container mx-auto my-16 px-4">
      <div class="flex items-center">
        <div class="flex-1 text-3xl font-semibold my-4">
          Update <span class="text-primary-dark">News</span> and
          <span class="text-primary-dark">Blog</span> about the project.
        </div>
        <div class="flex space-x-2">
          <div class="text-sm text-primary-dark">view all</div>
          <div
            class="w-6 h-6 rounded-full p-2"
            :class="{
              'bg-gray-200': news_page == 0,
              'bg-primary ': news_page != 0,
            }"
            @click="news_page = news_page < 1 ? 0 : news_page - 1"
          >
            <img
              src="@/assets/arrow.svg"
              class="w-4"
              :class="{
                'opacity-25': news_page == 0,
                'filter invert': news_page != 0,
              }"
            />
          </div>
          <div
            class="w-6 h-6 rounded-full p-2"
            :class="{
              'bg-gray-200': news_page == news.length - 1,
              'bg-primary f': news_page != news.length - 1,
            }"
            @click="
              news_page =
                news_page >= news.length - 1 ? news.length - 1 : news_page + 1
            "
          >
            <img
              src="@/assets/arrow.svg"
              class="transform rotate-180"
              :class="{
                'opacity-25': news_page == news.length - 1,
                'filter invert ': news_page != news.length - 1,
              }"
            />
          </div>
        </div>
      </div>

      <div>
        <carousel
          :items-to-show="3"
          :items-to-scroll="news_page"
          :wrap-around="true"
          ref="news_slide"
        >
          <slide v-for="(slide, i) in news" :key="`news-${i}`">
            <div class="p-3">
              <div class="shadow-md rounded-md overflow-hidden">
                <img :src="slide.img" class="w-full" />

                <div class="px-4 py-3 text-left">
                  {{ slide.title }}
                </div>
                <div class="px-4 text-xs text-primary text-left">
                  Global Warming
                </div>
                <div class="px-4 text-xs text-left mb-4">
                  Monday, October 4, 2021 15:00
                </div>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>

    <!-- Solutions for Scaling -->
    <div class="container mx-auto my-16 px-8">
      <div
        class="relative rounded-2xl overflow-hidden flex"
        style="
          min-height: 34vh;
          background: url(/images/gw-solutions-scaling/bg.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        "
      >
        <div class="flex-1 md:w-2/3 px-8">
          <div class="mt-16 mb-8 text-4xl text-white font-semibold">
            Solutions for Scaling
          </div>
          <div class="my-4 text-white">
            NEAR is eliminating the barriers to Web 3 adoption. With high
            speeds, low fees, and progressive UX, NEAR’s climate-neutral
            blockchain is ready for explosive growth.
          </div>
          <div class="my-8">
            <router-link
              class="outline-none border border-white px-6 py-2 text-sm font-semibold text-white"
              to="/"
            >
              Go to project
            </router-link>
          </div>
        </div>
        <img
          class="hidden lg:block w-1/3"
          src="/images/gw-solutions-scaling/tree.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import LandingImageLink from '../components/Landing/LandingImageLink.vue';

export default {
  components: { Carousel, Slide, LandingImageLink },
  watch: {
    banner_page(val) {
      this.$refs.banner_slide.slideTo(val);
    },
    news_page(val) {
      this.$refs.news_slide.slideTo(val);
    },
  },
  computed: {},
  mounted() {
    this.$refs.news_slide.slideTo(1);
  },
  data() {
    return {
      banners: [{}, {}, {}, {}],
      banner_page: 0,
      news: [
        {
          img: '/images/gw-news/1.png',
          title: 'AR6 Synthesis Report: Climate Change 2022',
          date: 'Monday, October 4, 2021 15:00',
        },
        {
          img: '/images/gw-news/2.png',
          title: 'AR4 Climate Change 2007: Synthesis Report',
          date: 'Monday, October 4, 2021 15:00',
        },
        {
          img: '/images/gw-news/3.png',
          title:
            'Special Report on the Ocean and Cryosphere in a Changing Climate',
          date: 'Monday, October 4, 2021 15:00',
        },
      ],
      news_page: 1,
      ourWorlds: [
        {
          img: '/images/gw-our-world/cause.png',
          title: 'Cause',
          des: 'Global warming is the long-term heating of Earth’s climate system due to human activities, primarily fossil fuel burning.',
        },
        {
          img: '/images/gw-our-world/effect.png',
          title: 'Effect',
          des: 'Extreme weather: rise in global temperature causes extreme weather conditions which results in extreme heat waves.',
        },
        {
          img: '/images/gw-our-world/solution.png',
          title: 'Solution',
          des: 'The decrease of carbon emission can decrease the accumulated carbon trapped within Earth’s atmosphere, which in turn can forestall extreme weather, glacial melt.',
        },
      ],
    };
  },
};
</script>

<style scoped>
.bg-gradient-transparent {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
</style>
