import { SmartContactKey } from '@/symbols/smart-contracts';
import { inject, reactive } from 'vue';

export const useSmartContract = () => {
  const contracts = inject(
    SmartContactKey,
    reactive({ nft: undefined, token: undefined })
  );

  return contracts;
};
