
import Moralis from 'moralis';
import {
  AppConfig,
  defineComponent,
  getCurrentInstance,
  onMounted,
  provide,
  reactive,
} from 'vue';
import {
  AGGREGATOR_ADDRESS,
  CARBON_ADDRESS,
  BIODIVERSITY_ADDRESS,
  USDT_ADDRESS,
} from './constants';
import { MoralisKey } from './symbols/moralis';
import { SmartContactKey, SmartContracts } from './symbols/smart-contracts';
import AggregatorABI from './abis/Aggregator.json';
import TokenABI from './abis/Token.json';
import BEPTokenABI from './abis/USDTToken.json';
import { useUserStore } from './store/user';
import ModalVerifyAccount from './components/Modals/ModalVerifyAccount.vue';
import { useCardStore } from './store/card';
import Web3 from 'web3';
import useToastMessage from '@/composables/useToastMessage';
import { AbiItem } from 'web3-utils';

export default defineComponent({
  setup() {
    const meta_des = document.querySelector('meta[property="og:description"]');
    const meta_des2 = document.querySelector('meta[name="description"]');
    const { isLoading } = useToastMessage();
    if (meta_des && meta_des2) {
      meta_des.setAttribute(
        'content',
        'The future of our planet and the chance to save itis in our hands.'
      );
      meta_des2.setAttribute(
        'content',
        'The future of our planet and the chance to save itis in our hands.'
      );
    }
    const meta_img = document.querySelector('meta[itemprop="image"]');
    const meta_ogimg = document.querySelector('meta[property="og:image"]');

    if (meta_img && meta_ogimg) {
      meta_img.setAttribute('content', '/banner.png');

      meta_ogimg.setAttribute('content', '/banner.png');
    }

    const card = useCardStore();
    const userStore = useUserStore();
    const config = getCurrentInstance()?.appContext.config as AppConfig;
    const moralis = config.globalProperties.$moralis as Moralis;
    const smartContracts = reactive<SmartContracts>({});
    provide(MoralisKey, moralis);
    provide(SmartContactKey, smartContracts);
    onMounted(async () => {
      card.initialize();
      userStore.getMe();
      await Moralis.enableWeb3()
        .then(async () => {
          const web3 = new Web3((window as any).ethereum);
          const createContract = (abi: AbiItem[], address: string): any =>
            new web3.eth.Contract(abi, address);

          smartContracts.aggregator = createContract(
            AggregatorABI as AbiItem[],
            AGGREGATOR_ADDRESS
          );
          smartContracts.carbon = createContract(
            TokenABI as AbiItem[],
            CARBON_ADDRESS
          );
          smartContracts.bio = createContract(
            TokenABI as AbiItem[],
            BIODIVERSITY_ADDRESS
          );
          smartContracts.usdt = createContract(
            BEPTokenABI as AbiItem[],
            USDT_ADDRESS
          );
        })
        .catch((error) => {
          console.log(error);
        });
    });

    return { isLoading };
  },
  components: { ModalVerifyAccount },
});
