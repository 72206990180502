<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
  >
    <path
      d="M20.3711 40C31.4168 40 40.3711 31.0457 40.3711 20C40.3711 8.95431 31.4168 0 20.3711 0C9.3254 0 0.371094 8.95431 0.371094 20C0.371094 31.0457 9.3254 40 20.3711 40Z"
      fill="black"
    />
    <path
      d="M17.7178 17.1432V16.0566C17.3511 16.0099 16.9844 15.9766 16.6111 15.9766C12.0644 15.9766 8.37109 19.6699 8.37109 24.2099C8.37109 26.9966 9.76443 29.4632 11.8844 30.9499C10.5111 29.4766 9.69776 27.5099 9.69776 25.3432C9.70443 20.8699 13.2644 17.2499 17.7178 17.1432Z"
      fill="#25F4EE"
    />
    <path
      d="M17.9099 29.1285C19.9366 29.1285 21.6099 27.4952 21.6899 25.4885L21.7099 7.58187H24.9566L24.9499 7.55521C24.8833 7.18854 24.8433 6.80854 24.8433 6.42188H20.3699L20.3633 24.3552C20.2899 26.3619 18.6366 27.9752 16.6099 27.9752C15.9833 27.9752 15.3899 27.8152 14.8633 27.5419C15.5433 28.4885 16.6566 29.1285 17.9099 29.1285Z"
      fill="#25F4EE"
    />
    <path
      d="M31.0664 13.6967V12.6434C29.8197 12.6434 28.653 12.2701 27.6797 11.6367C28.5464 12.6367 29.7264 13.4101 31.0664 13.6967Z"
      fill="#25F4EE"
    />
    <path
      d="M27.6791 11.6347C26.7258 10.5414 26.1458 9.11469 26.1458 7.55469H24.9258C25.2458 9.26135 26.2791 10.7214 27.6791 11.6347Z"
      fill="#FE2C55"
    />
    <path
      d="M16.6031 20.416C14.5297 20.416 12.8164 22.136 12.8164 24.2094C12.8164 25.656 13.6631 26.9094 14.8564 27.536C14.4097 26.916 14.1431 26.1627 14.1431 25.3427C14.1431 23.2694 15.8297 21.5827 17.9031 21.5827C18.2897 21.5827 18.6631 21.6494 19.0097 21.756V17.1894C18.6431 17.1427 18.2764 17.1094 17.9031 17.1094C17.8364 17.1094 17.7764 17.116 17.7097 17.116H17.6897L17.6764 20.576C17.3364 20.4627 16.9897 20.416 16.6031 20.416Z"
      fill="#FE2C55"
    />
    <path
      d="M31.0628 13.6434L31.0428 13.6367V17.0901C28.7228 17.0901 26.5695 16.3234 24.8095 15.0701V24.2101C24.8095 28.7501 21.1428 32.4234 16.6028 32.4234C14.8495 32.4234 13.2228 31.8901 11.8828 30.9567C13.3895 32.5701 15.5295 33.5834 17.9095 33.5834C22.4495 33.5834 26.1428 29.8901 26.1428 25.3501V16.2567C27.8961 17.5167 30.0495 18.2567 32.3695 18.2567V13.7834C31.9228 13.7834 31.4828 13.7367 31.0628 13.6434Z"
      fill="#FE2C55"
    />
    <path
      d="M24.8447 24.208V15.1214C26.5981 16.3814 28.7514 17.1214 31.0714 17.1214V13.6414C29.7314 13.3547 28.5514 12.6347 27.6847 11.6347C26.2847 10.7214 25.2714 9.26135 24.9581 7.55469H21.6847L21.6781 25.488C21.6047 27.4947 19.9514 29.108 17.9247 29.108C16.6714 29.108 15.5581 28.488 14.8781 27.5414C13.6781 26.908 12.8647 25.6547 12.8647 24.2147C12.8647 22.1414 14.5514 20.4547 16.6247 20.4547C17.0114 20.4547 17.3847 20.5214 17.7314 20.628V17.1214C13.2781 17.228 9.69141 20.8747 9.69141 25.3547C9.69141 27.5214 10.5314 29.488 11.9047 30.9614C13.2447 31.9014 14.8714 32.4547 16.6247 32.4547C21.1447 32.448 24.8447 28.7547 24.8447 24.208Z"
      fill="white"
    />
  </svg>
</template>
