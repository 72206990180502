import { Plugin } from 'vue';

interface GenericStringFn {
  (text: string): string;
}

const upperFirst: GenericStringFn = (text) => {
  return text[0].toUpperCase() + text.slice(1);
};

const camelCase: GenericStringFn = (text) => {
  return text
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word : word.toUpperCase()
    )
    .replace(/\W/g, '');
};

const requireComponent = require.context(
  // The relative path of the components folder
  '@/components/Base',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
);

const globalComponents: Plugin = {
  install: (app) => {
    requireComponent.keys().forEach((fileName) => {
      // Get component config
      const componentConfig = requireComponent(fileName);

      // Get PascalCase name of component
      const componentName = upperFirst(
        camelCase(
          // Gets the file name regardless of folder depth
          (fileName.split('/').pop() as string).replace(/\.\w+$/, '')
        )
      );

      app.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
      );
    });
  },
};
export default globalComponents;
