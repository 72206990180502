<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="308"
    height="208"
    viewBox="0 0 308 208"
    fill="none"
  >
    <path
      d="M299.308 201.941H5.47461V202.749H299.308V201.941Z"
      fill="#3F3D56"
    />
    <path
      d="M5.65493 208C8.77807 208 11.3099 205.468 11.3099 202.345C11.3099 199.222 8.77807 196.69 5.65493 196.69C2.5318 196.69 0 199.222 0 202.345C0 205.468 2.5318 208 5.65493 208Z"
      fill="#3F3D56"
    />
    <path
      d="M301.731 208C304.854 208 307.386 205.468 307.386 202.345C307.386 199.222 304.854 196.69 301.731 196.69C298.608 196.69 296.076 199.222 296.076 202.345C296.076 205.468 298.608 208 301.731 208Z"
      fill="#3F3D56"
    />
    <path
      d="M165.094 202.366H49.9761C49.0123 202.365 48.0883 201.981 47.4068 201.3C46.7253 200.618 46.3419 199.694 46.3408 198.731V3.63531C46.3419 2.6715 46.7253 1.74748 47.4068 1.06596C48.0883 0.38444 49.0123 0.0010862 49.9761 0H165.094C166.058 0.0010862 166.982 0.38444 167.664 1.06596C168.345 1.74748 168.729 2.6715 168.73 3.63531V198.731C168.729 199.694 168.345 200.618 167.664 201.3C166.982 201.981 166.058 202.365 165.094 202.366ZM49.9761 0.807848C49.2265 0.808722 48.5078 1.1069 47.9778 1.63696C47.4477 2.16702 47.1495 2.88569 47.1487 3.63531V198.731C47.1495 199.48 47.4477 200.199 47.9778 200.729C48.5078 201.259 49.2265 201.557 49.9761 201.558H165.094C165.844 201.557 166.563 201.259 167.093 200.729C167.623 200.199 167.921 199.48 167.922 198.731V3.63531C167.921 2.88569 167.623 2.16702 167.093 1.63696C166.563 1.1069 165.844 0.808722 165.094 0.807848H49.9761Z"
      fill="#3F3D56"
    />
    <path
      d="M123.289 11.7139H91.7828C91.6757 11.7139 91.573 11.6713 91.4972 11.5955C91.4215 11.5198 91.3789 11.4171 91.3789 11.3099C91.3789 11.2028 91.4215 11.1001 91.4972 11.0243C91.573 10.9486 91.6757 10.906 91.7828 10.906H123.289C123.396 10.906 123.499 10.9486 123.575 11.0243C123.65 11.1001 123.693 11.2028 123.693 11.3099C123.693 11.4171 123.65 11.5198 123.575 11.5955C123.499 11.6713 123.396 11.7139 123.289 11.7139Z"
      fill="#3F3D56"
    />
    <path
      d="M151.765 187.825H62.9019C61.9381 187.824 61.0141 187.44 60.3326 186.759C59.651 186.077 59.2677 185.153 59.2666 184.189V25.8511C59.2677 24.8873 59.651 23.9633 60.3326 23.2818C61.0141 22.6003 61.9381 22.2169 62.9019 22.2158H151.765C152.729 22.2169 153.653 22.6003 154.335 23.2818C155.016 23.9633 155.399 24.8873 155.4 25.8511V184.189C155.399 185.153 155.016 186.077 154.335 186.759C153.653 187.44 152.729 187.824 151.765 187.825ZM62.9019 23.0237C62.1523 23.0245 61.4336 23.3227 60.9036 23.8528C60.3735 24.3828 60.0753 25.1015 60.0744 25.8511V184.189C60.0753 184.939 60.3735 185.658 60.9036 186.188C61.4336 186.718 62.1523 187.016 62.9019 187.017H151.765C152.515 187.016 153.233 186.718 153.764 186.188C154.294 185.658 154.592 184.939 154.593 184.189V25.8511C154.592 25.1015 154.294 24.3828 153.764 23.8528C153.233 23.3227 152.515 23.0245 151.765 23.0237H62.9019Z"
      fill="#3F3D56"
    />
    <path
      d="M169.74 41.6042C169.258 41.6037 168.796 41.412 168.455 41.0712C168.114 40.7305 167.922 40.2684 167.922 39.7865V32.112C167.922 31.6299 168.113 31.1676 168.454 30.8267C168.795 30.4858 169.257 30.2943 169.74 30.2943C170.222 30.2943 170.684 30.4858 171.025 30.8267C171.366 31.1676 171.557 31.6299 171.557 32.112V39.7865C171.557 40.2684 171.365 40.7305 171.024 41.0712C170.683 41.412 170.221 41.6037 169.74 41.6042ZM169.74 31.1022C169.472 31.1024 169.215 31.2089 169.026 31.3982C168.836 31.5876 168.73 31.8442 168.73 32.112V39.7865C168.73 40.0543 168.836 40.3112 169.025 40.5006C169.215 40.69 169.472 40.7963 169.74 40.7963C170.007 40.7963 170.264 40.69 170.454 40.5006C170.643 40.3112 170.749 40.0543 170.749 39.7865V32.112C170.749 31.8442 170.643 31.5876 170.453 31.3982C170.264 31.2089 170.007 31.1024 169.74 31.1022Z"
      fill="#3F3D56"
    />
    <path
      d="M169.74 54.1258C169.258 54.1253 168.796 53.9336 168.455 53.5928C168.114 53.2521 167.922 52.79 167.922 52.3081V44.6336C167.922 44.1515 168.113 43.6892 168.454 43.3483C168.795 43.0074 169.257 42.8159 169.74 42.8159C170.222 42.8159 170.684 43.0074 171.025 43.3483C171.366 43.6892 171.557 44.1515 171.557 44.6336V52.3081C171.557 52.79 171.365 53.2521 171.024 53.5928C170.683 53.9336 170.221 54.1253 169.74 54.1258ZM169.74 43.6238C169.472 43.624 169.215 43.7305 169.026 43.9198C168.836 44.1092 168.73 44.3658 168.73 44.6336V52.3081C168.73 52.5759 168.836 52.8328 169.025 53.0222C169.215 53.2116 169.472 53.3179 169.74 53.3179C170.007 53.3179 170.264 53.2116 170.454 53.0222C170.643 52.8328 170.749 52.5759 170.749 52.3081V44.6336C170.749 44.3658 170.643 44.1092 170.453 43.9198C170.264 43.7305 170.007 43.624 169.74 43.6238Z"
      fill="#3F3D56"
    />
    <path
      d="M137.628 85.6349H77.0398C76.397 85.6349 75.7806 85.3796 75.326 84.9251C74.8715 84.4706 74.6162 83.8541 74.6162 83.2114C74.6162 82.5686 74.8715 81.9522 75.326 81.4977C75.7806 81.0432 76.397 80.7878 77.0398 80.7878H137.628C138.271 80.7878 138.888 81.0432 139.342 81.4977C139.797 81.9522 140.052 82.5686 140.052 83.2114C140.052 83.8541 139.797 84.4706 139.342 84.9251C138.888 85.3796 138.271 85.6349 137.628 85.6349Z"
      fill="#CCCCCC"
    />
    <path
      d="M137.628 96.5409H77.0398C76.397 96.5409 75.7806 96.2856 75.326 95.8311C74.8715 95.3766 74.6162 94.7602 74.6162 94.1174C74.6162 93.4746 74.8715 92.8582 75.326 92.4037C75.7806 91.9492 76.397 91.6938 77.0398 91.6938H137.628C138.271 91.6938 138.888 91.9492 139.342 92.4037C139.797 92.8582 140.052 93.4746 140.052 94.1174C140.052 94.7602 139.797 95.3766 139.342 95.8311C138.888 96.2856 138.271 96.5409 137.628 96.5409Z"
      fill="#CCCCCC"
    />
    <path
      d="M137.628 107.447H77.0398C76.397 107.447 75.7806 107.191 75.326 106.737C74.8715 106.282 74.6162 105.666 74.6162 105.023C74.6162 104.381 74.8715 103.764 75.326 103.31C75.7806 102.855 76.397 102.6 77.0398 102.6H137.628C138.271 102.6 138.888 102.855 139.342 103.31C139.797 103.764 140.052 104.381 140.052 105.023C140.052 105.666 139.797 106.282 139.342 106.737C138.888 107.191 138.271 107.447 137.628 107.447Z"
      fill="#CCCCCC"
    />
    <path
      d="M116.06 74.729H96.4676C95.2892 74.729 94.159 74.2609 93.3258 73.4276C92.4925 72.5944 92.0244 71.4642 92.0244 70.2858C92.0244 69.1074 92.4925 67.9773 93.3258 67.144C94.159 66.3108 95.2892 65.8427 96.4676 65.8427H116.06C117.238 65.8427 118.369 66.3108 119.202 67.144C120.035 67.9773 120.503 69.1074 120.503 70.2858C120.503 71.4642 120.035 72.5944 119.202 73.4276C118.369 74.2609 117.238 74.729 116.06 74.729Z"
      fill="#064C29"
    />
    <path
      d="M88.7387 144.198C86.9982 144.198 85.2968 143.682 83.8496 142.715C82.4024 141.748 81.2744 140.373 80.6084 138.765C79.9423 137.157 79.768 135.388 80.1076 133.681C80.4471 131.974 81.2853 130.405 82.516 129.175C83.7467 127.944 85.3148 127.106 87.0219 126.766C88.729 126.427 90.4984 126.601 92.1064 127.267C93.7145 127.933 95.0889 129.061 96.0559 130.508C97.0229 131.955 97.539 133.657 97.539 135.397C97.5363 137.731 96.6083 139.967 94.9585 141.617C93.3087 143.267 91.0719 144.195 88.7387 144.198ZM88.7387 127.524C87.1814 127.524 85.6591 127.985 84.3642 128.851C83.0694 129.716 82.0601 130.945 81.4642 132.384C80.8682 133.823 80.7123 135.406 81.0161 136.934C81.3199 138.461 82.0698 139.864 83.171 140.965C84.2722 142.066 85.6752 142.816 87.2026 143.12C88.73 143.424 90.3132 143.268 91.7519 142.672C93.1907 142.076 94.4205 141.067 95.2857 139.772C96.1508 138.477 96.6126 136.955 96.6126 135.397C96.6126 134.363 96.409 133.34 96.0133 132.384C95.6176 131.429 95.0376 130.561 94.3064 129.83C93.5753 129.099 92.7073 128.519 91.7519 128.123C90.7966 127.727 89.7728 127.524 88.7387 127.524Z"
      fill="#3F3D56"
    />
    <path
      d="M88.7535 138.444C88.621 138.444 88.4897 138.417 88.3673 138.367C88.2449 138.316 88.1337 138.241 88.0401 138.147L84.8066 134.914C84.7128 134.82 84.6384 134.709 84.5876 134.586C84.5368 134.464 84.5107 134.332 84.5107 134.2C84.5108 134.067 84.5369 133.936 84.5877 133.813C84.6385 133.691 84.7129 133.579 84.8068 133.486L84.974 133.318C85.1637 133.129 85.4204 133.023 85.6881 133.023C85.9558 133.023 86.2125 133.129 86.4022 133.318L88.0784 134.994C88.1409 135.057 88.2163 135.105 88.2993 135.135C88.3823 135.165 88.4709 135.177 88.559 135.169C88.647 135.162 88.7323 135.135 88.8089 135.091C88.8855 135.047 88.9515 134.987 89.0024 134.914L96.1508 124.756C96.305 124.537 96.5399 124.388 96.8037 124.342C97.0675 124.296 97.3387 124.357 97.5578 124.511L97.7523 124.648C97.8607 124.724 97.953 124.821 98.024 124.933C98.095 125.045 98.1432 125.17 98.1659 125.301C98.1886 125.431 98.1853 125.565 98.1563 125.695C98.1272 125.824 98.073 125.947 97.9966 126.055L89.5799 138.015C89.4953 138.135 89.3852 138.236 89.2575 138.309C89.1298 138.383 88.9875 138.427 88.8407 138.44C88.8115 138.442 88.7825 138.444 88.7535 138.444Z"
      fill="#064C29"
    />
    <path
      d="M123.477 144.198C121.736 144.198 120.035 143.682 118.588 142.715C117.141 141.748 116.013 140.373 115.347 138.765C114.681 137.157 114.506 135.388 114.846 133.681C115.185 131.974 116.024 130.405 117.254 129.175C118.485 127.944 120.053 127.106 121.76 126.766C123.467 126.427 125.237 126.601 126.845 127.267C128.453 127.933 129.827 129.061 130.794 130.508C131.761 131.955 132.277 133.657 132.277 135.397C132.275 137.731 131.347 139.967 129.697 141.617C128.047 143.267 125.81 144.195 123.477 144.198ZM123.477 127.524C121.92 127.524 120.397 127.985 119.103 128.851C117.808 129.716 116.798 130.945 116.202 132.384C115.607 133.823 115.451 135.406 115.754 136.934C116.058 138.461 116.808 139.864 117.909 140.965C119.01 142.066 120.413 142.816 121.941 143.12C123.468 143.424 125.051 143.268 126.49 142.672C127.929 142.076 129.159 141.067 130.024 139.772C130.889 138.477 131.351 136.955 131.351 135.397C131.351 134.363 131.147 133.34 130.752 132.384C130.356 131.429 129.776 130.561 129.045 129.83C128.314 129.099 127.446 128.519 126.49 128.123C125.535 127.727 124.511 127.524 123.477 127.524Z"
      fill="#3F3D56"
    />
    <path
      d="M126.297 139.423H126.295C126.162 139.423 126.031 139.397 125.908 139.346C125.786 139.295 125.675 139.22 125.581 139.126L123.906 137.446C123.79 137.335 123.637 137.273 123.477 137.273C123.317 137.273 123.163 137.335 123.048 137.445L121.372 139.126C121.279 139.22 121.167 139.295 121.045 139.346C120.922 139.397 120.791 139.423 120.658 139.423H120.657C120.524 139.423 120.393 139.397 120.271 139.347C120.148 139.296 120.037 139.221 119.944 139.128L119.776 138.961C119.587 138.772 119.481 138.515 119.48 138.248C119.48 137.98 119.585 137.723 119.774 137.533L121.457 135.845C121.57 135.731 121.633 135.577 121.633 135.417C121.633 135.257 121.57 135.103 121.457 134.989L119.736 133.262C119.547 133.072 119.441 132.815 119.441 132.547C119.442 132.28 119.548 132.023 119.737 131.834L119.905 131.667C119.999 131.573 120.11 131.499 120.232 131.448C120.355 131.398 120.486 131.372 120.618 131.372H120.62C120.752 131.372 120.884 131.398 121.006 131.449C121.129 131.5 121.24 131.575 121.333 131.669L123.048 133.389C123.163 133.499 123.317 133.561 123.477 133.561C123.636 133.561 123.79 133.499 123.906 133.389L125.62 131.669C125.713 131.575 125.825 131.5 125.947 131.449C126.07 131.398 126.201 131.372 126.334 131.372H126.335C126.468 131.372 126.599 131.398 126.721 131.448C126.844 131.499 126.955 131.573 127.048 131.667L127.216 131.834C127.405 132.023 127.512 132.28 127.512 132.547C127.512 132.815 127.406 133.072 127.218 133.262L125.496 134.989C125.383 135.103 125.32 135.256 125.32 135.417C125.32 135.577 125.383 135.731 125.496 135.845L127.179 137.533C127.368 137.723 127.474 137.98 127.473 138.247C127.473 138.515 127.366 138.772 127.177 138.961L127.009 139.128C126.916 139.222 126.805 139.296 126.682 139.347C126.56 139.397 126.429 139.423 126.297 139.423Z"
      fill="#CCCCCC"
    />
    <path
      d="M242.945 197.318H248.109L250.567 177.4H242.945V197.318Z"
      fill="#A0616A"
    />
    <path
      d="M258.068 202.113H241.838V195.843H251.885C252.697 195.843 253.501 196.003 254.251 196.313C255.001 196.624 255.683 197.08 256.257 197.654C256.831 198.228 257.287 198.91 257.598 199.66C257.908 200.41 258.068 201.214 258.068 202.026V202.113Z"
      fill="#2F2E41"
    />
    <path
      d="M244.76 166.453L241.198 170.193L225.081 158.233L230.338 152.715L244.76 166.453Z"
      fill="#A0616A"
    />
    <path
      d="M237.526 171.909L244.455 164.635L248.995 168.96L237.801 180.711L237.738 180.651C236.551 179.52 235.861 177.963 235.821 176.324C235.782 174.684 236.395 173.096 237.526 171.909Z"
      fill="#2F2E41"
    />
    <path
      d="M280.705 111.883C281.032 111.357 281.239 110.766 281.313 110.151C281.387 109.536 281.325 108.912 281.132 108.324C280.939 107.736 280.619 107.197 280.195 106.746C279.771 106.294 279.253 105.941 278.678 105.712L258.403 54.0383L250.5 58.7702L273.023 108.343C272.701 109.335 272.755 110.41 273.173 111.365C273.591 112.32 274.345 113.089 275.292 113.525C276.239 113.961 277.313 114.035 278.31 113.732C279.308 113.43 280.16 112.771 280.705 111.883Z"
      fill="#A0616A"
    />
    <path
      d="M203.747 115.586C204.365 115.542 204.965 115.363 205.506 115.062C206.047 114.76 206.516 114.344 206.878 113.842C207.241 113.34 207.489 112.765 207.605 112.157C207.722 111.549 207.703 110.922 207.551 110.322L237.462 63.5611L228.99 59.9445L202.1 107.291C201.108 107.613 200.269 108.287 199.742 109.187C199.215 110.086 199.036 111.148 199.239 112.17C199.442 113.193 200.013 114.105 200.844 114.735C201.675 115.365 202.708 115.668 203.747 115.586Z"
      fill="#A0616A"
    />
    <path
      d="M258.204 106.708L252.909 185.395L240.257 184.278L237.652 126.226L230.582 151.159L215.324 152.275L221.363 102.987L258.204 106.708Z"
      fill="#2F2E41"
    />
    <path
      d="M228.348 147.438L230.581 151.159L246.211 161.951L235.047 167.905C235.047 167.905 213.463 155.997 215.324 152.275L228.348 147.438Z"
      fill="#2F2E41"
    />
    <path
      d="M269.283 74.8718L264.879 62.4594C263.899 59.698 262.381 57.1583 260.413 54.9874C258.445 52.8164 256.067 51.0573 253.414 49.8118C247.453 47.6156 240.969 47.2607 234.804 48.7931C234.297 48.9189 233.763 49.0515 233.201 49.1909C228.927 50.2272 224.923 52.1615 221.454 54.8654C218.74 57.0049 216.298 59.714 216.081 62.6279C216.08 62.6342 216.077 62.6397 216.076 62.646C215.324 65.9407 214.952 83.0588 214.952 83.0588H221.583L220.534 106.131L220.619 105.964C220.619 105.964 258.864 113.201 258.864 109.48V85.6638L259.648 77.04L269.283 74.8718Z"
      fill="#E6E6E6"
    />
    <path
      d="M233.586 45.569C239.439 45.569 244.184 40.8239 244.184 34.9705C244.184 29.1172 239.439 24.3721 233.586 24.3721C227.732 24.3721 222.987 29.1172 222.987 34.9705C222.987 40.8239 227.732 45.569 233.586 45.569Z"
      fill="#A0616A"
    />
    <path
      d="M232.682 30.5755L223.769 30.5472C222.734 30.5439 221.604 30.5073 220.815 29.8365C219.627 28.8262 219.826 26.92 220.462 25.4962C222.234 21.5313 226.631 19.0133 230.972 19.1299C235.313 19.2465 239.424 21.816 241.762 25.475C244.101 29.1341 244.733 33.7553 243.852 38.0077C242.834 35.8455 241.266 33.9882 239.306 32.6212C237.345 31.2542 235.061 30.4253 232.68 30.2173L232.682 30.5755Z"
      fill="#2F2E41"
    />
  </svg>
</template>
