<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <g filter="url(#filter0_b_3245_23743)">
      <rect width="40" height="40" rx="20" fill="#F6F6F6" fill-opacity="0.15" />
      <path
        d="M16.5 27L23.5 20L16.5 13"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_3245_23743"
        x="-20"
        y="-20"
        width="80"
        height="80"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="10" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_3245_23743"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_3245_23743"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
