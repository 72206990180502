<template>
  <div class="p-2">
    <a :href="to">
      <div class="relative hover-show-more cursor-pointer">
        <img class="w-full img" :src="bg" alt="" />
        <div class="absolute bottom-0 left-0 w-full more">
          <div class="bg-gradient-transparent px-3 py-3 rounded-lg rounded-b">
            <div class="text-white text-sm font-semibold">{{ text }}</div>
            <div class="text-white text-xs">
              Learn more
              <img
                src="@/assets/arrow.svg"
                class="inline-block w-2 filter invert transform rotate-180"
                alt="arrow"
              />
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    bg: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.hover-show-more {
  overflow: hidden;
  @apply rounded-md;
}
.hover-show-more .more {
  display: none;
  transition: 0.2s;
}
.hover-show-more:hover .more {
  display: block;
}

.hover-show-more .img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.hover-show-more:hover .img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.bg-gradient-transparent {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
</style>
