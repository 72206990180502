<template>
  <div>
    <landing-banner
      text="Endangered arctic species are struggling to survive on disappearing
          glacier. Now is the time to stop glacial melt."
      bg="/images/gw-gm/banner-1.webp"
    />

    <div class="container mx-auto my-24 px-4">
      <div>
        <div class="text-2xl font-semibold my-4 text-center">
          Glacial melt is cause by global warming, a phenomena spurred by the
          increase in carbon emission.
        </div>
        <div class="text-sm my-4 max-w-2xl mx-auto text-center text-gray-500">
          Species that depend on sea ice for survival, including polar bears,
          walruses, arctic foxes, etc., must adapt or perish. As their sea ice
          habitat disappears, wildlife encroach on Arctic communities for
          refuge, coming into contact and oftentimes conflict with people.
        </div>

        <div
          class="flex flex-wrap border border-gray-100 shadow-md rounded px-4 py-4 md:divide-x divide-gray-100 my-8"
        >
          <div class="px-6 py-6 w-full md:flex-1">
            <div class="text-center font-semibold text-xl">90 feet</div>
            <div class="text-center text-sm text-green-800">
              of glacial lost
            </div>
          </div>
          <div class="px-6 py-6 w-full md:flex-1">
            <div class="text-center font-semibold text-xl">40 %</div>
            <div class="text-center text-sm text-green-800">
              accelerated glacial melt
            </div>
          </div>
          <div class="px-6 py-6 w-full md:flex-1">
            <div class="text-center font-semibold text-xl">By 2050</div>
            <div class="text-center text-sm text-green-800">
              Arctic will turn uninhabitable
            </div>
          </div>
          <div class="px-6 py-6 w-full md:flex-1">
            <div class="text-center font-semibold text-xl">17 species</div>
            <div class="text-center text-sm text-green-800">endangered</div>
          </div>
        </div>
      </div>
    </div>

    <div class="hidden container mx-auto my-24 px-4">
      <div>
        <div class="text-3xl font-semibold">Glacial melt</div>
        <div class="text-sm my-1 text-gray-400">
          The Arctic and Antarctic ice and snow balance out other parts of the
          world that absorb heat. Less ice means less reflected heat, meaning
          more intense heat waves worldwide.
        </div>
      </div>

      <div class="flex my-4">
        <div
          class="w-full lg:w-1/3 p-2"
          v-for="(l, i) in list"
          :key="`list-${i}`"
        >
          <div class="rounded-md border border-gray-100 shadow-md px-6 py-4">
            <div
              class="bg-gray-200 rounded-full w-10 h-10 flex items-center justify-center mt-1"
            >
              <img class="w-4" :src="l.img" />
            </div>
            <div class="text-lg font-semibold my-2">{{ l.name }}</div>
            <div class="text-xs">
              {{ l.des }}
            </div>
            <a :href="l.to" class="text-green-700 text-sm my-1">
              Learn more
              <img
                src="@/assets/arrow.svg"
                class="inline-block w-2 transform rotate-180"
              />
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- gallery -->
    <div class="hidden container mx-auto my-16 px-4">
      <div class="flex items-center space-x-4">
        <div class="flex-1">
          <div class="text-3xl font-semibold my-4">
            This is what extreme weather looks like.
          </div>
          <div class="text-sm text-gray-500">
            Mass loss from North American glaciers accelerated over the last two
            decades, nearly doubling for the period 2015-2019 compared to
            2000-2004. An exceptionally warm, dry summer in 2021 in western
            North America also took a brutal toll on the region's mountain
            glaciers.
          </div>
        </div>
        <div class="flex space-x-2">
          <div
            class="w-6 h-6 rounded-full p-2"
            :class="{
              'bg-gray-200': gallery_page == 0,
              'bg-primary ': gallery_page != 0,
            }"
            @click="gallery_page = gallery_page < 1 ? 0 : gallery_page - 1"
          >
            <img
              src="@/assets/arrow.svg"
              class="w-4"
              :class="{
                'opacity-25': gallery_page == 0,
                'filter invert': gallery_page != 0,
              }"
            />
          </div>
          <div
            class="w-6 h-6 rounded-full p-2"
            :class="{
              'bg-gray-200': gallery_page == gallerys.length - 1,
              'bg-primary f': gallery_page != gallerys.length - 1,
            }"
            @click="
              gallery_page =
                gallery_page >= gallerys.length - 1
                  ? gallerys.length - 1
                  : gallery_page + 1
            "
          >
            <img
              src="@/assets/arrow.svg"
              class="transform rotate-180"
              :class="{
                'opacity-25': gallery_page == gallerys.length - 1,
                'filter invert ': gallery_page != gallerys.length - 1,
              }"
            />
          </div>
        </div>
      </div>

      <div class="my-4">
        <carousel
          :items-to-show="3"
          :items-to-scroll="gallery_page"
          :wrap-around="true"
          ref="gallery_slide"
        >
          <slide v-for="(slide, i) in gallerys" :key="`gallery-${i}`">
            <div class="p-3">
              <div class="shadow-md rounded-md overflow-hidden">
                <img :src="slide.img" class="w-full" />
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>

    <div class="hidden container mx-auto my-24 px-4">
      <div>
        <div class="text-2xl font-semibold my-4 text-center">
          Incease in Glacial Melt Rate
        </div>
        <div class="text-sm my-4 max-w-2xl mx-auto text-center text-gray-500">
          Statistical increase in glacial melt rate from 2018 to 2027
        </div>

        <div class="my-8">
          <img class="w-full" src="/images/gw-gm/graph-1.svg" />
        </div>
      </div>
    </div>

    <div class="container mx-auto my-24 px-4">
      <div>
        <landing-image-link
          to="/landing/global-warming/extreme-weather"
          text="Extreme Weather"
          bg="/images/gw-ew/img-1.webp"
        />
      </div>

      <div class="flex">
        <landing-image-link
          to="/landing/global-warming/ocean-acidification"
          text="Ocean Acidification"
          bg="/images/gw-ew/img-2.webp"
        />

        <landing-image-link
          to="/landing/global-warming/rising-sea-level"
          text="Rising Sea Level"
          bg="/images/gw-ew/img-3.webp"
        />
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import LandingBanner from '../components/Landing/LandingBanner.vue';
import LandingImageLink from '../components/Landing/LandingImageLink.vue';

export default {
  components: { Carousel, Slide, LandingBanner, LandingImageLink },
  watch: {
    gallery_page(val) {
      this.$refs.gallery_slide.slideTo(val);
    },
  },
  data() {
    return {
      list: [
        {
          img: '/images/gw-our-world/cause.png',
          name: 'Cause',
          des: 'Global warming, the phenomena that is spurred by the increase in carbon emission, causes glacial melt. Rising global temperature and extreme weather cause shrinking ice she...',
          to: '',
        },
        {
          img: '/images/gw-our-world/effect.png',
          name: 'Effect',
          des: 'The Arctic and Antarctic ice and snow balance out other parts of the world that absorb heat. Less ice means less reflected heat, meaning more intense heat waves worldwide. This al...',
          to: '',
        },
        {
          img: '/images/gw-our-world/solution.png',
          name: 'Solution',
          des: 'Reduction of carbon emission can reduce the speed of glacial melt. Planting trees to reduce carbon emission, therefore, is a practical solution to reduce the speed in which arctic g...',
          to: '',
        },
      ],
      gallery_page: 1,
      gallerys: [
        {
          img: '/images/gw-gm/gallery-1.webp',
          to: '',
        },
        {
          img: '/images/gw-gm/gallery-2.webp',
          to: '',
        },
      ],
    };
  },
};
</script>
