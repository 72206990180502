import {
  ForestAll,
  ForestList,
  ForestSummariesThumbnails,
  ForestSummaries,
  ForestGalleries,
  AnnualReport,
} from '@/types/Forest';
import { Blogs, Params, BlogsEntities } from '@/types/Blogs';

import Axios from 'axios';

interface Page {
  page?: number;
  size?: number;
}

interface Query {
  page?: number;
  query?: string;
  reverse?: boolean;
  size?: number;
  sort?: string;
}

interface News {
  email: string;
  re_captcha: string;
}

const base_forest = Axios.create({
  baseURL: '/api/forest',
});

export const setHeaders = (target: string, value?: string): void => {
  if (!value) delete base_forest.defaults.headers.common[target];
  else base_forest.defaults.headers.common[target] = value;
};

export const getAllForest = (params: Query) => {
  return base_forest.get<ForestAll>('/forests', { params });
};

export const getForestInfomation = (id: number) => {
  return base_forest.get<ForestList>(`/forests/${id}`);
};

export const getForestSummariesThumbnails = (id: number) => {
  return base_forest.get<ForestSummariesThumbnails>(`/forests/${id}/summaries/thumbnails
  `);
};

export const getForestSummaries = (id: number, summary_id: number) => {
  return base_forest.get<ForestSummaries>(
    `/forests/${id}/summaries/${summary_id}`
  );
};

export const getForestGalleries = (id: number, page: Page) => {
  return base_forest.get<ForestGalleries>(`/forests/${id}/galleries`, {
    params: page,
  });
};

export const getAnnualReport = () => {
  return base_forest.get<AnnualReport>(`/annual-reports`);
};

export const getAnnualReportForest = (id: number) => {
  return base_forest.get<AnnualReport>(
    `/forests/${id}/summaries/annual-reports`
  );
};

export const getBlogs = (params: Params | undefined) => {
  return base_forest.get<Blogs>('/contents', { params });
};

export const getBlogDetail = (id: number) => {
  return base_forest.get<BlogsEntities>(`/contents/${id}`);
};

export const subscriptionNews = (params: News) => {
  return base_forest.post(`/newsletters/subscriptions`, params);
};
export default base_forest;
