import { createApp } from 'vue';
import Toast from 'vue-toastification';
import VueFinalModal from 'vue-final-modal';
import { pinia } from '@/store/index';
import FloatingVue from 'floating-vue';
import App from './App.vue';
import Moralis from './config/moralis';
import router from './router';
import './index.css';
import globalComponents from './plugins/global-components';
import visible from './directives/visible';
import 'vue-toastification/dist/index.css';
import 'floating-vue/dist/style.css';
import VueCreditCardValidation from 'vue-credit-card-validation';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Vue3Lottie from 'vue3-lottie';
import 'vue3-lottie/dist/style.css';
import vClickOutside from 'click-outside-vue3';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import '@/services/firebase.js';
import { createMetaManager } from 'vue-meta';

const app = createApp(App);

app.config.globalProperties.$moralis = Moralis;
AOS.init({
  easing: 'ease-in', // default easing for AOS animations
});

app.directive('visible', visible);
// component uis
app.use(VueReCaptcha, {
  siteKey: '6LenuNEjAAAAADLPJJYotYW4OPFFqInIwweE4fqt',
  loaderOptions: { autoHideBadge: true },
});
app.use(globalComponents).use(Toast);
app.use(VueCreditCardValidation);
app.use(Vue3Lottie, { name: 'LottieAnimation' });
app.use(VueFinalModal);
app.use(vClickOutside);
app.use(createMetaManager());
app.use(FloatingVue, {
  themes: {
    'no-arrow': {
      $extend: 'dropdown',
    },
  },
});
// core plugins
app.use(router).use(pinia);
(async () => {
  await router.isReady();
})().catch(() => {
  // Deal with the fact the chain failed
});
app.mount('#app');
