import * as api from '@/apis';
import { defineStore } from 'pinia';
import { useUserStore } from '../user';
import { Currency, DonateMethod, DonateResponse } from '@/types/Donate';
import { usePricesStore } from '../prices';

type CreateToken = any;
type CreateTokenParams = Parameters<CreateToken>;
type PayForm = CreateTokenParams[1];

interface CardError {
  number: string;
  expiration: string;
  name: string;
  cvv: string;
}

interface State {
  initialized: boolean;
  form: Partial<PayForm> | any;
  loading: boolean;
  error: CardError;
}

const MASK_SINGLE_MONTH = '{{9}}/{{99}}';
const MASK_DOUBLE_MONTH = '{{99}} / {{99}}';

export const useCardStore = defineStore('card', {
  state: (): State => ({
    initialized: false,
    form: {
      expiration: '',
    },
    loading: false,
    error: {
      number: '',
      expiration: '',
      name: '',
      cvv: '',
    },
  }),
  getters: {
    isFormFilled: (state) => {
      return (
        state.form.name &&
        state.form.number &&
        state.form.expiration_month &&
        state.form.expiration_year &&
        true
      );
    },
    expirationMask: (state) => {
      if (
        state.form.expiration_month &&
        state.form.expiration_month > 1 &&
        state.form.expiration_month < 10
      ) {
        return MASK_SINGLE_MONTH;
      }
      return MASK_DOUBLE_MONTH;
    },
  },
  actions: {
    initialize() {
      // if (this.initialized) return;
      // Omise.setPublicKey(OMISE_PUBLIC_KEY);
      // this.initialized = true;
    },
    setCard(updatedCard: Partial<PayForm>) {
      this.form = { ...this.form, ...updatedCard };
    },
    clearCard() {
      this.form = {};
      this.clearError();
    },
    clearError() {
      this.error = {
        number: '',
        expiration: '',
        name: '',
        cvv: '',
      };
    },
    setExpiration(payload: string) {
      this.form.expiration =
        payload.length > 7 ? this.form.expiration.slice(0, -1) : payload;

      this.form.expiration_month = this.form.expiration.slice(0, 2);
      this.form.expiration_year = this.form.expiration.slice(-2);
    },
    async pay(payload: {
      contracts: number;
      currency: Currency;
      recipient: string;
      token: string;
    }) {
      const { items } = usePricesStore();
      const currentCurrency = items.find((e) => e.symbol === payload.currency);
      const user = useUserStore();
      this.loading = true;
      this.clearError();

      const res: DonateResponse = await new Promise((resolve, reject) => {
        try {
          const totalPaid =
            payload.contracts * (currentCurrency?.pricePerContract ?? 1);
          if (user.me != null) {
            api
              .donate({
                cardToken: payload.token,
                sessionToken: user.attributes.sessionToken as string,
                total: totalPaid,
                type: DonateMethod.credit,
                currency: payload.currency,
              })
              .then(function (result: any) {
                resolve(result.data);
              })
              .catch((error) => {
                reject(error);
                this.loading = false;
              });
          } else {
            api
              .guestDonate({
                email: payload.recipient,
                cardToken: payload.token,
                currency: payload.currency,
                total: totalPaid,
                type: DonateMethod.credit,
              })
              .then(function (result: any) {
                resolve(result.data);
              })
              .catch((error) => {
                reject(error);
                this.loading = false;
              });
          }
        } catch (error) {
          reject(error);
          this.loading = false;
        }

        // Omise.createToken(
        //   'card',
        //   this.form as PayForm,
        //   async (statusCode, response) => {
        //     let res;
        //     if (statusCode === 200) {
        //       try {
        //         const totalPaid =
        //           payload.contracts * (currentCurrency?.pricePerContract ?? 1);
        //         if (user.me != null) {
        //           res = await api.donate({
        //             cardToken: response.id,
        //             sessionToken: user.attributes.sessionToken as string,
        //             total: totalPaid,
        //             type: DonateMethod.credit,
        //             currency: payload.currency,
        //           });
        //         } else {
        //           res = await api.guestDonate({
        //             email: payload.recipient,
        //             cardToken: response.id,
        //             currency: payload.currency,
        //             total: totalPaid,
        //             type: DonateMethod.credit,
        //           });
        //         }
        //         resolve(res.data);
        //       } catch (error) {
        //         reject(error);
        //       }
        //     } else if (statusCode === 400) {
        //       const messages = response.message.split(/, (and )?/i);
        //       messages.forEach((message) => {
        //         if (!message) return;
        //         if (message.includes('number')) {
        //           this.error.number = 'Invalid card number.'; //message;
        //         }
        //         if (message.includes('expiration')) {
        //           this.error.expiration = 'Invalid date.'; //message;
        //         }
        //         if (message.includes('security_code')) {
        //           this.error.cvv = 'Invalid CVV.'; //message;
        //         }
        //         if (message.includes('name')) {
        //           this.error.name = 'Invalid cardholder name.'; //message;
        //         }
        //       });
        //       reject(response);
        //     } else {
        //       reject(response);
        //     }
        //     this.loading = false;
        //   }
        // );
      });

      return res;
    },
  },
});
