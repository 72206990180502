<template>
  <svg
    width="43"
    height="39"
    viewBox="0 0 43 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.9732 0.5L23.9521 12.3782L26.9276 5.37033L39.9732 0.5Z"
      fill="#E17726"
      stroke="#E17726"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.02637 0.5L18.9016 12.4906L16.072 5.37033L3.02637 0.5Z"
      fill="#E27625"
      stroke="#E27625"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.2141 28.0361L29.9521 34.5626L39.0717 37.069L41.678 28.1802L34.2141 28.0361Z"
      fill="#E27625"
      stroke="#E27625"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.33789 28.1802L3.92831 37.069L13.0479 34.5626L8.80179 28.0361L1.33789 28.1802Z"
      fill="#E27625"
      stroke="#E27625"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5481 17.0079L10.0068 20.8506L19.0472 21.2528L18.7414 11.5264L12.5481 17.0079Z"
      fill="#E27625"
      stroke="#E27625"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.4512 17.008L24.1613 11.4141L23.9521 21.2529L32.9925 20.8507L30.4512 17.008Z"
      fill="#E27625"
      stroke="#E27625"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.0479 34.5626L18.5012 31.909L13.8036 28.2451L13.0479 34.5626Z"
      fill="#E27625"
      stroke="#E27625"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.499 31.909L29.9524 34.5626L29.1966 28.2451L24.499 31.909Z"
      fill="#E27625"
      stroke="#E27625"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.9524 34.5619L24.499 31.9082L24.9331 35.4612L24.8856 36.9559L29.9524 34.5619Z"
      fill="#D5BFB2"
      stroke="#D5BFB2"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.0479 34.5619L18.1146 36.9559L18.0829 35.4612L18.5012 31.9082L13.0479 34.5619Z"
      fill="#D5BFB2"
      stroke="#D5BFB2"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.2108 25.8968L13.6748 24.5636L16.8752 23.1006L18.2108 25.8968Z"
      fill="#233447"
      stroke="#233447"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.7891 25.8968L26.1247 23.1006L29.3409 24.5636L24.7891 25.8968Z"
      fill="#233447"
      stroke="#233447"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.0478 34.5626L13.8353 28.0361L8.80176 28.1802L13.0478 34.5626Z"
      fill="#CC6228"
      stroke="#CC6228"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.165 28.0361L29.9525 34.5626L34.2144 28.1802L29.165 28.0361Z"
      fill="#CC6228"
      stroke="#CC6228"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.9925 20.8506L23.9521 21.2528L24.7887 25.8967L26.1243 23.1005L29.3405 24.5635L32.9925 20.8506Z"
      fill="#CC6228"
      stroke="#CC6228"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.6746 24.5635L16.875 23.1005L18.2106 25.8967L19.0472 21.2528L10.0068 20.8506L13.6746 24.5635Z"
      fill="#CC6228"
      stroke="#CC6228"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0068 20.8506L13.803 28.2448L13.6746 24.5635L10.0068 20.8506Z"
      fill="#E27525"
      stroke="#E27525"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.3414 24.5635L29.1973 28.2448L32.9934 20.8506L29.3414 24.5635Z"
      fill="#E27525"
      stroke="#E27525"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.0475 21.2529L18.2109 25.8968L19.2725 31.3783L19.4974 24.1615L19.0475 21.2529Z"
      fill="#E27525"
      stroke="#E27525"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.9527 21.2529L23.5186 24.1457L23.7277 31.3783L24.7892 25.8968L23.9527 21.2529Z"
      fill="#E27525"
      stroke="#E27525"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.7891 25.8966L23.7275 31.3781L24.4991 31.9086L29.1967 28.2447L29.3409 24.5635L24.7891 25.8966Z"
      fill="#F5841F"
      stroke="#F5841F"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.6748 24.5635L13.8031 28.2447L18.5008 31.9086L19.2723 31.3781L18.2108 25.8966L13.6748 24.5635Z"
      fill="#F5841F"
      stroke="#F5841F"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.8862 36.9565L24.9337 35.4618L24.5329 35.1088H18.4679L18.0829 35.4618L18.1146 36.9565L13.0479 34.5625L14.8176 36.0081L18.4204 38.5002H24.5804L28.1832 36.0081L29.9529 34.5625L24.8862 36.9565Z"
      fill="#C0AC9D"
      stroke="#C0AC9D"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.4996 31.9083L23.7281 31.3779H19.2729L18.5013 31.9083L18.083 35.4613L18.468 35.1083H24.5329L24.9338 35.4613L24.4996 31.9083Z"
      fill="#161616"
      stroke="#161616"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M40.6484 13.1508L41.9998 6.59267L39.9734 0.5L24.499 11.9776L30.4514 17.0078L38.8628 19.4683L40.7133 17.2976L39.9085 16.7197L41.195 15.5448L40.2143 14.7737L41.5008 13.7937L40.6484 13.1508Z"
      fill="#763E1A"
      stroke="#763E1A"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 6.59267L2.3673 13.1508L1.49907 13.7937L2.78557 14.7737L1.80485 15.5448L3.09135 16.7197L2.2865 17.2976L4.13703 19.4683L12.5484 17.0078L18.5008 11.9776L3.02639 0.5L1 6.59267Z"
      fill="#763E1A"
      stroke="#763E1A"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M38.8634 19.4683L30.4521 17.0078L32.9934 20.8506L29.1973 28.2447L34.2149 28.1798H41.6788L38.8634 19.4683Z"
      fill="#F5841F"
      stroke="#F5841F"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5488 17.0078L4.13745 19.4683L1.33789 28.1798H8.80179L13.8036 28.2447L10.0075 20.8506L12.5488 17.0078Z"
      fill="#F5841F"
      stroke="#F5841F"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.9528 21.2525L24.4994 11.9774L26.9283 5.37012H16.0723L18.5011 11.9774L19.0477 21.2525L19.2568 24.1769L19.2727 31.3779H23.7279L23.7437 24.1769L23.9528 21.2525Z"
      fill="#F5841F"
      stroke="#F5841F"
      stroke-width="0.712817"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
