import { ref } from 'vue';
import * as apis from '@/apis/forest';
import {
  ForestAll,
  ForestList,
  ForestSummariesThumbnails,
  ForestSummaries,
  ForestGalleries,
} from '@/types/Forest';
import { useRoute } from 'vue-router';
interface Page {
  page?: number;
  size?: number;
}
const forestAll = ref<ForestAll>({ entities: [], page_information: {} });
const forestDetail = ref<ForestList>();
const forestSummariesThumbnails = ref<ForestSummariesThumbnails>();
const forestSummaries = ref<ForestSummaries>();
const forestGalleries = ref<ForestGalleries>();

export default function useForest() {
  const route = useRoute();
  const id = parseInt(route.params.id as string);

  const formatDate = (date: Date) => {
    const monthAll = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const d = new Date(date);
    const day = d.getDate();
    const month = monthAll[d.getMonth()];
    const year = d.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const getForestAll = async (page: number) => {
    const info = await apis.getAllForest({ sort: 'year', page: page });
    forestAll.value = info.data;
  };

  const getForestDetail = async () => {
    const info = await apis.getForestInfomation(id);
    forestDetail.value = info.data;
  };

  const getForestSummaries = async () => {
    const summariesThumbnails = await apis.getForestSummariesThumbnails(id);
    forestSummariesThumbnails.value = summariesThumbnails.data;
    if (forestSummariesThumbnails.value) {
      const sumId = forestSummariesThumbnails.value.entities[0]?.id;
      await getForestSummariesImpact(id, sumId);
    }
  };

  const getForestSummariesImpact = async (id: number, sumId: number) => {
    const summaries = await apis.getForestSummaries(id, sumId);
    forestSummaries.value = summaries.data;
    return forestSummaries.value;
  };

  const getForestGalleries = async (payload: Page) => {
    const page = { page: payload.page, size: payload.size };
    const response = await apis.getForestGalleries(id, page);
    forestGalleries.value = response.data;
  };

  return {
    forestAll,
    forestDetail,
    forestSummaries,
    forestSummariesThumbnails,
    forestGalleries,
    getForestAll,
    getForestDetail,
    getForestSummaries,
    getForestGalleries,
    formatDate,
    getForestSummariesImpact,
  };
}
