<script setup>
import { ref, onMounted, watch } from 'vue';
import { Icon } from '@iconify/vue';
import useForest from '@/composables/useForest';
import { useRoute } from 'vue-router';

const {
  forestSummaries,
  forestDetail,
  getForestSummaries,
  forestSummariesThumbnails,
  getForestSummariesImpact,
} = useForest();
const accumulated = ref();
const selectedYear = ref();
const selectedYearIndex = ref(0);
const route = useRoute();

onMounted(async () => {
  await getForestSummaries();
  selectedYear.value =
    forestSummariesThumbnails.value.entities[selectedYearIndex.value].year;
});

watch(selectedYearIndex, async (val) => {
  const thumbnailsLength = forestSummariesThumbnails.value.entities.length;
  if (val >= 0 && val <= thumbnailsLength) {
    selectedYear.value = forestSummariesThumbnails.value.entities[val].year;
    await getForestSummariesImpact(
      route.params.id,
      forestSummariesThumbnails.value.entities[val].id
    );
  }
});

watch(forestSummaries, async (val) => {
  const {
    trees_planted,
    wildlife,
    number_of_species,
    trees_survival_rate,
    biodiversity_index,
    co_2_sequestered,
  } = val;

  accumulated.value = [
    {
      name: 'TREES PLANTED',
      value: trees_planted,
      icon: 'forestDetailTree.svg',
    },
    {
      name: 'WILDLIFE',
      value: wildlife,
      icon: 'forestDetailWildlife.svg',
    },
    {
      name: 'NUMBER OF SPECIES',
      value: number_of_species,
      icon: 'forestDetailSpecies.svg',
    },
    {
      name: 'TREES SURVIVAL RATE',
      value: trees_survival_rate,
      icon: 'forestDetailSurvival.svg',
    },
    {
      name: 'BIODIVERSITY INDEX',
      value: biodiversity_index,
      icon: 'forestDetailBio.svg',
    },
    {
      name: 'CO2 SEQUESTERED (KG)',
      value: co_2_sequestered,
      icon: 'forestDetailCo2.svg',
    },
  ];
});
</script>
<template>
  <div class="impact">
    <div class="impact--year mb-16 md:mb-28">
      <div class="select">
        <div
          class="flex"
          v-if="
            selectedYearIndex < forestSummariesThumbnails?.entities.length - 1
          "
          @click="selectedYearIndex += 1"
        >
          <Icon class="text-xl mt-0.5" icon="ic:round-navigate-before" />
          <span>
            {{
              forestSummariesThumbnails.entities[selectedYearIndex + 1]?.year
            }}
          </span>
        </div>
      </div>
      <p
        class="font-sarabun mx-auto px-2 py-2 text-2xl md:text-3xl"
        v-if="forestDetail"
      >
        {{ forestDetail.name }},{{ forestDetail.country }} {{ selectedYear }}
      </p>
      <div class="select">
        <div
          class="flex"
          @click="selectedYearIndex -= 1"
          v-if="selectedYearIndex != 0"
        >
          <span>
            {{ forestSummariesThumbnails.entities[selectedYearIndex - 1].year }}
          </span>
          <Icon class="text-xl mt-0.5" icon="ic:round-navigate-next" />
        </div>
      </div>
    </div>
    <div v-if="forestSummaries">
      <p class="impact__header text-24px lg-text-32px">Accumulated Impact</p>
      <div
        class="grid md:grid-cols-3 grid-cols-1 justify-items-center text-center mt-16 md:px-0 px-4"
      >
        <div v-for="(data, i) in accumulated" :key="i">
          <img
            :src="require(`@/assets/svg/forest-detail/${data.icon}`)"
            alt="icon"
            class="mx-auto mb-6"
          />
          <div class="impact--value">{{ data.value }}</div>
          <div class="impact--name">{{ data.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.impact {
  @apply container mx-auto;

  &--year {
    @apply font-bold text-center border-b flex justify-between;
    font-size: 56px;

    @media screen and (max-width: 768px) {
      font-size: 28px;
    }

    .select {
      @apply text-base font-medium text-gray-description self-center w-24;
    }
  }

  &__header {
    @apply font-extrabold text-center uppercase;
  }

  &--value {
    @apply font-extrabold mb-2;
    font-size: 32px;
  }

  &--name {
    @apply text-gray-description text-base;
  }
}
</style>
