export enum DonateType {
  USDT = '0',
  FIAT = '1',
  BNB = '2',
}

export enum DonateMethod {
  crypto = 0,
  credit = 1,
  paypal = 2,
  promptpay = 3,
  appplay = 4,
  wechatpay = 5,
  googlepay = 6,
  alipay = 7,
  other = 8,
}

export interface Donate {
  amount: string;
  blockTimestamp: string;
  donor: string;
  round: string;
  transactionHash: string;
  value: string;
  type: DonateType;
  currency: string;
}

export type DonateRequestType =
  | 'promptpay'
  | 'scbqr30'
  | 'credit card'
  | 'paypal'
  | number;

export type DonateQRCodeType = Exclude<DonateRequestType, 'credit card'>;

export enum DonateRequestCurrency {
  US = 'us',
  TH = 'th',
}

export type Currency =
  | 'THB'
  | 'USD'
  | 'EURO'
  | 'AUD'
  | 'CHF'
  | 'JPY'
  | 'CNY'
  | 'GBP'
  | 'HKD'
  | 'SGD'
  | 'BNB'
  | 'USDT';

export interface DonateRequest {
  cardToken?: string;
  currency?: Currency;
  sessionToken: string;
  total: number;
  type: DonateRequestType;
}

export interface GuestDonateRequest {
  email: string;
  cardToken?: string;
  currency?: Currency;
  total: number;
  type: DonateRequestType;
}

export interface DonateResponse {
  amount: number;
  id: string;
  image: string;
  isBase64: boolean;
  paymentUrl: string;
}

export interface DonationFees {
  feeAmount: number;
  transactionFeePercentage: number;
}

export interface DonationInfo {
  id: string;
  email: string;
  objectId: string;
  donationTransactionId: string;
}

interface PaypalPurchaseUnit {
  reference_id: string;
}

export interface DonationPaypalInfo {
  id: string;
  status: string;
  purchase_units: PaypalPurchaseUnit[];
}
