<template>
  <div
    :style="`
      min-height: 60vh;
      background: url(${bg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    `"
    class="px-4 py-20 flex items-center"
  >
    <div class="max-w-2xl mx-auto">
      <div class="text-center text-white my-6 text-4xl font-semibold">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    bg: {
      type: String,
      default: '',
    },
  },
};
</script>
