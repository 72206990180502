import { AGGREGATOR_ADDRESS, BSC_SCAN_URL } from '@/constants';
import { useUserStore } from '@/store/user';
import { reactive, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { useSmartContract } from './useSmartContract';
import { toBN } from 'web3-utils';
import useToastMessage from './useToastMessage';
import type { TransactionReceipt } from 'web3-core/types';
import BN from 'bn.js';
import { USDTToken } from '@/types/USDTToken';
import { useCardStore } from '@/store/card';
import { Currency } from '@/types/Donate';
import router from '@/router';
import Moralis from 'moralis';
import Web3 from 'web3';

interface UseDonate {
  initialAmount?: number;
  onAfterConfirmed?: () => void;
}

export type DonateType = 'bnb' | 'usdt' | 'fiat' | 'promptpay';

const web3 = new Web3((window as any).ethereum);

interface DonateFn {
  (email: string, type: DonateType, currency?: Currency): void;
}
interface DonateFn {
  (
    email: string,
    type: Extract<DonateType, 'fiat' | 'promptpay'>,
    currency: Currency,
    token?: string
  ): void;
}

export default function useDonate(props: UseDonate = {}) {
  const user = useUserStore();
  const smartContract = useSmartContract();
  const toast = useToast();
  const recipient = ref('');
  const isModalActive = ref(false);
  const isModalPaypalActive = ref(false);
  const { error: showError } = useToastMessage();

  const amount = ref<number | undefined>(props.initialAmount);
  const isDonating = ref(false);

  const openBscScan = (transactionHash: string) =>
    window.open(BSC_SCAN_URL + transactionHash, '_blank');

  const onMetamaskConfirm = (receipt: string) => {
    isDonating.value = false;
    props.onAfterConfirmed?.();
    toast.info('Your donation has been sent. Click to view on BscScan.', {
      timeout: false,
      onClick: () => openBscScan(receipt),
    });
  };

  const showSuccessDonation = (receipt: TransactionReceipt) => {
    toast.success(
      'Your donation transaction has been completed. Click to view on BscScan.',
      {
        timeout: false,
        onClick: () => openBscScan(receipt.transactionHash),
      }
    );
    router.push(`/donate/success/${receipt.transactionHash}`);
  };

  // const donateByBNB = async (email: string) => {
  //   if (!amount.value || !recipient.value) return;
  //   const sendOption = {
  //     value: toBN(CONTRACT_FEE_WEI).muln(amount.value),
  //     from: user.ethAddress,
  //   };
  //   return smartContract.aggregator?.methods
  //     .donateWithBNB(email, amount.value)
  //     .send(sendOption)
  //     .on('transactionHash', onMetamaskConfirm)
  //     .on('receipt', (receipt) => {
  //       showSuccessDonation(receipt, email);
  //     });
  // };

  const usdtProgress = reactive({
    active: false,
    loading: false,
    current: 0,
  });
  const approveUSDT = async (value: BN) => {
    const usdt = smartContract.usdt as USDTToken;
    const from = (user.ethAddress as string) || (await user.getAccount());
    // get USDT allowance & validate allowance
    const allowance = await usdt.methods
      .allowance(from, AGGREGATOR_ADDRESS)
      .call({ from });

    // if allowance is insufficient, it should ask for approval
    const isInsufficient = value.gt(toBN(allowance));
    const MAXIMUM_ALLOWANCE = toBN(2).pow(toBN(256)).sub(toBN(1));
    const gasPrice = await web3.eth.getGasPrice();

    if (isInsufficient) {
      usdtProgress.active = true;
      await usdt.methods
        .approve(AGGREGATOR_ADDRESS, MAXIMUM_ALLOWANCE)
        .send({ from, gasPrice })
        .on('transactionHash', () => {
          usdtProgress.loading = true;
        })
        .on('receipt', () => {
          usdtProgress.loading = false;
          usdtProgress.current = 1;
        });
    }
    return isInsufficient;
  };
  const donateByUSDT = async (email: string) => {
    const { usdt, aggregator } = smartContract;
    const hasAllValue = aggregator && usdt && recipient;
    if (!(hasAllValue && amount.value)) return;
    const from = recipient.value || (await user.getAccount());

    const usdtPrice = await aggregator.methods.priceUSDT().call({ from });
    const donatedUSDT = toBN(usdtPrice as string).muln(amount.value);
    const isInsufficient = await approveUSDT(donatedUSDT);
    const gasPrice = await web3.eth.getGasPrice();
    // execute our donateByUSDT method
    return aggregator.methods
      .donateWithUSDT(email, amount.value, donatedUSDT)
      .send({
        from,
        gasPrice,
      })
      .on('transactionHash', (receipt) => {
        if (isInsufficient) usdtProgress.loading = true;
        onMetamaskConfirm(receipt);
      })
      .on('receipt', (receipt) => {
        if (isInsufficient) {
          usdtProgress.loading = false;
          usdtProgress.current = 2;
        }
        showSuccessDonation(receipt);
      })
      .on('error', (err) => {
        showError(err);
      });
  };

  const card = useCardStore();
  const donateByFiat = async (
    email: string,
    currency: Currency,
    token: string
  ) => {
    // if (!amount.value) return;
    // if (!card.isFormFilled) return;
    try {
      const res = await card.pay({
        currency,
        contracts: amount.value as number,
        recipient: email,
        token,
      });
      if (!res.paymentUrl) {
        props.onAfterConfirmed?.();
        toast.success('Your donation has been completed.');
        router.push(`/donate/success/${res.id}`);
      } else {
        window.location.href = res?.paymentUrl as string;
      }
    } catch (error) {
      isDonating.value = false;
    }
  };

  const donate: DonateFn = async (email, type, currency, token?: string) => {
    isDonating.value = true;

    try {
      switch (type) {
        case 'usdt':
          await donateByUSDT(email);
          break;
        // case 'bnb':
        //   await donateByBNB(email);
        //   break;
        case 'fiat':
          await donateByFiat(email, currency as Currency, token as string);
          break;
        default:
          break;
      }
      amount.value = 1;
    } catch (error) {
      showError(error);
      usdtProgress.active = false;
      usdtProgress.current = 0;
      usdtProgress.loading = false;
      isDonating.value = false;
    }
    isDonating.value = false;

    return;
  };

  watch(
    () => user.ethAddress,
    (value) => {
      value && (recipient.value = value);
    },
    {
      immediate: true,
    }
  );

  return {
    amount,
    recipient,
    isDonating,
    donate,
    // donateByBNB,
    donateByUSDT,
    usdtProgress,
    isModalActive,
    isModalPaypalActive,
  };
}
