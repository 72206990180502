<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="32"
    viewBox="0 0 47 32"
    fill="none"
  >
    <g clip-path="url(#clip0_3682_82974)">
      <path
        d="M45.3913 5.00706C44.8642 3.04941 43.3207 1.50588 41.363 0.978823C37.7865 0 23.4807 0 23.4807 0C23.4807 0 9.17479 0 5.59832 0.941176C3.67832 1.46824 2.09714 3.04941 1.57008 5.00706C0.628906 8.58353 0.628906 16 0.628906 16C0.628906 16 0.628906 23.4541 1.57008 26.9929C2.09714 28.9506 3.64067 30.4941 5.59832 31.0212C9.21243 32 23.4807 32 23.4807 32C23.4807 32 37.7865 32 41.363 31.0588C43.3207 30.5318 44.8642 28.9882 45.3913 27.0306C46.3324 23.4541 46.3324 16.0376 46.3324 16.0376C46.3324 16.0376 46.3701 8.58353 45.3913 5.00706Z"
        fill="#FF0000"
      />
      <path
        d="M18.9258 22.852L30.8223 16.0002L18.9258 9.14844V22.852Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3682_82974">
        <rect
          width="45.7412"
          height="32"
          fill="white"
          transform="translate(0.628906)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
