<template>
  <svg viewBox="0 0 340 133" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_577_2616)">
      <path
        d="M8.00195 66.3594V60.9685L84.9132 60.6514L84.9253 66.042L8.00195 66.3594Z"
        fill="url(#paint0_linear_577_2616)"
      />
      <path
        d="M8.00571 65.1391L8 62.0923L75.1058 61.8232L75.1117 64.8698L8.00571 65.1391Z"
        fill="url(#paint1_linear_577_2616)"
      />
      <path
        d="M75.3155 67.6057H75.9645C76.2985 67.6057 76.5846 67.3134 76.7225 66.8922C76.8193 67.4337 77.1456 67.8343 77.5374 67.8343H78.1865C78.5203 67.8343 78.8062 67.542 78.9443 67.1209C79.0412 67.6624 79.3674 68.0629 79.759 68.0629H80.4081C80.7421 68.0629 81.028 67.7706 81.1659 67.3498C81.2628 67.891 81.589 68.2916 81.9808 68.2916H82.6299C82.9637 68.2916 83.2498 67.999 83.3877 67.5781C83.4844 68.1196 83.8109 68.5202 84.2024 68.5202H84.8516C85.1855 68.5202 85.4715 68.2279 85.6093 67.807C85.7062 68.3482 86.0325 68.7488 86.4242 68.7488H87.0734C87.5409 68.7488 87.9202 68.1806 87.9202 67.4794V59.5116C87.9202 58.8107 87.5409 58.2422 87.0734 58.2422H86.4242C86.0325 58.2422 85.7062 58.643 85.6093 59.1843C85.4715 58.7631 85.1855 58.4708 84.8516 58.4708H84.2024C83.8109 58.4708 83.4844 58.8717 83.3877 59.4129C83.2498 58.992 82.9637 58.6994 82.6299 58.6994H81.9808C81.589 58.6994 81.2628 59.1003 81.1659 59.6413C81.028 59.2204 80.7421 58.9281 80.4081 58.9281H79.759C79.3674 58.9281 79.0412 59.3287 78.9443 59.8701C78.8062 59.4493 78.5203 59.1567 78.1865 59.1567H77.5374C77.1456 59.1567 76.8193 59.5573 76.7225 60.0985C76.5846 59.6776 76.2985 59.3856 75.9645 59.3856H75.3155C74.8478 59.3856 74.4688 59.9539 74.4688 60.6547V66.3363C74.4688 67.0374 74.8478 67.6057 75.3155 67.6057Z"
        fill="#F5FFF7"
      />
      <path
        d="M87.0734 66.8123H86.4242C86.0325 66.8123 85.7062 66.5596 85.6093 66.2176C85.4715 66.4834 85.1855 66.6679 84.8516 66.6679H84.2024C83.8109 66.6679 83.4844 66.4152 83.3877 66.0732C83.2498 66.339 82.9637 66.5238 82.6299 66.5238H81.9808C81.589 66.5238 81.2628 66.2708 81.1659 65.9288C81.028 66.1946 80.7421 66.3791 80.4081 66.3791H79.759C79.3674 66.3791 79.0412 66.1264 78.9443 65.7844C78.8062 66.0502 78.5203 66.2347 78.1865 66.2347H77.5374C77.1456 66.2347 76.8193 65.9818 76.7225 65.64C76.5846 65.9058 76.2985 66.0903 75.9645 66.0903H75.3155C74.8478 66.0903 74.4688 65.7317 74.4688 65.2887V61.7014C74.4688 61.2583 74.8478 60.8995 75.3155 60.8995H75.9645C76.2985 60.8995 76.5846 61.0842 76.7225 61.35C76.8193 61.0083 77.1456 60.7551 77.5374 60.7551H78.1865C78.5203 60.7551 78.8062 60.9398 78.9443 61.2056C79.0412 60.8639 79.3674 60.6109 79.759 60.6109H80.4081C80.7421 60.6109 81.028 60.7954 81.1659 61.0612C81.2628 60.7195 81.589 60.4665 81.9808 60.4665H82.6299C82.9637 60.4665 83.2498 60.6513 83.3877 60.9171C83.4844 60.5754 83.8109 60.3221 84.2024 60.3221H84.8516C85.1855 60.3221 85.4715 60.5069 85.6093 60.7727C85.7062 60.431 86.0325 60.1777 86.4242 60.1777H87.0734C87.5409 60.1777 87.9202 60.5366 87.9202 60.9792V66.0109C87.9202 66.4535 87.5409 66.8123 87.0734 66.8123Z"
        fill="#E7ECE4"
      />
      <path
        d="M76.5146 67.296C76.6003 67.1839 76.6713 67.0473 76.7221 66.8925C76.7507 67.0521 76.8011 67.1973 76.8655 67.3259V59.665C76.8011 59.7937 76.7507 59.9389 76.7221 60.0985C76.6713 59.9437 76.6003 59.807 76.5146 59.695V67.296Z"
        fill="#BFBFBF"
      />
      <path
        d="M78.7344 67.5245C78.8202 67.4127 78.8912 67.2755 78.942 67.1207C78.9706 67.2806 79.021 67.4258 79.0853 67.5544V59.4365C79.021 59.5649 78.9706 59.7101 78.942 59.87C78.8912 59.7152 78.8202 59.5783 78.7344 59.4662V67.5245Z"
        fill="#BFBFBF"
      />
      <path
        d="M83.1797 67.9813C83.2655 67.8695 83.3367 67.7323 83.3873 67.5775C83.4159 67.7377 83.4664 67.8826 83.5308 68.0112V58.9785C83.4664 59.1071 83.4159 59.2523 83.3873 59.4122C83.3367 59.2574 83.2655 59.1205 83.1797 59.0085V67.9813Z"
        fill="#BFBFBF"
      />
      <path
        d="M85.4492 68.1386C85.5129 68.0413 85.5673 67.9306 85.6078 67.807C85.6435 68.0063 85.7107 68.186 85.8003 68.3341V58.6572C85.7107 58.8054 85.6435 58.9848 85.6078 59.1843C85.5673 59.0607 85.5129 58.95 85.4492 58.8527V68.1386Z"
        fill="#BFBFBF"
      />
      <path
        d="M80.9561 67.7525C81.0417 67.6405 81.1129 67.5038 81.1635 67.349C81.1921 67.5086 81.2425 67.6538 81.3069 67.7825V59.207C81.2425 59.3357 81.1921 59.4809 81.1635 59.6405C81.1129 59.4859 81.0417 59.349 80.9561 59.2372V67.7525Z"
        fill="#BFBFBF"
      />
      <path
        d="M145.184 81.8417H129.158C128.295 81.8417 127.595 80.7924 127.595 79.4978C127.595 78.2036 128.295 77.1543 129.158 77.1543H145.184C146.048 77.1543 146.748 78.2036 146.748 79.4978C146.748 80.7924 146.048 81.8417 145.184 81.8417Z"
        fill="#F4D038"
      />
      <path
        d="M145.184 46.3145H129.158C128.295 46.3145 127.595 47.3635 127.595 48.658C127.595 49.9522 128.295 51.0018 129.158 51.0018H145.184C146.048 51.0018 146.748 49.9522 146.748 48.658C146.748 47.3635 146.048 46.3145 145.184 46.3145Z"
        fill="#F4D038"
      />
      <path
        d="M145.184 47.1055H129.158C128.295 47.1055 127.595 47.8002 127.595 48.6578C127.595 49.5151 128.295 50.2103 129.158 50.2103H145.184C146.048 50.2103 146.748 49.5151 146.748 48.6578C146.748 47.8002 146.048 47.1055 145.184 47.1055Z"
        fill="#FFF176"
      />
      <path
        d="M145.184 77.9453H129.158C128.295 77.9453 127.595 78.64 127.595 79.4976C127.595 80.3549 128.295 81.0502 129.158 81.0502H145.184C146.048 81.0502 146.748 80.3549 146.748 79.4976C146.748 78.64 146.048 77.9453 145.184 77.9453Z"
        fill="#FFF176"
      />
      <path
        d="M107.621 85.37H96.0219C91.612 85.37 88.0371 80.0112 88.0371 73.4007V54.7545C88.0371 48.144 91.612 42.7852 96.0219 42.7852H107.621V85.37Z"
        fill="#E1D8D9"
      />
      <path
        d="M107.621 81.8409H96.0219C91.612 81.8409 88.0371 76.4823 88.0371 69.8715V58.2835C88.0371 51.673 91.612 46.3145 96.0219 46.3145H107.621V81.8409Z"
        fill="#F0ECEB"
      />
      <path
        d="M112.015 93.3253H109.918C108.652 93.3253 107.625 91.7861 107.625 89.8878V38.2676C107.625 36.369 108.652 34.8301 109.918 34.8301H112.015C113.281 34.8301 114.308 36.369 114.308 38.2676V89.8878C114.308 91.7861 113.281 93.3253 112.015 93.3253Z"
        fill="#E1D8D9"
      />
      <path
        d="M112.015 89.8775H109.918C108.652 89.8775 107.625 88.3383 107.625 86.4397V41.7146C107.625 39.8163 108.652 38.2773 109.918 38.2773H112.015C113.281 38.2773 114.308 39.8163 114.308 41.7146V86.4397C114.308 88.3383 113.281 89.8775 112.015 89.8775Z"
        fill="#F0ECEB"
      />
      <path
        d="M129.732 86.0402C129.732 87.5395 128.921 88.7549 127.92 88.7549H114.305V39.4004H127.92C128.921 39.4004 129.732 40.616 129.732 42.1151V86.0402Z"
        fill="#E1D8D9"
      />
      <path
        d="M127.92 84.3984H114.305V43.7578H127.92C128.921 43.7578 129.732 44.9178 129.732 46.3487V81.8075C129.732 83.2384 128.921 84.3984 127.92 84.3984Z"
        fill="#F0ECEB"
      />
      <path
        d="M116.42 84.3984H114.305V88.7553H116.42V84.3984Z"
        fill="#BEBCB7"
      />
      <path
        d="M116.42 39.4004H114.305V43.7575H116.42V39.4004Z"
        fill="#CFDED0"
      />
      <path
        d="M116.42 43.7578H114.305V84.3984H116.42V43.7578Z"
        fill="#CACDC6"
      />
      <path
        d="M107.62 81.8408H106.682V85.3698H107.62V81.8408Z"
        fill="#BEBCB7"
      />
      <path
        d="M107.62 42.7852H106.682V46.3146H107.62V42.7852Z"
        fill="#CFDED0"
      />
      <path
        d="M107.62 46.3145H106.682V81.8409H107.62V46.3145Z"
        fill="#CACDC6"
      />
      <path
        d="M129.734 61.6289H116.422V66.5272H129.734V61.6289Z"
        fill="#D1B333"
      />
      <path
        d="M129.734 65.8125H116.422V66.4372H129.734V65.8125Z"
        fill="#F4D038"
      />
      <path
        d="M129.734 61.6289H116.422V62.1854H129.734V61.6289Z"
        fill="#FFF176"
      />
      <path
        d="M74.527 60.1777H73.6689V66.8123H74.527V60.1777Z"
        fill="#BFBFBF"
      />
      <path
        d="M88.0395 57.3516H87.1816V69.6399H88.0395V57.3516Z"
        fill="#BFBFBF"
      />
      <path
        d="M127.921 39.4004H127.035C128.035 39.4004 128.846 40.616 128.846 42.1151V86.0402C128.846 87.5398 128.035 88.7549 127.035 88.7549H127.921C128.921 88.7549 129.732 87.5398 129.732 86.0402V42.1151C129.732 40.616 128.921 39.4004 127.921 39.4004Z"
        fill="white"
      />
      <path
        d="M276.119 66.0185L276.101 60.6591L371.983 60.3438L371.999 65.7032L276.119 66.0185Z"
        fill="url(#paint2_linear_577_2616)"
      />
      <path
        d="M277.827 66.1538C277.827 66.8509 277.261 67.4159 276.564 67.4159H275.596C275.099 67.4159 274.671 67.1253 274.466 66.7065C274.323 67.2449 273.836 67.6432 273.251 67.6432H272.284C271.787 67.6432 271.361 67.3526 271.156 66.9339C271.01 67.4722 270.524 67.8705 269.941 67.8705H268.974C268.477 67.8705 268.051 67.5799 267.844 67.1614C267.7 67.6995 267.214 68.0978 266.629 68.0978H265.664C265.164 68.0978 264.739 67.8069 264.534 67.3885C264.388 67.9268 263.904 68.3251 263.319 68.3251H262.351C261.854 68.3251 261.429 68.0345 261.221 67.616C261.078 68.1542 260.591 68.5524 260.009 68.5524H259.041C258.342 68.5524 257.778 67.9875 257.778 67.2904V59.3685C257.778 58.6717 258.342 58.1064 259.041 58.1064H260.009C260.591 58.1064 261.078 58.505 261.221 59.0431C261.429 58.6243 261.854 58.3338 262.351 58.3338H263.319C263.904 58.3338 264.388 58.7323 264.534 59.2704C264.739 58.8519 265.164 58.5611 265.664 58.5611H266.629C267.214 58.5611 267.7 58.9596 267.844 59.4974C268.051 59.079 268.477 58.7884 268.974 58.7884H269.941C270.524 58.7884 271.01 59.1866 271.156 59.725C271.361 59.3065 271.787 59.0157 272.284 59.0157H273.251C273.836 59.0157 274.323 59.4139 274.466 59.9521C274.671 59.5336 275.099 59.2433 275.596 59.2433H276.564C277.261 59.2433 277.827 59.8082 277.827 60.5051V66.1538Z"
        fill="#F5FFF7"
      />
      <path
        d="M260.009 66.6275H259.041C258.342 66.6275 257.778 66.2707 257.778 65.8307V60.828C257.778 60.388 258.342 60.0312 259.041 60.0312H260.009C260.591 60.0312 261.078 60.283 261.221 60.6228C261.429 60.3585 261.854 60.1748 262.351 60.1748H263.319C263.904 60.1748 264.388 60.4266 264.534 60.7664C264.739 60.5021 265.164 60.3184 265.664 60.3184H266.629C267.214 60.3184 267.7 60.5699 267.844 60.9097C268.051 60.6454 268.477 60.4619 268.974 60.4619H269.941C270.524 60.4619 271.01 60.7134 271.156 61.0532C271.361 60.789 271.787 60.6052 272.284 60.6052H273.251C273.836 60.6052 274.323 60.857 274.466 61.1968C274.671 60.9325 275.099 60.7488 275.596 60.7488H276.564C277.261 60.7488 277.827 61.1056 277.827 61.5461V65.1127C277.827 65.5532 277.261 65.9097 276.564 65.9097H275.596C275.099 65.9097 274.671 65.7263 274.466 65.462C274.323 65.8018 273.836 66.0533 273.251 66.0533H272.284C271.787 66.0533 271.361 65.8698 271.156 65.6056C271.01 65.9456 270.524 66.1968 269.941 66.1968H268.974C268.477 66.1968 268.051 66.0134 267.844 65.7491C267.7 66.0892 267.214 66.3407 266.629 66.3407H265.664C265.164 66.3407 264.739 66.157 264.534 65.8927C264.388 66.2327 263.904 66.484 263.319 66.484H262.351C261.854 66.484 261.429 66.3005 261.221 66.0363C261.078 66.3763 260.591 66.6275 260.009 66.6275Z"
        fill="#E7ECE4"
      />
      <path
        d="M274.774 67.1084C274.646 66.997 274.543 66.8611 274.466 66.7072C274.423 66.8659 274.349 67.0103 274.253 67.1382V59.5215C274.349 59.6494 274.423 59.7937 274.466 59.9524C274.543 59.7985 274.646 59.6627 274.774 59.5513V67.1084Z"
        fill="#BFBFBF"
      />
      <path
        d="M271.461 67.3352C271.334 67.2241 271.227 67.0877 271.153 66.9337C271.11 67.0927 271.033 67.2371 270.938 67.365V59.2939C271.033 59.4216 271.11 59.5659 271.153 59.7249C271.227 59.571 271.334 59.4349 271.461 59.3235V67.3352Z"
        fill="#BFBFBF"
      />
      <path
        d="M264.843 67.7896C264.715 67.6785 264.609 67.5421 264.535 67.3882C264.492 67.5474 264.415 67.6915 264.319 67.8194V58.8389C264.415 58.9667 264.492 59.1111 264.535 59.2701C264.609 59.1162 264.715 58.98 264.843 58.8686V67.7896Z"
        fill="#BFBFBF"
      />
      <path
        d="M261.458 67.9462C261.363 67.8494 261.283 67.7393 261.222 67.6165C261.169 67.8146 261.07 67.9932 260.935 68.1405V58.5195C261.07 58.6668 261.169 58.8452 261.222 59.0435C261.283 58.9207 261.363 58.8106 261.458 58.7139V67.9462Z"
        fill="#BFBFBF"
      />
      <path
        d="M268.155 67.5626C268.024 67.4512 267.921 67.3153 267.844 67.1614C267.801 67.3201 267.727 67.4645 267.631 67.5923V59.0664C267.727 59.1943 267.801 59.3386 267.844 59.4974C267.921 59.3437 268.024 59.2076 268.155 59.0965V67.5626Z"
        fill="#BFBFBF"
      />
      <path
        d="M227.946 42.7383H245.233C251.807 42.7383 257.135 48.0661 257.135 54.6385V73.1773C257.135 79.7494 251.807 85.0773 245.233 85.0773H227.946V42.7383Z"
        fill="#E1D8D9"
      />
      <path
        d="M227.946 46.248H245.233C251.807 46.248 257.135 51.5759 257.135 58.148V69.6691C257.135 76.2418 251.807 81.5694 245.233 81.5694H227.946V46.248Z"
        fill="#F0ECEB"
      />
      <path
        d="M224.526 38.2578H221.401C219.514 38.2578 217.983 39.7878 217.983 41.6752V89.5699C217.983 91.4572 219.514 92.9876 221.401 92.9876H224.526C226.413 92.9876 227.943 91.4572 227.943 89.5699V41.6752C227.943 39.7878 226.413 38.2578 224.526 38.2578Z"
        fill="#E1D8D9"
      />
      <path
        d="M221.401 38.2578H224.526C226.413 38.2578 227.943 39.7878 227.943 41.6752V86.1421C227.943 88.0298 226.413 89.5601 224.526 89.5601H221.401C219.514 89.5601 217.983 88.0298 217.983 86.1421V41.6752C217.983 39.7878 219.514 38.2578 221.401 38.2578Z"
        fill="#F0ECEB"
      />
      <path
        d="M194.995 40.6303C194.995 39.041 196.204 37.7529 197.694 37.7529H217.986V90.0646H197.694C196.204 90.0646 194.995 88.7763 194.995 87.1872V40.6303Z"
        fill="#E1D8D9"
      />
      <path
        d="M197.694 44.7148H217.986V83.1013H197.694C196.204 83.1013 194.995 82.0057 194.995 80.654V47.1621C194.995 45.8105 196.204 44.7148 197.694 44.7148Z"
        fill="#F0ECEB"
      />
      <path
        d="M217.984 38.2473V37.7525H215.427V37.752H217.984V38.2473Z"
        fill="#504175"
      />
      <path
        d="M217.984 90.0643H215.427V83.1019H217.984V90.0645V90.0643ZM217.984 44.7155H215.427V44.6689H217.984V44.7155Z"
        fill="#BEBCB7"
      />
      <path
        d="M217.984 44.6686H215.427V37.752L217.984 37.7525V44.6686Z"
        fill="#CFDED0"
      />
      <path
        d="M217.984 44.7148H215.427V83.1013H217.984V44.7148Z"
        fill="#CACDC6"
      />
      <path
        d="M229.344 42.7383H227.946V85.0773H229.344V42.7383Z"
        fill="#D7DED7"
      />
      <path
        d="M235.172 73.1309H249.91C250.346 73.1309 250.697 73.4831 250.697 73.9173V74.0925C250.697 74.5266 250.346 74.8786 249.91 74.8786H235.172C234.738 74.8786 234.386 74.5266 234.386 74.0925V73.9173C234.386 73.4831 234.738 73.1309 235.172 73.1309Z"
        fill="#A5A4A2"
      />
      <path
        d="M235.172 69.0762H249.91C250.346 69.0762 250.697 69.4282 250.697 69.8626V70.0373C250.697 70.4717 250.346 70.8237 249.91 70.8237H235.172C234.738 70.8237 234.386 70.4717 234.386 70.0373V69.8626C234.386 69.4282 234.738 69.0762 235.172 69.0762Z"
        fill="#A5A4A2"
      />
      <path
        d="M235.172 65.0225H249.91C250.346 65.0225 250.697 65.3745 250.697 65.8089V65.9836C250.697 66.418 250.346 66.77 249.91 66.77H235.172C234.738 66.77 234.386 66.418 234.386 65.9836V65.8089C234.386 65.3745 234.738 65.0225 235.172 65.0225Z"
        fill="#A5A4A2"
      />
      <path
        d="M235.172 60.9678H249.91C250.346 60.9678 250.697 61.3198 250.697 61.7542V61.9291C250.697 62.3633 250.346 62.7155 249.91 62.7155H235.172C234.738 62.7155 234.386 62.3633 234.386 61.9291V61.7542C234.386 61.3198 234.738 60.9678 235.172 60.9678Z"
        fill="#A5A4A2"
      />
      <path
        d="M235.172 56.9131H249.91C250.346 56.9131 250.697 57.2651 250.697 57.6995V57.8742C250.697 58.3086 250.346 58.6606 249.91 58.6606H235.172C234.738 58.6606 234.386 58.3086 234.386 57.8742V57.6995C234.386 57.2651 234.738 56.9131 235.172 56.9131Z"
        fill="#A5A4A2"
      />
      <path
        d="M235.172 52.8584H249.91C250.346 52.8584 250.697 53.2104 250.697 53.6448V53.8195C250.697 54.2539 250.346 54.6059 249.91 54.6059H235.172C234.738 54.6059 234.386 54.2539 234.386 53.8195V53.6448C234.386 53.2104 234.738 52.8584 235.172 52.8584Z"
        fill="#A5A4A2"
      />
      <path
        d="M249.91 65.0225H235.172C234.738 65.0225 234.386 65.3745 234.386 65.8089V65.9835L234.392 66.0516C234.427 65.6496 234.761 65.3332 235.172 65.3332H249.91C250.323 65.3332 250.655 65.6496 250.689 66.0516L250.697 65.9835V65.8089C250.697 65.3745 250.344 65.0225 249.91 65.0225Z"
        fill="#F5FFF7"
      />
      <path
        d="M249.91 73.1309H235.172C234.738 73.1309 234.386 73.4829 234.386 73.9173V74.0925L234.392 74.1603C234.427 73.758 234.761 73.4417 235.172 73.4417H249.91C250.323 73.4417 250.655 73.758 250.689 74.1603L250.697 74.0925V73.9173C250.697 73.4829 250.344 73.1309 249.91 73.1309Z"
        fill="#F5FFF7"
      />
      <path
        d="M249.91 69.0762H235.172C234.738 69.0762 234.386 69.4282 234.386 69.8626V70.0373L234.392 70.1053C234.427 69.7031 234.761 69.3867 235.172 69.3867H249.91C250.323 69.3867 250.655 69.7031 250.689 70.1053L250.697 70.0373V69.8626C250.697 69.4282 250.344 69.0762 249.91 69.0762Z"
        fill="#F5FFF7"
      />
      <path
        d="M249.91 60.9678H235.172C234.738 60.9678 234.386 61.3198 234.386 61.7542V61.9291L234.392 61.9969C234.427 61.595 234.761 61.2786 235.172 61.2786H249.91C250.323 61.2786 250.655 61.595 250.689 61.9969L250.697 61.9291V61.7542C250.697 61.3198 250.344 60.9678 249.91 60.9678Z"
        fill="#F5FFF7"
      />
      <path
        d="M249.91 52.8584H235.172C234.738 52.8584 234.386 53.2104 234.386 53.6448V53.8195L234.392 53.8875C234.427 53.4856 234.761 53.1692 235.172 53.1692H249.91C250.323 53.1692 250.655 53.4856 250.689 53.8875L250.697 53.8195V53.6448C250.697 53.2104 250.344 52.8584 249.91 52.8584Z"
        fill="#F5FFF7"
      />
      <path
        d="M249.91 56.9131H235.172C234.738 56.9131 234.386 57.2651 234.386 57.6995V57.8742L234.392 57.9422C234.427 57.54 234.761 57.2236 235.172 57.2236H249.91C250.323 57.2236 250.655 57.54 250.689 57.9422L250.697 57.8742V57.6995C250.697 57.2651 250.344 56.9131 249.91 56.9131Z"
        fill="#F5FFF7"
      />
      <path
        d="M279.101 60.0312H277.825V66.6275H279.101V60.0312Z"
        fill="#BFBFBF"
      />
      <path
        d="M258.41 57.2207H257.134V69.4381H258.41V57.2207Z"
        fill="#BFBFBF"
      />
      <path
        d="M203.342 92.3433C203.342 92.8867 202.901 93.3276 202.358 93.3276C201.814 93.3276 201.374 92.8867 201.374 92.3433C201.374 91.7996 201.814 91.3594 202.358 91.3594C202.901 91.3594 203.342 91.7996 203.342 92.3433Z"
        fill="#71C9FF"
      />
      <path
        d="M197.707 32.098C197.823 31.6171 197.958 31.1463 198.11 30.6827C198.259 30.2186 198.414 29.757 198.585 29.3036C198.922 28.3935 199.291 27.5007 199.685 26.6198L200.289 27.5954C199.678 27.622 199.066 27.6361 198.454 27.6393L197.296 27.6454L197.812 26.5987C198.427 25.3506 199.083 24.1237 199.803 22.9282C200.162 22.33 200.535 21.7388 200.93 21.1585C201.333 20.5823 201.73 20.0035 202.192 19.457C202.031 20.1543 201.806 20.8195 201.586 21.4872C201.357 22.151 201.108 22.8037 200.843 23.4493C200.318 24.7421 199.728 26.0025 199.097 27.2425L198.454 26.2018C199.066 26.2053 199.678 26.2191 200.289 26.246L201.405 26.2951L200.893 27.222C200.427 28.0668 199.935 28.8992 199.411 29.7152C199.151 30.1247 198.876 30.5262 198.595 30.9248C198.316 31.3245 198.021 31.7162 197.707 32.098Z"
        fill="#085642"
      />
      <path
        d="M170.809 32.6475C170.824 31.463 170.876 30.2842 170.941 29.1075C171.01 27.9311 171.09 26.7564 171.19 25.5849C171.385 23.2408 171.631 20.9039 171.915 18.5727L173.144 19.6067C172.443 19.8592 171.739 20.1018 171.035 20.3438C170.332 20.5896 169.625 20.8239 168.919 21.0598L167.38 21.5728L167.609 19.9562C167.848 18.2796 168.111 16.6066 168.387 14.9355C168.668 13.2649 168.962 11.5966 169.287 9.93233C169.608 8.26837 169.952 6.60702 170.329 4.95057C170.707 3.29499 171.106 1.64172 171.58 0C171.559 1.70901 171.462 3.40648 171.345 5.10134C171.226 6.79562 171.073 8.48499 170.898 10.1712C170.727 11.8577 170.525 13.5401 170.31 15.22C170.09 16.8992 169.858 18.577 169.6 20.2508L168.291 19.1478C168.999 18.9184 169.707 18.6882 170.419 18.4693C171.13 18.2463 171.841 18.0245 172.555 17.8122L174.036 17.3718L173.784 18.8462C173.388 21.1609 172.954 23.4701 172.469 25.7718C172.229 26.9234 171.969 28.0715 171.698 29.2182C171.423 30.3645 171.134 31.5086 170.809 32.6475Z"
        fill="#085642"
      />
      <path
        d="M183.823 38.5666C184.042 37.1984 184.297 35.84 184.565 34.4851C184.837 33.1314 185.121 31.7808 185.423 30.4357C186.022 27.7438 186.672 25.0658 187.358 22.3984L188.352 23.593C187.864 23.658 187.376 23.7126 186.887 23.7666C186.398 23.8244 185.909 23.8703 185.419 23.9174L183.808 24.0725L184.394 22.5466C184.794 21.5025 185.218 20.4676 185.654 19.4376C186.095 18.4091 186.548 17.3858 187.03 16.3737C187.508 15.3602 188.008 14.3551 188.538 13.3621C189.072 12.3702 189.623 11.3856 190.246 10.4287C190.049 11.5534 189.781 12.6498 189.495 13.7384C189.206 14.8267 188.886 15.9026 188.546 16.9704C188.208 18.0394 187.842 19.0971 187.463 20.1496C187.08 21.2007 186.685 22.2468 186.266 23.284L185.24 21.9132C185.73 21.8725 186.22 21.8309 186.711 21.8008C187.201 21.7673 187.692 21.7344 188.184 21.7119L189.557 21.6481L189.178 22.9065C188.382 25.5438 187.55 28.1704 186.668 30.7835C186.229 32.0904 185.771 33.3925 185.302 34.6911C184.829 35.9885 184.344 37.2828 183.823 38.5666Z"
        fill="#085642"
      />
      <path
        d="M150.328 33.2916C150.014 32.9095 149.719 32.5179 149.44 32.1181C149.159 31.7198 148.883 31.3184 148.624 30.9088C148.1 30.0928 147.608 29.2601 147.142 28.4153L146.631 27.4887L147.746 27.4396C148.358 27.4128 148.969 27.3986 149.581 27.3955L148.938 28.4361C148.307 27.1962 147.717 25.9357 147.192 24.6426C146.927 23.9974 146.678 23.3443 146.449 22.6806C146.229 22.0131 146.004 21.3479 145.843 20.6504C146.305 21.1969 146.703 21.7757 147.106 22.3519C147.5 22.9322 147.873 23.5234 148.232 24.1219C148.952 25.317 149.609 26.5443 150.223 27.792L150.739 28.839L149.581 28.833C148.969 28.8295 148.358 28.8153 147.746 28.7888L148.35 27.8131C148.744 28.6938 149.114 29.5871 149.45 30.4972C149.621 30.9504 149.776 31.4119 149.925 31.8764C150.076 32.3394 150.212 32.8105 150.328 33.2916Z"
        fill="#085642"
      />
      <path
        d="M159.328 31.3126C158.9 30.648 158.506 29.9699 158.124 29.2871C157.746 28.6028 157.379 27.914 157.03 27.2182C156.328 25.8286 155.673 24.42 155.055 22.9969L154.466 21.6397L156.019 21.7119C156.511 21.7344 157.001 21.7673 157.492 21.8009C157.983 21.8309 158.473 21.8725 158.963 21.9132L157.937 23.284C157.518 22.2468 157.123 21.2007 156.74 20.1496C156.361 19.0971 155.995 18.0394 155.657 16.9704C155.316 15.9026 154.997 14.8267 154.708 13.7384C154.421 12.6498 154.154 11.5534 153.957 10.4287C154.58 11.3856 155.131 12.3702 155.664 13.3621C156.195 14.3551 156.694 15.3602 157.173 16.3737C157.655 17.3858 158.108 18.4091 158.549 19.4376C158.984 20.4676 159.409 21.5025 159.809 22.5466L160.395 24.0725L158.783 23.9174C158.294 23.8703 157.804 23.8244 157.316 23.7666C156.827 23.7126 156.338 23.658 155.851 23.5931L156.814 22.3078C157.327 23.7716 157.804 25.2501 158.233 26.7468C158.45 27.4943 158.649 28.2487 158.836 29.0081C159.02 29.7686 159.191 30.534 159.328 31.3126Z"
        fill="#085642"
      />
      <path
        d="M144.295 96.5127C144.148 97.0545 143.982 97.5866 143.8 98.1105C143.621 98.6359 143.436 99.1581 143.234 99.6725C142.837 100.704 142.406 101.719 141.952 102.721L141.348 101.745C142.046 101.719 142.744 101.705 143.441 101.701L144.601 101.696L144.084 102.742C143.381 104.165 142.637 105.568 141.829 106.938C141.427 107.624 141.01 108.303 140.571 108.971C140.124 109.635 139.683 110.301 139.177 110.936C139.382 110.151 139.651 109.398 139.915 108.642C140.187 107.891 140.481 107.151 140.789 106.417C141.402 104.949 142.08 103.513 142.799 102.098L143.441 103.139C142.744 103.136 142.046 103.121 141.348 103.095L140.236 103.052L140.745 102.119C141.271 101.152 141.823 100.198 142.408 99.2603C142.698 98.7901 143.004 98.3277 143.315 97.8685C143.625 97.4084 143.95 96.9555 144.295 96.5127Z"
        fill="#085642"
      />
      <path
        d="M168.512 95.8691C168.471 97.2165 168.4 98.5596 168.305 99.8995C168.219 101.24 168.103 102.577 167.984 103.913C167.873 105.25 167.716 106.581 167.584 107.915C167.443 109.248 167.286 110.579 167.119 111.908L165.889 110.874C166.69 110.589 167.494 110.313 168.298 110.038C169.101 109.76 169.907 109.493 170.713 109.224L172.253 108.711L172.022 110.328C171.749 112.241 171.45 114.15 171.14 116.057C170.822 117.963 170.498 119.869 170.131 121.768L169.583 124.616L168.988 127.458C168.565 129.349 168.148 131.24 167.632 133.117C167.682 131.171 167.831 129.24 167.973 127.308L168.227 124.416L168.528 121.53C168.726 119.606 168.969 117.689 169.217 115.772C169.472 113.857 169.739 111.943 170.031 110.033L171.341 111.136C170.533 111.398 169.725 111.661 168.913 111.913C168.103 112.168 167.293 112.423 166.479 112.668L165.003 113.113L165.25 111.634C165.471 110.313 165.701 108.993 165.948 107.676C166.204 106.36 166.435 105.04 166.712 103.727C166.981 102.413 167.253 101.099 167.555 99.7894C167.848 98.479 168.164 97.1715 168.512 95.8691Z"
        fill="#085642"
      />
      <path
        d="M160.172 94.3477C159.895 95.4836 159.584 96.6055 159.26 97.7232C158.933 98.839 158.594 99.9504 158.238 101.055C157.529 103.266 156.772 105.458 155.98 107.635L155.016 106.35C155.573 106.279 156.131 106.218 156.688 106.158C157.246 106.094 157.804 106.042 158.363 105.989L160.158 105.817L159.351 107.445C158.76 108.636 158.146 109.816 157.522 110.991C156.892 112.163 156.25 113.329 155.582 114.481C154.916 115.635 154.23 116.779 153.515 117.907C152.797 119.035 152.061 120.153 151.257 121.237C151.652 119.947 152.116 118.691 152.597 117.444C153.081 116.198 153.594 114.967 154.128 113.747C154.658 112.525 155.216 111.316 155.786 110.114C156.36 108.914 156.946 107.72 157.554 106.537L158.542 107.993C157.983 108.04 157.424 108.088 156.864 108.123C156.305 108.164 155.745 108.203 155.184 108.231L153.647 108.311L154.221 106.946C155.119 104.81 156.053 102.687 157.035 100.584C157.523 99.5307 158.03 98.4852 158.548 97.4439C159.069 96.4041 159.603 95.369 160.172 94.3477Z"
        fill="#085642"
      />
      <path
        d="M197.2 96.5127C197.544 96.9555 197.87 97.4084 198.179 97.8685C198.49 98.3277 198.796 98.7901 199.086 99.2603C199.671 100.198 200.223 101.152 200.75 102.119L201.259 103.052L200.146 103.095C199.448 103.121 198.75 103.136 198.053 103.139L198.695 102.098C199.414 103.513 200.091 104.949 200.705 106.417C201.013 107.151 201.307 107.891 201.58 108.642C201.843 109.398 202.112 110.151 202.317 110.936C201.811 110.301 201.37 109.635 200.923 108.971C200.485 108.303 200.068 107.624 199.665 106.938C198.857 105.568 198.113 104.165 197.41 102.742L196.894 101.696L198.053 101.701C198.75 101.705 199.448 101.719 200.146 101.745L199.542 102.721C199.088 101.719 198.657 100.704 198.26 99.6725C198.058 99.1581 197.873 98.6359 197.694 98.1105C197.512 97.5866 197.346 97.0545 197.2 96.5127Z"
        fill="#085642"
      />
      <path
        d="M180.213 88.792C180.873 89.7434 181.505 90.7084 182.115 91.6849C182.734 92.6571 183.325 93.6432 183.914 94.6304C184.51 95.6145 185.065 96.619 185.642 97.6123C186.211 98.6099 186.766 99.6145 187.311 100.624L188.615 103.037L186.07 101.909C185.105 101.481 184.144 101.044 183.184 100.607C182.222 100.173 181.264 99.7283 180.307 99.2846L181.669 98.0043C182.512 100.12 183.332 102.245 184.14 104.374C184.942 106.506 185.738 108.64 186.493 110.791L187.627 114.015L188.719 117.257C189.421 119.428 190.129 121.597 190.744 123.802C189.691 121.769 188.73 119.699 187.764 117.633L186.353 114.517L184.985 111.384C184.071 109.297 183.198 107.193 182.331 105.086C181.471 102.978 180.622 100.864 179.797 98.7416L178.882 96.3882L181.159 97.4613C182.114 97.911 183.069 98.3601 184.019 98.8191C184.971 99.2751 185.923 99.732 186.87 100.198L185.629 101.483C185.131 100.449 184.643 99.4097 184.169 98.3639C183.704 97.3134 183.216 96.2747 182.769 95.215C182.315 94.1585 181.864 93.1011 181.44 92.0298C181.007 90.9626 180.596 89.8844 180.213 88.792Z"
        fill="#085642"
      />
      <path
        d="M152.73 11.7846C152.73 12.3022 152.311 12.7219 151.793 12.7219C151.276 12.7219 150.856 12.3022 150.856 11.7846C150.856 11.2673 151.276 10.8477 151.793 10.8477C152.311 10.8477 152.73 11.2673 152.73 11.7846Z"
        fill="#085642"
      />
      <path
        d="M183.308 76.6362C183.308 77.1535 182.888 77.5732 182.371 77.5732C181.853 77.5732 181.434 77.1535 181.434 76.6362C181.434 76.1186 181.853 75.6992 182.371 75.6992C182.888 75.6992 183.308 76.1186 183.308 76.6362Z"
        fill="#085642"
      />
      <path
        d="M139.325 25.7782C139.325 26.0416 139.111 26.2551 138.847 26.2551C138.584 26.2551 138.37 26.0416 138.37 25.7782C138.37 25.5145 138.584 25.3008 138.847 25.3008C139.111 25.3008 139.325 25.5145 139.325 25.7782Z"
        fill="#085642"
      />
      <path
        d="M180.197 28.9241C180.197 29.6603 179.6 30.2573 178.864 30.2573C178.127 30.2573 177.53 29.6603 177.53 28.9241C177.53 28.1875 178.127 27.5908 178.864 27.5908C179.6 27.5908 180.197 28.1875 180.197 28.9241Z"
        fill="#085642"
      />
      <path
        d="M147.437 98.5952C147.437 99.4354 146.756 100.116 145.916 100.116C145.076 100.116 144.395 99.4354 144.395 98.5952C144.395 97.7553 145.076 97.0742 145.916 97.0742C146.756 97.0742 147.437 97.7553 147.437 98.5952Z"
        fill="#085642"
      />
      <path
        d="M196.984 14.9902C196.984 15.3272 196.71 15.6005 196.373 15.6005C196.036 15.6005 195.763 15.3272 195.763 14.9902C195.763 14.6531 196.036 14.3799 196.373 14.3799C196.71 14.3799 196.984 14.6531 196.984 14.9902Z"
        fill="#085642"
      />
      <path
        d="M177.915 125.797C177.915 126.182 177.604 126.493 177.219 126.493C176.834 126.493 176.522 126.182 176.522 125.797C176.522 125.412 176.834 125.101 177.219 125.101C177.604 125.101 177.915 125.412 177.915 125.797Z"
        fill="#085642"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_577_2616"
        x1="73.8426"
        y1="63.8011"
        x2="7.99938"
        y2="63.8011"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EFEFEF" />
        <stop offset="1" stop-color="#EFEFEF" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_577_2616"
        x1="73.1848"
        y1="63.8011"
        x2="8"
        y2="63.8011"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EFEFEF" />
        <stop offset="0.906734" stop-color="#EFEFEF" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_577_2616"
        x1="279.933"
        y1="63.8002"
        x2="363.554"
        y2="63.8002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EFEFEF" />
        <stop offset="1" stop-color="#EFEFEF" stop-opacity="0" />
      </linearGradient>
      <clipPath id="clip0_577_2616">
        <rect width="340" height="133" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
