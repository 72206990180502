import Moralis from 'moralis';

const config: Moralis.StartOptions = {
  serverUrl: process.env.VUE_APP_MORALIS_SERVER_URL,
  appId: process.env.VUE_APP_MORALIS_APPID,
};
Moralis.start(config);

const MoralisConfig = Moralis;

type NetworkConfigs = Parameters<typeof Moralis['addNetwork']>;

export const networks: Record<string, NetworkConfigs> = {
  testnet: [
    97,
    'Smart Chain - Testnet',
    'Binance Coin',
    'BNB',
    'https://data-seed-prebsc-1-s1.binance.org:8545',
    'https://explorer.binance.org/smart-testnet',
  ],
  mainnet: [
    56,
    'Smart Chain',
    'Binance Coin',
    'BNB',
    'https://bsc-dataseed.binance.org/',
    'https://bscscan.com',
  ],
};
export default MoralisConfig;
