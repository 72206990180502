<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
  >
    <path
      d="M20.6289 40C31.6746 40 40.6289 31.0457 40.6289 20C40.6289 8.95431 31.6746 0 20.6289 0C9.58321 0 0.628906 8.95431 0.628906 20C0.628906 31.0457 9.58321 40 20.6289 40Z"
      fill="#1DA1F2"
    />
    <path
      d="M16.9677 30.5492C25.8377 30.5492 30.6877 23.1992 30.6877 16.8292C30.6877 16.6192 30.6877 16.4092 30.6777 16.2092C31.6177 15.5292 32.4377 14.6792 33.0877 13.7092C32.2277 14.0892 31.2977 14.3492 30.3177 14.4692C31.3177 13.8692 32.0777 12.9292 32.4377 11.7992C31.5077 12.3492 30.4777 12.7492 29.3777 12.9692C28.4977 12.0292 27.2477 11.4492 25.8577 11.4492C23.1977 11.4492 21.0377 13.6092 21.0377 16.2692C21.0377 16.6492 21.0777 17.0192 21.1677 17.3692C17.1577 17.1692 13.6077 15.2492 11.2277 12.3292C10.8177 13.0392 10.5777 13.8692 10.5777 14.7492C10.5777 16.4192 11.4277 17.8992 12.7277 18.7592C11.9377 18.7392 11.1977 18.5192 10.5477 18.1592C10.5477 18.1792 10.5477 18.1992 10.5477 18.2192C10.5477 20.5592 12.2077 22.4992 14.4177 22.9492C14.0177 23.0592 13.5877 23.1192 13.1477 23.1192C12.8377 23.1192 12.5377 23.0892 12.2377 23.0292C12.8477 24.9492 14.6277 26.3392 16.7377 26.3792C15.0877 27.6692 13.0077 28.4392 10.7477 28.4392C10.3577 28.4392 9.97766 28.4192 9.59766 28.3692C11.7077 29.7492 14.2477 30.5492 16.9677 30.5492Z"
      fill="white"
    />
  </svg>
</template>
