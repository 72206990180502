import { getCryptoPrices, getPrices } from '@/apis';
import { USDT_ADDRESS } from '@/constants';
import { CryptoPrice, Price } from '@/types/Price';
import { defineStore } from 'pinia';
import USDTToken from '@/abis/USDTToken.json';
import { DonateRequestType } from '@/types/Donate';
import { roundNumber } from '@/utils/numbers';

interface State {
  items: Price[];
  cryptoPrices: Price[];
  cryptoEnables: Record<string, boolean>;
}

const getDefaultcryptoConfigs = (): CryptoPrice[] => [
  {
    name: 'BNB',
    description: 'Binance Coin',
    pricePerContract: 0,
    abi: {},
    tokenAddress: '',
    symbol: 'BNB',
    imageURL: '',
  },
  {
    name: 'USDT',
    description: 'Tether USD',
    decimal: 2,
    symbol: 'USDT',
    pricePerContract: 0,
    abi: USDTToken,
    tokenAddress: USDT_ADDRESS,
    imageURL: '',
  },
];

export const usePricesStore = defineStore('prices', {
  state: (): State => ({
    items: [
      {
        symbol: 'USD',
        description: '',
        imageURL: '',
        name: '',
        pricePerContract: 0,
      },
    ],
    cryptoEnables: { BNB: true, USDT: true },
    cryptoPrices: [],
  }),
  getters: {
    cryptos: ({ cryptoPrices, cryptoEnables }): CryptoPrice[] => {
      return cryptoPrices
        .filter((e) => cryptoEnables[e.name])
        .map(({ pricePerContract, ...item }, i) => {
          const cryptos = getDefaultcryptoConfigs();
          const matchedCrypto = cryptos.find((c) => c.name === item.name);
          if (!matchedCrypto) return cryptos[i]; // returning this is for type checking only
          // because api does not return abi & token address
          // so we will need it merged with static value
          const cryptoPrice = {
            ...matchedCrypto,
            ...item,
            pricePerContract: roundNumber(pricePerContract / 10 ** 18),
          };
          return cryptoPrice;
        });
    },
  },
  actions: {
    async getPrices(type: DonateRequestType) {
      const { data } = await getPrices(type);
      this.items = [...data];
    },
    async getCryptoPrices() {
      this.cryptoPrices = await getCryptoPrices().then((s) => s.data);
    },
  },
});
