import { ref } from 'vue';

interface Configs {
  interval: number;
  callback: () => void;
}

export default function useTimer(configs: Configs) {
  const timer = ref<NodeJS.Timer>();
  const start = () => {
    timer.value = setInterval(configs.callback, configs.interval);
  };
  const stop = () => {
    timer.value && clearInterval(timer.value);
  };
  return {
    start,
    stop,
  };
}
