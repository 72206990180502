<template>
  <svg viewBox="0 0 1440 332" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M-22.4388 311.479L-3.38062 290.034C15.6776 268.59 53.794 225.701 94.8167 219.761C135.839 213.82 179.768 244.829 221.621 249.446C263.474 254.063 303.251 232.288 342.443 203.067C381.634 173.846 420.24 137.179 461.348 132.321C502.456 127.462 546.065 154.413 587.742 156.783C629.418 159.153 669.161 136.943 710.355 133.188C751.55 129.432 794.196 144.13 835.524 142.081C876.853 140.031 916.864 121.233 958.626 124.69C1000.39 128.146 1043.9 153.857 1087.26 177.659C1130.62 201.46 1173.84 223.352 1213.22 196.606C1252.61 169.86 1288.17 94.4756 1330.22 101.641C1372.28 108.807 1420.82 198.522 1465.37 237.393C1509.92 276.264 1550.46 264.289 1587.62 209.234C1624.78 154.18 1658.55 56.0446 1697.47 23.3639C1736.39 -9.31668 1780.46 23.4572 1822.52 30.6855C1864.58 37.9139 1904.63 19.5966 1924.65 10.4379L1944.68 1.27927"
      stroke="#bbb"
    />
    <path
      d="M-21.7276 331.012L-2.66944 309.568C16.3888 288.123 54.5052 245.234 95.5278 239.294C136.55 233.354 180.479 264.363 222.332 268.98C264.185 273.597 303.963 251.822 343.154 222.601C382.346 193.38 420.951 156.713 462.059 151.854C503.167 146.996 546.777 173.946 588.453 176.317C630.129 178.687 669.872 156.477 711.066 152.721C752.261 148.966 794.907 163.664 836.236 161.614C877.564 159.565 917.576 140.767 959.337 144.223C1001.1 147.68 1044.61 173.391 1087.97 197.192C1131.34 220.994 1174.55 242.885 1213.93 216.139C1253.32 189.393 1288.88 114.009 1330.93 121.175C1372.99 128.341 1421.53 218.056 1466.08 256.927C1510.63 295.797 1551.18 283.823 1588.34 228.768C1625.49 173.713 1659.27 75.5783 1698.19 42.8976C1737.1 10.217 1781.17 42.9909 1823.23 50.2192C1865.29 57.4475 1905.34 39.1303 1925.36 29.9716L1945.39 20.813"
      stroke="#bbb"
    />
  </svg>
</template>
