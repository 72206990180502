<template>
  <div class="my-12">
    <div class="flex gap-x-2 cursor-pointer items-center" v-if="getTotal > 1">
      <div
        v-for="index in getTotal"
        :key="index"
        class="border py-1 rounded-md"
      >
        <span
          :class="{ 'bg-primary text-white': index == pageSelected }"
          class="px-3 py-2 rounded"
          @click="pageSelected = index"
        >
          {{ index }}
        </span>
      </div>
      <div class="border p-1 rounded-md">
        <IconChevronRight
          @click="pageSelected < getTotal ? (pageSelected += 1) : ''"
        />
      </div>

      <p class="text-gray-description md:block hidden">
        Page {{ pageSelected }} of {{ getTotal }}
      </p>
    </div>
    <p class="text-gray-description block md:hidden mt-2">
      Page {{ pageSelected }} of {{ getTotal }}
    </p>
  </div>
</template>

<script>
import IconChevronRight from '@/components/Icons/IconChevronRight.vue';
export default {
  components: {
    IconChevronRight,
  },
  props: {
    list: {
      type: Number,
      default: () => 10,
    },
    dataPerPage: {
      type: Number,
      default: () => 2,
    },
  },

  data() {
    return {
      //   pageTotal: 0,
      pageSelected: 1,
      member: [],
      workspceShow: 10,
      spaceUserSelected: [],
    };
  },
  watch: {
    pageSelected(val) {
      this.$emit('pageCurrent', val);
    },
  },
  mounted() {
    this.$emit('perPage', this.dataPerPage);
    this.$emit('pageCurrent', 1);
  },
  computed: {
    getTotal() {
      const pageTotal = Math.ceil(this.list / this.dataPerPage);
      return pageTotal;
    },
  },
};
</script>
