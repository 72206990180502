import { ref } from 'vue';
import { POSITION, useToast } from 'vue-toastification';
import { ToastID } from 'vue-toastification/dist/types/types';

export default function useCopyToClipboard() {
  const toast = useToast();
  const toastID = ref<ToastID>();

  const copyToClipboard = (text?: string) => {
    if (!text) return;
    navigator.clipboard.writeText(text);
    const toastOptions: Parameters<typeof toast.success>[1] = {
      position: POSITION.TOP_CENTER,
    };
    // When assign 'id' directly with the initial value of undefined into toastOptions
    // The noti will not be able to be shown
    if (toastID.value) toastOptions.id = toastID.value;
    toastID.value = toast.success('Copied', toastOptions);
  };
  return {
    toastID,
    copyToClipboard,
  };
}
