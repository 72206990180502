<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
  >
    <path
      d="M48.3175 42.2206C48.3175 42.2206 65.474 55.24 91.5562 30.896C114.72 9.27672 133.633 43.3871 133.766 59.722C133.937 80.882 110.602 97.8133 121.926 111.712C133.251 125.61 99.4684 148.56 81.2614 128.698C58.6124 103.99 52.4766 124.065 39.5668 124.065C30.3013 124.065 11.2783 101.043 24.1243 83.9151C34.9341 69.5021 29.0387 64.7188 26.1837 59.722C22.0654 52.5155 31.8456 32.9551 48.3175 42.2206Z"
      fill="#E6E6E6"
    />
    <path
      d="M117.196 70.9914C118.118 71.3341 119.126 71.3569 120.084 71.1368C124.102 70.2147 137.915 67.7733 135.514 78.4771C134.992 80.6008 126.902 92.494 138.423 96.8355C139.267 97.1577 140.16 97.3335 141.063 97.3555C144.61 97.4286 151.64 96.5911 154.582 93.5963C158.135 89.9786 154.253 91.6943 154.253 91.6943C154.253 91.6943 140.742 97.5903 135.969 91.335C135.438 90.639 135.1 89.6281 135.003 88.7579C134.835 87.2491 135.505 85.2552 136.385 83.5195C137.577 81.1704 143.295 70.6031 130.879 67.5247C128.019 66.8436 123.865 66.2318 118.509 68.487C115.384 69.8023 116.076 70.5755 117.196 70.9914Z"
      fill="#2A9D80"
    />
    <path
      d="M119.96 67.0465L116.364 68.3924C115.123 68.857 114.493 70.24 114.957 71.4814L115.136 71.9577C115.6 73.199 116.983 73.8288 118.225 73.3641L121.821 72.0182C123.062 71.5536 123.692 70.1707 123.227 68.9293L123.049 68.453C122.584 67.2116 121.201 66.5819 119.96 67.0465Z"
      fill="#064C29"
    />
    <path
      d="M97.8861 70.941L86.0262 75.3797C85.4055 75.612 85.0907 76.3035 85.323 76.9242L85.3697 77.049C85.602 77.6697 86.2935 77.9845 86.9142 77.7522L98.7741 73.3135C99.3948 73.0812 99.7096 72.3897 99.4773 71.769L99.4306 71.6442C99.1983 71.0235 98.5068 70.7087 97.8861 70.941Z"
      fill="white"
    />
    <path
      d="M101.197 79.7849L89.3368 84.2236C88.7161 84.456 88.4012 85.1474 88.6335 85.7681L88.6802 85.893C88.9125 86.5137 89.604 86.8285 90.2247 86.5962L102.085 82.1575C102.705 81.9252 103.02 81.2337 102.788 80.613L102.741 80.4881C102.509 79.8674 101.817 79.5526 101.197 79.7849Z"
      fill="white"
    />
    <path
      d="M112.146 64.9055L102.534 68.503C101.292 68.9676 100.663 70.3506 101.127 71.592L104.409 80.3606C104.874 81.602 106.257 82.2317 107.498 81.7671L117.11 78.1696C118.352 77.705 118.981 76.322 118.517 75.0806L115.235 66.312C114.77 65.0706 113.388 64.4409 112.146 64.9055Z"
      fill="#0E6D55"
    />
    <path
      d="M103.527 67.2059L93.9147 70.8034C92.6733 71.268 92.0436 72.651 92.5082 73.8924L96.4235 84.3537C96.8881 85.5951 98.2711 86.2248 99.5125 85.7602L109.125 82.1627C110.366 81.6981 110.996 80.3151 110.531 79.0737L106.616 68.6124C106.151 67.371 104.768 66.7413 103.527 67.2059Z"
      fill="#21886E"
    />
    <path
      d="M105.032 65.0829L104.956 65.1111C104.129 65.4208 103.709 66.3428 104.019 67.1704L109.504 81.8256C109.813 82.6532 110.735 83.073 111.563 82.7633L111.638 82.735C112.466 82.4253 112.886 81.5033 112.576 80.6757L107.091 66.0205C106.781 65.1929 105.859 64.7731 105.032 65.0829Z"
      fill="#064C29"
    />
    <path
      d="M7.01826 74.4528C7.01826 74.4528 26.3229 69.0277 23.5202 81.5266C23.0335 83.5043 16.0271 93.6874 24.3727 98.5766C26.1647 99.6265 28.2327 100.092 30.3095 100.081C34.0329 100.061 39.9357 99.3446 42.5875 96.6455C46.1414 93.0278 42.2588 94.7434 42.2588 94.7434C42.2588 94.7434 31.1719 99.3039 25.468 95.6686C23.5204 94.4273 22.6376 92.0239 23.1665 89.7757C23.4368 88.6589 23.8485 87.5812 24.3915 86.5686C25.6319 84.2453 31.3012 73.6522 18.8852 70.5738C16.0251 69.8927 11.8709 69.2809 6.5145 71.5361C1.1581 73.7913 7.01826 74.4528 7.01826 74.4528Z"
      fill="#2A9D80"
    />
    <path
      d="M45.1837 91.9465L41.5876 93.2924C40.3462 93.757 39.7165 95.14 40.1811 96.3814L40.3593 96.8577C40.824 98.0991 42.2069 98.7288 43.4483 98.2642L47.0445 96.9183C48.2859 96.4537 48.9156 95.0707 48.451 93.8293L48.2727 93.353C47.8081 92.1116 46.4251 91.4819 45.1837 91.9465Z"
      fill="#064C29"
    />
    <path
      d="M51.259 86.0298L45.9294 88.0245C44.688 88.4891 44.0583 89.8721 44.5229 91.1135L47.0928 97.9802C47.5574 99.2215 48.9404 99.8513 50.1818 99.3867L55.5115 97.3919C56.7529 96.9273 57.3826 95.5444 56.918 94.303L54.348 87.4363C53.8834 86.1949 52.5004 85.5652 51.259 86.0298Z"
      fill="#0E6D55"
    />
    <path
      d="M60.1323 80.7431L52.1843 83.7177C50.9429 84.1823 50.3132 85.5653 50.7778 86.8067L54.693 97.2681C55.1577 98.5095 56.5406 99.1392 57.782 98.6746L65.7301 95.6999C66.9715 95.2353 67.6012 93.8523 67.1366 92.6109L63.2213 82.1496C62.7567 80.9082 61.3737 80.2785 60.1323 80.7431Z"
      fill="#21886E"
    />
    <path
      d="M61.8151 78.5766L61.7397 78.6048C60.9121 78.9145 60.4923 79.8365 60.802 80.6641L66.2869 95.3193C66.5967 96.1469 67.5187 96.5667 68.3463 96.2569L68.4216 96.2287C69.2492 95.919 69.669 94.997 69.3593 94.1694L63.8744 79.5142C63.5647 78.6866 62.6427 78.2668 61.8151 78.5766Z"
      fill="#064C29"
    />
    <path
      d="M82.1114 69.24C81.8252 69.2082 81.5624 69.0667 81.3785 68.8451C81.1945 68.6235 81.1037 68.3392 81.1252 68.052L81.7182 57.22C81.7536 56.573 82.3 56.0814 82.92 56.1386L83.784 56.2186C84.4041 56.2758 84.848 56.8586 84.7613 57.501L83.304 68.2531C83.2705 68.5395 83.1278 68.8019 82.9057 68.9857C82.6835 69.1696 82.399 69.2607 82.1114 69.24Z"
      fill="#064C29"
    />
    <path
      d="M76.9902 70.174C76.9012 70.2001 76.8079 70.2085 76.7156 70.1986C76.6234 70.1887 76.534 70.1607 76.4525 70.1163C76.3711 70.0718 76.2992 70.0117 76.241 69.9395C76.1828 69.8672 76.1393 69.7842 76.1132 69.6952L73.9954 62.9571C73.9338 62.7655 73.9508 62.5573 74.0425 62.3781C74.1343 62.199 74.2934 62.0635 74.4849 62.0015L75.0332 61.8361C75.1273 61.8086 75.226 61.801 75.3232 61.8136C75.4204 61.8262 75.5139 61.8589 75.5978 61.9095C75.6818 61.96 75.7543 62.0275 75.8109 62.1075C75.8674 62.1876 75.9067 62.2785 75.9264 62.3745L77.496 69.2781C77.5397 69.4636 77.512 69.6589 77.4183 69.8249C77.3245 69.9909 77.1717 70.1156 76.9902 70.174Z"
      fill="#064C29"
    />
    <path
      d="M74.133 74.0596C73.7535 74.5298 72.9904 74.5242 72.403 74.0469L61.9734 65.5732C61.3504 65.067 61.1666 64.2292 61.5691 63.7308L62.1302 63.0358C62.5327 62.5371 63.3595 62.578 63.949 63.1257L73.8176 72.2945C74.3734 72.8109 74.5124 73.5895 74.133 74.0596Z"
      fill="#064C29"
    />
    <path
      d="M78.9537 95.9464C79.2223 96.0502 79.4402 96.2542 79.5615 96.5154C79.6828 96.7766 79.698 97.0747 79.6039 97.3469L76.2641 107.668C76.0646 108.284 75.4109 108.62 74.8259 108.407L74.0106 108.109C73.4257 107.895 73.145 107.218 73.3933 106.619L77.5482 96.596C77.6537 96.3275 77.8587 96.1101 78.1206 95.989C78.3824 95.868 78.6809 95.8527 78.9537 95.9464Z"
      fill="#064C29"
    />
    <path
      d="M84.1432 96.3512C84.236 96.3486 84.3283 96.3644 84.415 96.3975C84.5017 96.4306 84.581 96.4805 84.6484 96.5443C84.7158 96.6081 84.7699 96.6845 84.8078 96.7692C84.8457 96.854 84.8665 96.9453 84.869 97.0381L85.1956 104.094C85.2063 104.295 85.1367 104.492 85.0023 104.641C84.8678 104.791 84.6794 104.882 84.4784 104.893L83.9059 104.912C83.808 104.915 83.7105 104.897 83.6198 104.86C83.5291 104.823 83.447 104.768 83.3787 104.697C83.3105 104.627 83.2576 104.543 83.2234 104.451C83.1891 104.36 83.1743 104.262 83.1798 104.164L83.4257 97.0883C83.4308 96.8978 83.5074 96.7161 83.6404 96.5795C83.7734 96.4429 83.9529 96.3614 84.1432 96.3512Z"
      fill="#064C29"
    />
    <path
      d="M87.8986 93.3242C88.3857 92.9665 89.122 93.1669 89.5681 93.7783L97.4877 104.634C97.9608 105.283 97.9246 106.14 97.4077 106.519L96.6877 107.048C96.1712 107.427 95.3823 107.176 94.9522 106.496L87.7522 95.1109C87.3474 94.4701 87.4117 93.6818 87.8986 93.3242Z"
      fill="#064C29"
    />
  </svg>
</template>
