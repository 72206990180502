import { MoralisKey } from '@/symbols/moralis';
import Moralis from 'moralis';
import { inject } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/store/user';

export default function useMoralis() {
  const currect = inject(MoralisKey, Moralis);
  const router = useRouter();

  const handleMoralisError = (err: Moralis.Error) => {
    const userStore = useUserStore();
    switch (err.code) {
      case Moralis.Error.INVALID_SESSION_TOKEN:
        userStore.logout();
        router.push('/landing');
        break;
    }
  };

  return {
    currect,
    handleMoralisError,
  };
}
