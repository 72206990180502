export const toDDMMYYYY = (date?: Date | string, joint = '/'): string => {
  if (!date) return '';
  const d = new Date(date);
  const dd = d.getDate();
  const mm = d.getMonth() + 1;
  const yyyy = d.getFullYear();
  return [dd, mm, yyyy].join(joint);
};

export const displayGroupTitle = (date: string) => {
  const d = new Date(date);
  const dL = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const mL = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return `${dL[d.getDay()]} ${d.getDate()} ${
    mL[d.getMonth()]
  } ${d.getFullYear()}`;
};
