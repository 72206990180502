import { computed, onMounted, onUnmounted, ref } from 'vue';
import useTimer from './useTimer';

const MILLI_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;
const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24;
const SECONDS_IN_YEAR = SECONDS_IN_DAY * 365;

interface Config {
  seconds: number;
  immediate?: boolean;
}

const isConfig = (config: Config | number): config is Config =>
  (config as Config).seconds !== undefined;

export default function useCountdown(config: Config | number) {
  const timer = useTimer({
    interval: MILLI_IN_SECOND,
    callback: () => {
      if (seconds.value <= 0) {
        timer.stop();
      }
      seconds.value -= Math.min(1, seconds.value);
    },
  });
  const seconds = ref(isConfig(config) ? config.seconds : config);

  const stop = () => {
    timer.stop();
    seconds.value = isConfig(config) ? config.seconds : config;
  };
  const reset = () => {
    stop();
    timer.start();
  };

  onMounted(() => {
    if ((isConfig(config) && config.immediate) || !isConfig(config)) {
      timer.start();
    }
  });
  onUnmounted(() => stop());

  const countdown = computed(() => {
    let leftover = seconds.value;
    const [years, days, hours, minutes, sec] = [
      SECONDS_IN_YEAR,
      SECONDS_IN_DAY,
      SECONDS_IN_HOUR,
      SECONDS_IN_MINUTE,
      1,
    ].map((value) => {
      const initialValue = leftover;
      leftover %= value;
      return Math.floor(initialValue / value);
    });
    const time = computed(() => {
      return [minutes, sec].map((e) => e.toString().padStart(2, '0')).join(':');
    });
    return {
      years,
      days,
      hours,
      minutes,
      seconds: sec,
      time: time.value,
    };
  });

  return {
    seconds,
    countdown,
    reset,
    stop,
    start: timer.start,
  };
}
