import { Aggregator } from '@/types/Aggregator';
import { USDTToken } from '@/types/USDTToken';
import { Token } from '@/types/Token';
import { InjectionKey } from 'vue';
import { Contract } from 'web3-eth-contract';

export interface SmartContracts {
  nft?: Contract;
  token?: Contract;
  aggregator?: Aggregator;
  carbon?: Token;
  bio?: Token;
  usdt?: USDTToken;
}

export const SmartContactKey: InjectionKey<SmartContracts> =
  Symbol('smart-contract');
