<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M9.09 16.59L13.67 12L9.09 7.41L10.5 6L16.5 12L10.5 18L9.09 16.59Z"
      fill="currentColor"
    />
  </svg>
</template>
