// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCS3ocCs3hxFM4pWb_oaXPoTX6OG_zzhNU',
  authDomain: 'bring-back-the-forest.firebaseapp.com',
  projectId: 'bring-back-the-forest',
  storageBucket: 'bring-back-the-forest.appspot.com',
  messagingSenderId: '1002980127697',
  appId: '1:1002980127697:web:fbc2002e34199cadf426f0',
  measurementId: 'G-2GQXN09XR4',
};

// Initialize Firebase
initializeApp(firebaseConfig);
const analytics = getAnalytics();

// logAnalytics
logEvent(analytics, 'screen_view');
