interface PriceResponse {
  symbol: string;
  price: string;
}

export const getBNBtoUSDT = async (): Promise<number> => {
  const response = await fetch(
    'https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT',
    {
      method: 'GET',
    }
  );
  const data = (await response.json()) as PriceResponse;
  return parseFloat(data.price);
};
