<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="176"
    height="208"
    viewBox="0 0 176 208"
    fill="none"
  >
    <g clip-path="url(#clip0_2633_13766)">
      <path
        d="M69.0997 170.718C65.6267 167.137 60.594 165.083 55.2918 165.083L55.0873 165.083C51.5949 165.085 48.1649 164.158 45.1479 162.399L44.834 162.216L45.0868 161.956C46.4557 160.539 47.5988 158.921 48.4759 157.157C47.853 157.738 47.11 158.175 46.2995 158.437C45.489 158.699 44.6309 158.779 43.7859 158.672C42.8938 158.566 42.0374 158.259 41.2808 157.775C40.5243 157.29 39.8873 156.64 39.4176 155.874C37.5645 152.868 35.3555 148.601 35.3555 145.351C35.3584 142.604 35.9336 139.887 37.0445 137.375C38.1553 134.862 39.7775 132.609 41.8076 130.758C43.0928 129.599 44.1194 128.182 44.8204 126.6C45.5213 125.018 45.881 123.306 45.8758 121.575L45.8748 121.329C45.8745 119.05 46.1529 116.781 46.7039 114.57L46.7598 114.347L46.9895 114.355C48.4707 114.405 49.9526 114.281 51.4047 113.985C50.0426 113.818 48.7101 113.463 47.445 112.932L47.1979 112.832L47.2818 112.578C53.0623 108.999 66.1107 110.853 73.6843 93.5248C79.8255 79.4741 101.494 105.998 101.494 121.329C101.494 122.893 99.9456 124.96 101.105 125.994C113.633 137.172 104.989 144.349 102.263 151.571C101.616 153.285 103.019 155.166 103.019 157.02C103.019 157.334 103.011 157.659 102.995 157.985L102.971 158.486L102.553 158.208C101.726 157.666 100.96 157.038 100.268 156.334C100.986 157.987 101.325 159.78 101.259 161.582C101.193 163.384 100.723 165.147 99.8854 166.744C98.5725 169.222 97.3022 170.996 96.1101 172.016C92.2911 175.284 87.368 176.97 82.3473 176.729C77.3266 176.488 72.5878 174.338 69.0997 170.718H69.0997Z"
        fill="#E6E6E6"
      />
      <path
        d="M90.0755 208C90.1609 208 90.2433 207.969 90.3078 207.913C90.3722 207.857 90.4143 207.78 90.4264 207.695C90.4414 207.589 91.9181 196.939 90.5919 182.784C89.3671 169.712 85.4338 150.884 73.651 135.42C73.6228 135.383 73.5876 135.352 73.5473 135.329C73.507 135.305 73.4624 135.29 73.4162 135.283C73.37 135.277 73.323 135.28 73.2779 135.292C73.2329 135.304 73.1905 135.325 73.1534 135.353C73.1163 135.381 73.0852 135.416 73.0618 135.457C73.0383 135.497 73.0231 135.542 73.0168 135.588C73.0106 135.634 73.0136 135.681 73.0255 135.726C73.0375 135.771 73.0582 135.814 73.0865 135.851C84.7675 151.181 88.6691 169.871 89.8853 182.851C91.2038 196.923 89.7387 207.49 89.7237 207.595C89.7166 207.645 89.7204 207.697 89.7348 207.745C89.7492 207.794 89.7739 207.839 89.8073 207.878C89.8406 207.916 89.8818 207.947 89.9281 207.968C89.9744 207.989 90.0247 208 90.0755 208V208Z"
        fill="#3F3D56"
      />
      <path
        d="M80.4599 157.232C80.5349 157.232 80.608 157.209 80.6686 157.165C80.7293 157.121 80.7745 157.059 80.7977 156.987C80.8209 156.916 80.8209 156.839 80.7978 156.768C80.7746 156.697 80.7295 156.635 80.6689 156.59C77.3115 154.318 73.6846 152.472 69.8716 151.094C63.9145 148.913 54.8559 146.936 45.5302 149.904C45.4857 149.918 45.4444 149.941 45.4087 149.971C45.373 150.001 45.3435 150.038 45.3221 150.079C45.3006 150.12 45.2875 150.166 45.2835 150.212C45.2795 150.259 45.2848 150.306 45.2989 150.35C45.3131 150.394 45.3359 150.436 45.366 150.471C45.3961 150.507 45.4329 150.536 45.4744 150.558C45.5159 150.579 45.5612 150.592 45.6077 150.596C45.6542 150.6 45.701 150.594 45.7454 150.58C54.88 147.673 63.7744 149.618 69.6275 151.761C73.3792 153.114 76.948 154.93 80.2516 157.165C80.3121 157.209 80.3851 157.232 80.4599 157.232Z"
        fill="#3F3D56"
      />
      <path
        d="M96.7013 116.781C96.7067 116.707 96.6884 116.632 96.6488 116.568C96.6093 116.505 96.5506 116.455 96.4812 116.427C96.4118 116.399 96.3352 116.393 96.2624 116.411C96.1896 116.429 96.1244 116.469 96.0761 116.527C93.5676 119.712 91.4649 123.196 89.8164 126.9C87.2121 132.685 84.5877 141.577 86.8765 151.093C86.8873 151.138 86.907 151.181 86.9344 151.218C86.9618 151.256 86.9963 151.288 87.0361 151.313C87.0759 151.337 87.12 151.353 87.1661 151.361C87.2122 151.368 87.2592 151.366 87.3045 151.355C87.3499 151.344 87.3926 151.324 87.4303 151.297C87.4681 151.27 87.5 151.235 87.5243 151.195C87.5486 151.155 87.5649 151.111 87.5721 151.065C87.5793 151.019 87.5774 150.972 87.5664 150.927C85.3248 141.606 87.9047 132.875 90.4636 127.192C92.0842 123.547 94.1517 120.118 96.6187 116.984C96.667 116.927 96.6959 116.856 96.7013 116.781Z"
        fill="#3F3D56"
      />
      <path
        d="M165.148 207.624H136.899L136.778 207.404C136.416 206.749 136.068 206.055 135.742 205.342C132.832 199.112 131.602 191.987 130.517 185.701L129.7 180.963C129.601 180.389 129.675 179.799 129.914 179.267C130.152 178.736 130.544 178.288 131.039 177.981C131.533 177.673 132.108 177.521 132.69 177.542C133.272 177.563 133.834 177.758 134.305 178.101C138.598 181.225 142.893 184.346 147.189 187.463C148.816 188.647 150.674 190.017 152.453 191.498C152.625 190.664 152.805 189.827 152.984 189.003C153.108 188.433 153.399 187.914 153.82 187.511C154.24 187.108 154.772 186.84 155.346 186.74C155.92 186.641 156.511 186.715 157.043 186.954C157.574 187.192 158.023 187.584 158.33 188.078L161.636 193.389C164.046 197.267 166.174 201.088 165.74 205.209C165.743 205.26 165.739 205.31 165.729 205.359C165.643 206.036 165.482 206.701 165.25 207.343L165.148 207.624Z"
        fill="#F0F0F0"
      />
      <path
        d="M151.987 96.5661L146.536 76.3871C146.302 75.5329 145.742 74.8047 144.976 74.3597C144.211 73.9147 143.301 73.7886 142.443 74.0086C141.999 74.1224 141.583 74.3254 141.221 74.6052C140.858 74.885 140.556 75.2357 140.333 75.636C140.11 76.0364 139.971 76.4778 139.925 76.9336C139.878 77.3894 139.925 77.8498 140.062 78.2869L146.368 99.0744L149.667 111.867C149.186 112.234 148.831 112.74 148.648 113.316C148.465 113.892 148.463 114.511 148.644 115.088C148.825 115.665 149.179 116.172 149.658 116.541C150.137 116.909 150.719 117.121 151.322 117.148C151.926 117.175 152.524 117.016 153.034 116.691C153.544 116.367 153.942 115.893 154.173 115.335C154.405 114.776 154.459 114.16 154.328 113.57C154.197 112.979 153.888 112.444 153.442 112.036L151.987 96.5661Z"
        fill="#A0616A"
      />
      <path
        d="M106.111 92.22L122.676 79.4717C123.376 78.9295 123.835 78.1338 123.954 77.2562C124.072 76.3786 123.841 75.4895 123.31 74.7808C123.035 74.4142 122.689 74.1074 122.292 73.8791C121.895 73.6507 121.455 73.5057 121 73.4528C120.545 73.3998 120.084 73.4401 119.645 73.5712C119.206 73.7023 118.798 73.9214 118.447 74.2152L101.646 87.9851L91.0841 95.9218C90.5615 95.6179 89.958 95.4823 89.3556 95.5334C88.7532 95.5845 88.1811 95.8198 87.7171 96.2073C87.2531 96.5949 86.9196 97.1158 86.762 97.6995C86.6044 98.2831 86.6302 98.9012 86.8361 99.4696C87.0419 100.038 87.4178 100.529 87.9126 100.877C88.4074 101.224 88.9971 101.411 89.6017 101.411C90.2062 101.412 90.7963 101.226 91.2917 100.88C91.7871 100.533 92.1639 100.043 92.3708 99.4746L106.111 92.22Z"
        fill="#A0616A"
      />
      <path
        d="M119.612 95.1506L118.882 111.458L141.808 106.475C139.857 99.9225 138.53 98.8048 139.996 94.2446L119.612 95.1506Z"
        fill="#A0616A"
      />
      <path
        d="M133.708 65.2546H126.069L122.789 70.8684L121.225 70.7821C120.461 70.7399 119.696 70.8541 118.977 71.1176C118.258 71.3811 117.6 71.7885 117.044 72.3148C116.488 72.8411 116.045 73.4753 115.742 74.1786C115.439 74.8819 115.283 75.6396 115.283 76.4053L117.209 79.3905C117.209 79.3905 113.765 85.3936 118.727 88.3469C118.727 88.3469 117.842 93.9112 118.727 93.8514C119.611 93.7916 121.65 92.6591 119.611 93.7916C117.573 94.9241 117.725 95.0761 117.989 95.3398L118.253 95.6035L139.687 99.2026C139.687 99.2026 140.314 95.0474 140.381 94.3063C140.449 93.5651 139.996 95.377 140.449 93.5651C140.902 91.7532 141.581 92.4326 140.902 91.7532C140.222 91.0737 145.304 82.6558 143.216 79.3905L146.564 76.1253C146.564 72.9931 141.533 70.454 138.401 70.454H138L133.708 65.2546Z"
        fill="#E6E6E6"
      />
      <path
        d="M115.303 201.337H112.211L110.74 189.411L115.304 189.412L115.303 201.337Z"
        fill="#A0616A"
      />
      <path
        d="M105.971 205.9C105.972 206.246 106.111 206.578 106.355 206.823C106.6 207.068 106.931 207.207 107.278 207.209H113.098L113.279 206.835L114.102 205.136L114.421 206.835L114.491 207.209H116.687L116.655 206.833L116.07 199.813L115.304 199.765L111.995 199.569L111.182 199.52V201.616C110.566 202.272 105.725 204.752 105.971 205.9Z"
        fill="#2F2E41"
      />
      <path
        d="M161.255 193.239L158.654 194.912L150.965 185.678L154.803 183.209L161.255 193.239Z"
        fill="#A0616A"
      />
      <path
        d="M155.875 202.125C156.063 202.415 156.359 202.619 156.697 202.693C157.036 202.767 157.39 202.704 157.682 202.519L162.577 199.37L162.527 198.958L162.3 197.084L163.487 198.34L163.749 198.616L165.595 197.428L165.365 197.129L161.074 191.542L160.405 191.916L157.516 193.542L156.806 193.94L157.94 195.702C157.776 196.588 155.047 201.293 155.875 202.125Z"
        fill="#2F2E41"
      />
      <path
        d="M175.678 207.842L79.9368 207.935C79.8413 207.935 79.7498 207.897 79.6824 207.829C79.615 207.761 79.5771 207.669 79.5771 207.574C79.5771 207.478 79.615 207.387 79.6824 207.319C79.7498 207.251 79.8413 207.213 79.9368 207.212L175.678 207.119C175.773 207.12 175.865 207.158 175.932 207.226C176 207.293 176.037 207.385 176.037 207.481C176.037 207.576 176 207.668 175.932 207.736C175.865 207.803 175.773 207.842 175.678 207.842Z"
        fill="#CACACA"
      />
      <path
        d="M141.448 104.633C141.448 104.633 126.793 112.385 119.042 107.096C119.042 107.096 117.347 115.308 116.667 115.988C115.988 116.667 114.905 116.963 115.673 117.721C116.441 118.479 116.868 119.503 115.409 120.803C113.949 122.103 112.494 121.691 113.901 123.029C115.308 124.368 109.646 157.209 109.646 157.209C109.646 157.209 110.552 187.75 109.646 188.674C108.74 189.597 107.529 189.804 108.701 190.947C109.872 192.089 109.872 191.203 109.872 192.778V194.354L110.408 195.92H117.762C117.762 195.92 119.537 193.858 117.762 192.067C115.988 190.277 118.302 189.787 118.844 188.107C119.385 186.427 120.291 188.239 119.385 186.427C118.479 184.615 118.044 184.959 118.035 183.768C118.026 182.576 131.389 135.919 131.389 135.919L133.008 161.743L152.476 190.025L158.206 185.727C158.206 185.727 154.034 179.854 155.009 179.047C155.985 178.239 152.134 177.227 153.348 176.316C154.563 175.405 151.639 174.487 152.741 173.28C153.843 172.072 151.223 169.029 149.705 169.029C148.187 169.029 146.057 163.957 146.057 163.957L149.237 123.885L141.448 104.633Z"
        fill="#2F2E41"
      />
      <path
        d="M125.916 48.7711C125.812 48.9072 124.986 48.8339 123.992 48.6888C122.028 48.4019 121.76 48.7428 120.291 50.0788C118.164 52.0139 119.373 63.8173 118.328 66.3748L117.99 66.6236C118.66 66.4957 119.318 66.3116 119.957 66.0732C120.318 64.9343 120.68 63.7917 121.044 62.6537C121.388 63.5391 121.605 64.469 121.687 65.4153C122.192 65.216 122.695 64.7284 124.808 64.0745C125.872 63.3527 133.571 62.6269 134.56 62.3093C137.072 53.7635 133.024 50.3076 125.916 48.7711Z"
        fill="#2F2E41"
      />
      <path
        d="M128.515 61.949C132.578 61.949 135.871 58.6554 135.871 54.5926C135.871 50.5297 132.578 47.2361 128.515 47.2361C124.452 47.2361 121.158 50.5297 121.158 54.5926C121.158 58.6554 124.452 61.949 128.515 61.949Z"
        fill="#A0616A"
      />
      <path
        d="M133.146 48.1227C132.732 48.0616 132.333 47.9217 131.971 47.7106C131.791 47.6022 131.646 47.4455 131.551 47.2584C131.456 47.0714 131.416 46.8615 131.435 46.6527C131.526 46.13 132.117 45.8179 132.646 45.8497C133.174 45.9257 133.675 46.1284 134.106 46.4405C134.059 45.9353 133.843 45.4608 133.493 45.093C133.144 44.7253 132.681 44.4857 132.179 44.4128C132.875 44.3753 133.599 44.3422 134.243 44.61C134.887 44.8779 135.424 45.5411 135.308 46.2289C136.391 46.0894 137.458 46.8438 137.905 47.8398C138.352 48.8358 138.264 50.0054 137.907 51.037C137.871 51.195 137.803 51.3435 137.705 51.4726C137.607 51.6017 137.483 51.7083 137.341 51.7851C136.976 51.9234 136.584 51.6915 136.268 51.4629C134.619 50.2688 133.118 48.8824 131.797 47.3331"
        fill="#2F2E41"
      />
      <path
        d="M136.354 62.4135C136.042 62.5603 136.204 63.1066 135.962 63.3512C135.857 63.4548 135.73 63.5337 135.59 63.5822C135.554 63.1263 135.448 62.6787 135.276 62.255C135.244 62.178 135.192 62.0919 135.11 62.0987C135.015 62.11 134.99 62.2346 134.988 62.3274C134.969 62.7881 134.867 63.2415 134.687 63.666C134.685 63.6706 134.682 63.6773 134.68 63.6819C134.61 63.6796 134.537 63.6773 134.469 63.6751C133.944 63.6547 132.78 62.3048 132.522 62.7623C133.432 61.1475 134.542 58.7194 134.712 56.8735C134.771 56.4871 134.742 56.0923 134.628 55.7184C134.568 55.5324 134.457 55.3674 134.306 55.2429C134.156 55.1185 133.972 55.0398 133.779 55.0163C133.683 55.012 133.587 55.0288 133.499 55.0656C133.411 55.1024 133.332 55.1583 133.267 55.2293C133.203 55.3003 133.155 55.3846 133.127 55.4762C133.099 55.5678 133.092 55.6645 133.106 55.7592L132.975 55.7411C131.756 54.7491 132.807 53.0255 131.389 52.3437C130.909 52.1127 127.969 52.885 127.45 52.7491C127.439 52.2028 127.328 51.6631 127.122 51.1569C127.09 51.0799 127.038 50.9938 126.957 51.0006C126.862 51.012 126.837 51.1365 126.834 51.2294C126.815 51.69 126.713 52.1435 126.533 52.568C125.815 52.466 125.083 52.5045 124.379 52.6812C123.759 52.8443 123.17 53.1251 122.547 53.2746C122.239 53.369 121.915 53.3995 121.595 53.3645C121.275 53.3294 120.965 53.2295 120.685 53.0708C119.895 52.5408 119.757 51.3676 120.151 50.5023C120.436 49.9472 120.821 49.4496 121.288 49.0347C121.643 48.6904 122.012 48.3597 122.382 48.0291C122.712 47.7324 123.043 47.4357 123.374 47.1389C123.79 46.7472 124.233 46.3854 124.701 46.0563C125.447 45.5671 126.286 45.2356 127.165 45.0822C128.044 44.9287 128.945 44.9565 129.813 45.1639C130.855 45.4131 132.247 45.062 133.201 45.549C133.699 45.8049 134.802 46.7856 135.239 47.1344C136.358 48.0245 135.601 47.2992 136.825 47.8139C139.743 49.041 138.453 61.4255 136.354 62.4135Z"
        fill="#2F2E41"
      />
      <path
        d="M54.9013 116.108C80.0976 116.108 100.523 95.6821 100.523 70.4857C100.523 45.2894 80.0976 24.8638 54.9013 24.8638C29.705 24.8638 9.2793 45.2894 9.2793 70.4857C9.2793 95.6821 29.705 116.108 54.9013 116.108Z"
        fill="white"
      />
      <path
        d="M54.9016 116.563C29.4945 116.563 8.82422 95.8926 8.82422 70.4855C8.82422 45.0785 29.4945 24.4082 54.9016 24.4082C80.3087 24.4082 100.979 45.0785 100.979 70.4855C100.979 95.8926 80.3087 116.563 54.9016 116.563ZM54.9016 25.319C29.9967 25.319 9.735 45.5807 9.735 70.4855C9.735 95.3904 29.9967 115.652 54.9016 115.652C79.8064 115.652 100.068 95.3904 100.068 70.4855C100.068 45.5807 79.8064 25.319 54.9016 25.319Z"
        fill="#064C29"
      />
      <path
        d="M48.6779 94.2299C47.8901 94.2299 47.1131 94.0464 46.4084 93.694C45.7038 93.3416 45.091 92.8299 44.6184 92.1995L32.1721 75.6041C31.7722 75.071 31.4813 74.4643 31.316 73.8187C31.1506 73.1731 31.114 72.5013 31.2083 71.8416C31.3025 71.1819 31.5258 70.5472 31.8654 69.9737C32.2049 69.4003 32.6541 68.8994 33.1872 68.4996C33.7204 68.0997 34.3271 67.8088 34.9727 67.6434C35.6182 67.4781 36.2901 67.4415 36.9498 67.5357C37.6095 67.63 38.2442 67.8533 38.8176 68.1928C39.391 68.5324 39.892 68.9816 40.2918 69.5147L48.4346 80.371L69.3485 49.0007C70.0955 47.8817 71.2562 47.1051 72.5756 46.8417C73.8949 46.5782 75.2649 46.8495 76.3844 47.5958C77.5038 48.3421 78.2811 49.5023 78.5454 50.8215C78.8096 52.1407 78.5392 53.5109 77.7936 54.6308L52.9009 91.9699C52.4506 92.6452 51.8449 93.2027 51.1347 93.5956C50.4245 93.9886 49.6305 94.2055 48.8191 94.2282C48.772 94.229 48.725 94.2299 48.6779 94.2299Z"
        fill="#064C29"
      />
      <path
        d="M101.905 27.7141C102.032 26.8419 101.118 25.9869 99.8646 25.8045C98.6108 25.6221 97.4915 26.1813 97.3646 27.0535C97.2376 27.9257 98.1512 28.7807 99.405 28.9631C100.659 29.1456 101.778 28.5864 101.905 27.7141Z"
        fill="#E6E6E6"
      />
      <path
        d="M116.834 26.2576C116.961 25.3854 116.047 24.5304 114.793 24.348C113.539 24.1656 112.42 24.7247 112.293 25.597C112.166 26.4692 113.08 27.3242 114.334 27.5066C115.588 27.689 116.707 27.1298 116.834 26.2576Z"
        fill="#3F3D56"
      />
      <path
        d="M97.8172 15.6287C97.9441 14.7564 97.0305 13.9015 95.7767 13.7191C94.5229 13.5366 93.4036 14.0958 93.2767 14.968C93.1498 15.8403 94.0633 16.6952 95.3171 16.8777C96.571 17.0601 97.6903 16.5009 97.8172 15.6287Z"
        fill="#FF6584"
      />
      <path
        d="M91.3309 10.6782C91.337 9.87743 90.8903 9.22489 90.3333 9.22067C89.7762 9.21646 89.3198 9.86218 89.3137 10.6629C89.3076 11.4637 89.7543 12.1162 90.3113 12.1204C90.8684 12.1246 91.3248 11.4789 91.3309 10.6782Z"
        fill="#E6E6E6"
      />
      <path
        d="M89.1307 1.45747C89.1368 0.65673 88.6901 0.0041839 88.1331 -2.82906e-05C87.576 -0.00424048 87.1196 0.641476 87.1135 1.44222C87.1074 2.24296 87.5541 2.8955 88.1111 2.89972C88.6682 2.90393 89.1247 2.25821 89.1307 1.45747Z"
        fill="#3F3D56"
      />
      <path
        d="M106.237 17.6853C106.243 16.8845 105.797 16.232 105.24 16.2278C104.682 16.2235 104.226 16.8693 104.22 17.67C104.214 18.4707 104.661 19.1233 105.218 19.1275C105.775 19.1317 106.231 18.486 106.237 17.6853Z"
        fill="#E6E6E6"
      />
      <path
        d="M99.0233 21.2871C99.0293 20.4863 98.5827 19.8338 98.0256 19.8296C97.4686 19.8254 97.0121 20.4711 97.0061 21.2718C97 22.0726 97.4467 22.7251 98.0037 22.7293C98.5608 22.7335 99.0172 22.0878 99.0233 21.2871Z"
        fill="#FF6584"
      />
      <path
        d="M109.065 27.9443C109.071 27.1436 108.625 26.491 108.068 26.4868C107.511 26.4826 107.054 27.1283 107.048 27.9291C107.042 28.7298 107.489 29.3823 108.046 29.3865C108.603 29.3907 109.059 28.745 109.065 27.9443Z"
        fill="#3F3D56"
      />
      <path
        d="M16.2918 135.794C16.4187 134.922 15.5051 134.067 14.2513 133.884C12.9975 133.702 11.8782 134.261 11.7513 135.133C11.6244 136.006 12.5379 136.861 13.7917 137.043C15.0456 137.225 16.1649 136.666 16.2918 135.794Z"
        fill="#E6E6E6"
      />
      <path
        d="M31.2195 134.337C31.3464 133.465 30.4329 132.61 29.179 132.428C27.9252 132.245 26.8059 132.804 26.679 133.677C26.5521 134.549 27.4656 135.404 28.7195 135.586C29.9733 135.769 31.0926 135.209 31.2195 134.337Z"
        fill="#3F3D56"
      />
      <path
        d="M12.2039 123.708C12.3308 122.836 11.4173 121.981 10.1634 121.799C8.9096 121.616 7.79028 122.175 7.66338 123.048C7.53647 123.92 8.45003 124.775 9.70386 124.957C10.9577 125.14 12.077 124.581 12.2039 123.708Z"
        fill="#FF6584"
      />
      <path
        d="M5.71666 118.758C5.72271 117.957 5.27605 117.305 4.71902 117.301C4.16198 117.296 3.7055 117.942 3.69944 118.743C3.69339 119.543 4.14005 120.196 4.69709 120.2C5.25413 120.204 5.7106 119.559 5.71666 118.758Z"
        fill="#E6E6E6"
      />
      <path
        d="M3.51743 109.537C3.52348 108.737 3.07683 108.084 2.51979 108.08C1.96275 108.076 1.50628 108.721 1.50022 109.522C1.49417 110.323 1.94082 110.975 2.49786 110.98C3.0549 110.984 3.51137 110.338 3.51743 109.537Z"
        fill="#3F3D56"
      />
      <path
        d="M20.6229 125.765C20.629 124.964 20.1823 124.312 19.6252 124.308C19.0682 124.303 18.6117 124.949 18.6057 125.75C18.5996 126.551 19.0463 127.203 19.6033 127.207C20.1604 127.212 20.6168 126.566 20.6229 125.765Z"
        fill="#E6E6E6"
      />
      <path
        d="M13.409 129.367C13.4151 128.566 12.9684 127.914 12.4114 127.909C11.8544 127.905 11.3979 128.551 11.3918 129.352C11.3858 130.152 11.8324 130.805 12.3895 130.809C12.9465 130.813 13.403 130.168 13.409 129.367Z"
        fill="#FF6584"
      />
      <path
        d="M23.451 136.024C23.4571 135.223 23.0104 134.571 22.4534 134.567C21.8963 134.562 21.4399 135.208 21.4338 136.009C21.4278 136.81 21.8744 137.462 22.4314 137.466C22.9885 137.471 23.445 136.825 23.451 136.024Z"
        fill="#3F3D56"
      />
    </g>
    <defs>
      <clipPath id="clip0_2633_13766">
        <rect
          width="175"
          height="208"
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
