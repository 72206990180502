import { ref } from 'vue';
import * as apis from '@/apis/forest';
import { Blogs, BlogsEntities, Params } from '@/types/Blogs';
import { useRoute } from 'vue-router';

const blogs = ref<Blogs>({ entities: [], page_information: {} });
const blogDetail = ref<BlogsEntities>();
const recommended = ref<BlogsEntities[]>();
export default function useForest() {
  const route = useRoute();

  const formatDate = (date: Date) => {
    const monthAll = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const dL = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    const d = new Date(date);
    const day = dL[d.getDay()];
    const dayNumber = d.getDate();
    const month = monthAll[d.getMonth()];
    const year = d.getFullYear();
    return `${day},${month} ${dayNumber},${year} ${d.getHours()}:${
      d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
    }`;
  };

  const getBlogs = async (payload: Params | undefined) => {
    const response = await apis.getBlogs(payload);
    blogs.value = response.data;
  };

  const filterBlogsRecommended = (blogs: BlogsEntities[]) => {
    const blogsRecommended = blogs.filter((b) => b.recommended);
    recommended.value = blogsRecommended;
    return blogsRecommended;
  };

  const getBlogDetail = async () => {
    const blogId = parseInt(route.params.id as string);

    const response = await apis.getBlogDetail(blogId);
    blogDetail.value = response.data;
  };

  return {
    getBlogs,
    blogs,
    filterBlogsRecommended,
    getBlogDetail,
    blogDetail,
    formatDate,
    recommended,
  };
}
