import { donate, guestDonate, getPayment, getPrices } from '@/apis';
import { useUserStore } from '@/store/user';
import { DonateQRCodeType, DonateResponse } from '@/types/Donate';
import { ref, onBeforeUnmount } from 'vue';
import useTimer from './useTimer';

interface Params {
  onPaid?(): unknown;
}

const WAIT_5_SECONDS = 5000;
const QR_CURRENCY = 'THB';

const BASE64_PREFIX = 'data:image/png;base64, ';

const getImage = ({ isBase64, image }: DonateResponse) => {
  const isImage = /^data:image\/[a-z]{3,};/;
  if (!isBase64) return image;
  return (isImage.test(image) ? '' : BASE64_PREFIX) + image;
};

export default function useQRPayment(params?: Params) {
  const timer = useTimer({
    interval: WAIT_5_SECONDS,
    callback: async () => {
      if (!qrPayment.value) return;
      const { data: payment } = await getPayment(qrPayment.value.id);
      if (payment.status === 'paid') {
        timer.stop();
        params?.onPaid?.();
      }
    },
  });
  const stopQRScan = () => {
    qrPayment.value = undefined;
    timer.stop();
  };

  const user = useUserStore();
  const qrPayment = ref<DonateResponse>();
  const createQRPayment = async (payload: {
    amount: number;
    receiver: string;
    type: DonateQRCodeType;
  }) => {
    const { amount, receiver, type } = payload;
    const { data: prices } = await getPrices(type);
    const price = prices.find((p) => p.symbol === QR_CURRENCY);
    if (!price) return;
    const total = Math.ceil(amount * price?.pricePerContract);

    if (user.me != null) {
      qrPayment.value = await donate({
        total,
        type,
        sessionToken: user.sessionToken as string,
        currency: QR_CURRENCY,
      }).then(({ data }) => ({
        ...data,
        image: getImage(data),
      }));
    } else {
      qrPayment.value = await guestDonate({
        email: receiver,
        total,
        type,
        currency: QR_CURRENCY,
      }).then(({ data }) => ({
        ...data,
      }));
    }
    timer.start();

    return qrPayment.value;
  };

  onBeforeUnmount(() => stopQRScan());
  return {
    qrPayment,
    createQRPayment,
    stopQRScan,
  };
}
