import Moralis from 'moralis';
import { defineStore } from 'pinia';
import { UserAttributes, UserUpdate } from './types';
import * as apis from '@/apis';
import useToastMessage from '@/composables/useToastMessage';

export interface State {
  me: Moralis.User | null;
  attributes: Partial<UserAttributes>;
}
const { isLoading } = useToastMessage();

export const useUserStore = defineStore('user', {
  state: (): State => {
    return {
      me: null,
      attributes: {},
    };
  },
  getters: {
    email: (state) => state.attributes.email ?? '',
    isLoggedIn: (state) => state.me?.authenticated() ?? false,
    shouldShowVerify(): boolean {
      return this.isLoggedIn && !this.email;
    },
    ethAddress: (state) => state.attributes.ethAddress,
    sessionToken: (state) => state.attributes.sessionToken,
    isGeneratedUser: (state) => !!state.attributes.walletKeystore,
  },
  actions: {
    setMe(me: Moralis.User | null) {
      this.me = me;
      this.attributes = { ...(me?.attributes ?? {}) };
      apis.setHeaders('Authorization', me?.getSessionToken());
    },
    async getMe() {
      this.setMe(await Moralis.User.currentAsync());
    },
    async connectWallet() {
      isLoading.value = true;
      const { message } = await Moralis.Cloud.run('requestMessage', {
        address: window.ethereum.selectedAddress,
        chain: parseInt(window.ethereum.chainId, 16),
        networkType: 'evm',
      });

      this.setMe(
        await Moralis.authenticate({
          signingMessage: message,
        })
      );
      isLoading.value = false;
    },
    async login(username: string, password: string) {
      this.setMe(await Moralis.User.logIn(username, password));
    },
    async signup(username: string, password: string) {
      const user = new Moralis.User();
      user.set('username', username);
      user.set('password', password);
      user.set('email', username);
      this.setMe(await user.signUp());
      this.createWallet();
    },
    async verify({ email, password }: Required<UserUpdate>) {
      if (this.me) {
        this.me.setEmail(email);
        this.me.setPassword(password);
        await this.me.save();
        await this.getMe();
      }
    },
    async logout() {
      try {
        await Moralis.User.logOut();
      } catch (e) {
        console.log(e);
      }
      this.$reset();
    },
    async getAccount() {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      return accounts[0];
    },
    async linkWallet() {
      isLoading.value = true;

      await Moralis.enableWeb3()
        .then(async () => {
          const { message } = await Moralis.Cloud.run('requestMessage', {
            address: window.ethereum.selectedAddress,
            chain: parseInt(window.ethereum.chainId, 16),
            networkType: 'evm',
          });

          const user = await Moralis.Web3.link(await this.getAccount(), {
            signingMessage: message,
          });

          if (this.me && user) {
            this.me.unset('walletKeystore');
            await this.me.save();
          }
          this.setMe(user);
          isLoading.value = false;
        })
        .catch((error) => {
          isLoading.value = false;
        });
    },
    async unlinkWallet() {
      // TODO
      const user = await this.me?._unlinkFrom(Moralis.Web3.givenProvider);
      // const user = await Moralis.Web3.unlink(await this.getAccount());
      this.setMe(user ?? null);
    },
    async createWallet() {
      await Moralis.Cloud.run('generateWalletToUser', {
        token: this.me?.getSessionToken(),
      });
      const currentUser = Moralis.User.current();
      const user =
        currentUser &&
        (await Moralis.User.become(currentUser.getSessionToken()));
      user && this.setMe(user);
    },
  },
});
