import { from } from 'env-var';

const env = from(process.env);
const getEnv = (path: string) => env.get('VUE_APP_' + path);
const getEnvString = (path: string) => getEnv(path).asString() as string;

export const CHAIN_ID = getEnv('CHAIN_ID').asInt() as number;
export const CHAIN_NAME = getEnvString('CHAIN_NAME');
export const CHAIN_RPC_URL = getEnvString('CHAIN_RPC_URL');
export const CHAIN_CURRENCY_NAME = 'Binance Coin';
export const CHAIN_CURRENCY_SYMBOL = 'BNB';
export const CHAIN_BLOCK_EXPLORER_URL = getEnvString(
  'CHAIN_BLOCK_EXPLORER_URL'
);

export const CHAIN_TITLE = getEnvString('CHAIN_TITLE') as 'bsc' | 'bsc testnet';

export const AGGREGATOR_ADDRESS = getEnvString('AGGREGATOR_ADDRESS');
export const CARBON_ADDRESS = getEnvString('CARBON_ADDRESS');
export const CARBON_NFT_ADDRESS = getEnvString('CARBON_NFT_ADDRESS');
export const BIODIVERSITY_ADDRESS = getEnvString('BIODIVERSITY_ADDRESS');
export const BIODIVERSITY_NFT_ADDRESS = getEnvString(
  'BIODIVERSITY_NFT_ADDRESS'
);
export const USDT_ADDRESS = getEnvString('USDT_ADDRESS');
export const TREASURE_ADDRESS = getEnvString('TREASURE_ADDRESS');
export const TOKEN_GENERATOR_ADDRESS = getEnvString('TOKEN_GENERATOR_ADDRESS');

export const CONTRACT_FEE_WEI = getEnvString('CONTRACT_FEE_WEI');
export const CONTRACT_FEE_BNB = getEnv('CONTRACT_FEE_BNB').asFloat() as number;
export const CONTRACT_FEE_USDT = getEnv(
  'CONTRACT_FEE_USDT'
).asFloat() as number;
export const IS_NODE_ENV_PROD = process.env.NODE_ENV === 'production';
export const BSC_SCAN_URL = `${CHAIN_BLOCK_EXPLORER_URL}/tx/`;
export const BSC_ADDRESS_URL = `${CHAIN_BLOCK_EXPLORER_URL}/address/`;
export const API_CARBON_URL = getEnvString('API_CARBON_URL');
export const API_FOREST_URL = getEnvString('API_FOREST_URL');

export const OMISE_PUBLIC_KEY = getEnvString('OMISE_PUBLIC_KEY');
export const OMISE_PRIVATE_KEY = getEnvString('OMISE_PRIVATE_KEY');

export const ALLOWED_PAYMENT_CRYPTO = getEnv('ALLOWED_PAYMENT_CRYPTO').asBool();
export const ALLOWED_PAYMENT_CARD = getEnv('ALLOWED_PAYMENT_CARD').asBool();
export const ALLOWED_PAYMENT_QRCODE = getEnv('ALLOWED_PAYMENT_QRCODE').asBool();
export const ALLOWED_PAYMENT_PAYPAL = getEnv('ALLOWED_PAYMENT_PAYPAL').asBool();

export const STRIPE_PUBLIC_KEY = getEnvString('STRIPE_PUBLIC_KEY');
