<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="130"
    height="96"
    viewBox="0 0 130 96"
    fill="none"
  >
    <path d="M129.5 11.0125H7.47266V91.8513H129.5V11.0125Z" fill="#E0E2E5" />
    <path
      d="M127.959 88.7039L77.3349 47.8422C74.8406 45.8286 71.7133 44.728 68.4862 44.728C65.2591 44.728 62.1318 45.8286 59.6375 47.8422L9.0137 88.7039C8.34721 89.2413 7.86572 89.9679 7.63506 90.7845C7.40439 91.6011 7.4358 92.4678 7.72499 93.2662C8.01418 94.0646 8.54704 94.7557 9.25074 95.245C9.95443 95.7343 10.7946 95.998 11.6565 95.9999H125.316C126.178 95.998 127.018 95.7343 127.722 95.245C128.425 94.7557 128.958 94.0646 129.247 93.2662C129.537 92.4678 129.568 91.6011 129.337 90.7845C129.107 89.9679 128.625 89.2413 127.959 88.7039Z"
      fill="#CFD1D3"
    />
    <path
      d="M8.86719 14.0367L59.1214 58.8207C61.6865 61.1066 65.0244 62.3726 68.4861 62.3726C71.9478 62.3726 75.2857 61.1066 77.8508 58.8207L128.105 14.0161L8.86719 14.0367Z"
      fill="#A4A4A6"
    />
    <path
      d="M127.959 14.1532L77.3349 55.0149C74.8406 57.0285 71.7133 58.1291 68.4862 58.1291C65.2591 58.1291 62.1318 57.0285 59.6375 55.0149L9.0137 14.1532C8.34721 13.6158 7.86572 12.8892 7.63506 12.0726C7.40439 11.256 7.4358 10.3893 7.72499 9.5909C8.01418 8.79251 8.54704 8.10143 9.25074 7.61212C9.95443 7.12281 10.7946 6.85915 11.6565 6.85718H125.316C126.178 6.85915 127.018 7.12281 127.722 7.61212C128.425 8.10143 128.958 8.79251 129.247 9.5909C129.537 10.3893 129.568 11.256 129.337 12.0726C129.107 12.8892 128.625 13.6158 127.959 14.1532Z"
      fill="#F1F1F2"
    />
    <path
      d="M14.4456 27.4286C11.9955 27.4302 9.58901 26.7913 7.47266 25.5772V25.9269C7.47156 26.4755 7.63775 27.0117 7.94974 27.4665C8.26174 27.9212 8.70521 28.2735 9.22288 28.4778C11.3167 29.3207 13.5895 29.6434 15.8402 29.4172C22.7714 28.7315 28.3916 22.3064 28.3916 15.4629V13.7144C28.3916 17.3516 26.9223 20.8399 24.3069 23.4118C21.6915 25.9837 18.1443 27.4286 14.4456 27.4286Z"
      fill="#A4A4A6"
    />
    <path
      d="M14.4459 27.4286C22.1481 27.4286 28.3919 21.2885 28.3919 13.7143C28.3919 6.14009 22.1481 0 14.4459 0C6.74381 0 0.5 6.14009 0.5 13.7143C0.5 21.2885 6.74381 27.4286 14.4459 27.4286Z"
      fill="#E35429"
    />
    <path
      d="M13.2951 10.5395L11.1893 11.8217L9.95508 9.70288L13.9088 7.09717H16.3772V19.344H13.2951V10.5395Z"
      fill="#FAFAFA"
    />
  </svg>
</template>
