import { ref } from 'vue';
import { AxiosError } from 'axios';
import { useToast } from 'vue-toastification';

interface ToastMessage {
  message: string;
}

enum ErrorCode {
  DENIED = 4001,
}
interface MetamaskError extends ToastMessage {
  code: ErrorCode;
}

type Error = MetamaskError | ToastMessage | unknown;

const isMetamask = (e: Error): e is MetamaskError =>
  typeof e === 'object' && e != null && 'code' in e;
const isLoading = ref<boolean>(false);

export default function useToastMessage() {
  const toast = useToast();

  const error = (e: Error) => {
    if (isMetamask(e) && e.code === ErrorCode.DENIED) return;
    const message =
      (e as AxiosError).response?.data.message || (e as ToastMessage).message;
    toast.error(message);
  };
  return {
    error,
    isLoading,
  };
}
